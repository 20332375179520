<div class="left-navigation">
  <div class="left-navigation-logo">
    <a routerLink="/activity">
      <img src="/assets/img/attune_logo_new.svg" alt="Attune Home Page -- Activity Page" />
    </a>
  </div>
  <ul class="main-navigation">
    <li class="main-nav-item" *ngIf="showWelcomeBack">
      <a
        routerLink="/activity"
        routerLinkActive="main-nav-link__active"
        class="main-nav-link activity-nav-link"
        title="Activity"
      >
        <img class="nav-icon" src="/assets/img/activity_icon_color.svg" alt="" />
        <span>Activity</span>
      </a>
    </li>

    <li class="main-nav-item">
      <a
        routerLink="/accounts"
        routerLinkActive="main-nav-link__active"
        class="main-nav-link accounts-nav-link"
        title="Accounts"
      >
        <img class="nav-icon" src="/assets/img/accounts_icon_color.svg" alt="" />
        <span>Accounts</span>
      </a>
    </li>

    <li class="main-nav-item">
      <a
        routerLink="/billing"
        routerLinkActive="main-nav-link__active"
        class="main-nav-link billing-nav-link"
        title="Invoices"
      >
        <img class="nav-icon" src="/assets/img/billing_icon_color.svg" alt="" />
        <span>Invoices</span>
      </a>
    </li>

    <li *ngIf="isAiCheckerEnabled" class="main-nav-item">
      <a
        routerLink="/ai-checker"
        routerLinkActive="main-nav-link__active"
        class="main-nav-link billing-nav-link"
        title="Guidelines"
      >
        <img class="nav-icon" src="/assets/img/ai_bot.svg" alt="" />
        <span>Guidelines</span>
      </a>
    </li>

    <li class="main-nav-item">
      <a
        routerLink="/appetite"
        routerLinkActive="main-nav-link__active"
        class="main-nav-link appetite-nav-link"
        title="Appetite"
      >
        <img class="nav-icon" src="/assets/img/appetite_icon_color.svg" alt="" />
        <span>Appetite</span>
      </a>
    </li>

    <li class="main-nav-item">
      <a
        routerLink="/support"
        routerLinkActive="main-nav-link__active"
        class="main-nav-link support-nav-link"
        title="Support"
      >
        <img class="nav-icon" src="/assets/img/support_icon_color.svg" alt="" />
        <span>Support</span>
      </a>
    </li>

    <ng-container>
      <li class="main-nav-item main-nav-item__rewards">
        <a
          routerLink="/rewards"
          routerLinkActive="main-nav-link__active"
          class="main-nav-link rewards-nav-link"
          data-amplitude-input-name="rewards_checklist_leftNav"
        >
          <img class="nav-icon" src="/assets/img/rewards-color.svg" alt="" />
          <span>Rewards</span>
        </a>
        <p class="rewards-balance" aria-label="rewards balance">
          {{ rewardsBalance }}<span class="sr-only">insurecoins</span>
        </p>
      </li>
    </ng-container>

    <li class="main-nav-item main-nav-item__settings">
      <a
        routerLink="/settings"
        routerLinkActive="main-nav-link__active"
        class="main-nav-link settings-nav-link"
      >
        <img class="nav-icon" src="/assets/img/settings_icon_color.svg" alt="" />
        <span>Settings</span>
      </a>
    </li>

    <li class="main-nav-item main-nav-item__logout">
      <a (click)="logout()" (keyup.enter)="logout()" class="main-nav-link" title="Logout">
        <svg width="23" height="22" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.999 22H10.036a1.832 1.832 0 0 1-1.826-1.833v-4.584c0-.506.408-.916.913-.916.504 0 .874.41.874.916v4.584H21V1.833H9.997v4.584c0 .506-.37.916-.874.916a.915.915 0 0 1-.913-.916V1.833C8.21.823 9.03 0 10.036 0h10.963c1.007 0 1.826.822 1.826 1.833v18.334A1.831 1.831 0 0 1 20.999 22zM4.023 10.083h12.403c.504 0 .913.411.913.917a.915.915 0 0 1-.913.917H4.024l2.093 2.102a.919.919 0 0 1-.645 1.564.908.908 0 0 1-.646-.268l-3.652-3.667a.913.913 0 0 1-.266-.642L.907 11c0-.007.003-.013.004-.02a.91.91 0 0 1 .264-.629l3.651-3.666a.909.909 0 0 1 1.29 0 .919.919 0 0 1 0 1.296l-2.093 2.102z"
            fill="#7D8D9E"
            fill-rule="evenodd"
          ></path>
        </svg>
        <span>Logout</span>
      </a>
    </li>
  </ul>
</div>
