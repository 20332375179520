import {
  BOP_GUIDELINE_LINK_ACCREDITED,
  BOP_GUIDELINE_LINK_BLACKBOARD,
} from '../../features/support/models/support-constants';
import {
  COALITION_BLOCKLISTED_DOMAIN_ERROR_MESSAGE,
  COALITION_DUPLICATE_BROKER_ERROR_MESSAGE_MODAL,
  COALITION_BROKER_OF_RECORD_REFERRAL_ERROR_MESSAGE,
} from '../../features/coalition/models/cyber-constants.model';

export interface AppError {
  header: string;
  image: string;
  body: string;
  displayClose: boolean;
  displayBack: boolean;
  displayRetry: boolean;
  backButtonText?: string;
  closeButtonText?: string;
  retryButtonText?: string;
  message?: string;
  hideDialogBox?: boolean;
  dialogMessage?: string;
  modalNameForAmplitudeEvent?: string;
}

export const TECH_MORATORIUM_ERROR: AppError = {
  body: `There is a moratorium in place which is affecting this location. This quote can not be bound until the moratorium is lifted.`,
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Moratorium',
  hideDialogBox: true,
  image: 'assets/img/issue.png',
};

export const DECLINED_ERROR_V1: AppError = {
  body: `Here's a <a href="${BOP_GUIDELINE_LINK_BLACKBOARD}" target="_blank">link to our guidelines</a> to learn more.
  Feel free to contact our Customer Care Team if you have any questions.`,
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Sadly, we have to decline',
  image: 'assets/img/declined.png',
};

export const DECLINED_ERROR_V2: AppError = {
  body: `Here's a <a href="${BOP_GUIDELINE_LINK_ACCREDITED}" target="_blank">link to our guidelines</a> to learn more.
  Feel free to contact our Customer Care Team if you have any questions.`,
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Sadly, we have to decline',
  image: 'assets/img/declined.png',
};

export const INELIGIBLE_ERROR_V1: AppError = {
  body: `Here's a <a href="${BOP_GUIDELINE_LINK_BLACKBOARD}" target="_blank">link to our guidelines</a> to learn more.
  Feel free to contact our Customer Care Team if you have any questions.`,
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Ineligible Location',
  image: 'assets/img/ineligible.png',
};

export const INELIGIBLE_ERROR_V2: AppError = {
  body: `Here's a <a href="${BOP_GUIDELINE_LINK_ACCREDITED}" target="_blank">link to our guidelines</a> to learn more.
  Feel free to contact our Customer Care Team if you have any questions.`,
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Ineligible Location',
  image: 'assets/img/ineligible.png',
};

export const BOP_BIND_ERROR: AppError = {
  body: "We're having trouble issuing this BOP policy. Please try again or contact the Customer Care Team if you continue to have this issue.",
  displayBack: true,
  displayRetry: false,
  displayClose: false,
  backButtonText: 'Back to account',
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const BOP_BUNDLE_BIND_ERROR: AppError = {
  body: "We're having trouble binding these quotes. Please try again or contact the Customer Care Team if you continue to have this issue.",
  displayBack: true,
  displayRetry: false,
  displayClose: false,
  backButtonText: 'Back to account',
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
  modalNameForAmplitudeEvent: 'bop-bundle-bind-error',
};

export const BOP_MORATORIUM_BIND_ERROR: AppError = {
  body:
    'Due to a moratorium that impacts this client’s location, we are unable to bind this policy until further notice. If you choose to submit a bind request, Attune will email you when the moratorium is lifted to confirm whether you would like to proceed with this bind, effective the date the moratorium is lifted.' +
    '<br/><br/>Please note: Clients with a lapse in coverage are ineligible.',
  displayBack: false,
  displayRetry: true,
  displayClose: true,
  retryButtonText: 'Submit bind request',
  header: 'Bind blocked by moratorium',
  image: 'assets/img/server_error.png',
  modalNameForAmplitudeEvent: 'bop-bundle-bind-error',
};

export const UNKNOWN_ERROR: AppError = {
  body: 'We had some trouble processing your quote. If you get this error again, contact our Customer Care Team and they will complete the quote for you.',
  displayBack: false,
  displayClose: true,
  displayRetry: true,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const UNKNOWN_COALITION_ERROR: AppError = {
  body: 'We had some trouble processing your quote. Please try again by starting a new quote for this insured on the accounts page. If you get this error again, contact our Customer Care Team.',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const COALITION_EARLY_DECLINE_ERROR: AppError = {
  body: `Feel free to contact our Customer Care Team if you have any questions or submit a request via our <a href="https://app.attuneinsurance.com/help-center">Help Center</a>.`,
  dialogMessage: 'This quote has been declined for the following reason(s):',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Quote declined',
  image: 'assets/img/declined.png',
};

export const COALITION_SURPLUS_MARKET_UNAVAILABLE_ERROR = (bodyText: string): AppError => ({
  body: bodyText,
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  dialogMessage:
    'With the surplus market currently unavailable, this quote is ineligible for the following reasons:',
  header: 'Surplus market currently unavailable',
  image: 'assets/img/ineligible.png',
});

export const COALITION_BIND_ERROR: AppError = {
  body: 'We had some trouble binding your quote. If you get this error again, contact our Customer Care Team.',
  displayBack: true,
  displayClose: false,
  displayRetry: true,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const COALITION_BUNDLE_BIND_ERROR: AppError = {
  body: "We've issued your BOP policy but had some trouble binding your Cyber quote. If you get this error again, contact our Customer Care Team.",
  displayBack: false,
  displayClose: true,
  closeButtonText: 'Back to account',
  displayRetry: true,
  retryButtonText: 'Retry cyber bind',
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
  modalNameForAmplitudeEvent: 'cyber-bundle-bind-error',
};

export const COALITION_DEFAULT_RETENTION_ERROR: AppError = {
  body: 'We were unable to return a price indication for the selected retention and limit. We updated the retention to reflect a valid input for the selected limit.',
  displayBack: false,
  displayClose: true,
  displayRetry: false,
  closeButtonText: 'Continue with quote',
  header: 'The retention value does not meet the requirement for the selected limit.',
  image: 'assets/img/issue.png',
};

export const COALITION_MISSING_DOMAIN_ERROR: AppError = {
  body: 'Submissions without a domain typically require manual review. Get immediate pricing and avoid the wait by adding a domain opened and operated by the insured now. In addition, all quotes with a domain get the free Coalition Risk Assessment.',
  displayBack: false,
  displayClose: true,
  displayRetry: true,
  closeButtonText: 'Add Domain',
  retryButtonText: 'Skip',
  header: 'We strongly suggest you add a domain',
  image: 'assets/img/issue.png',
};

export const COALITION_INVALID_DOMAIN_ERROR: AppError = {
  body: "The domain you provided was determined to be invalid by Coalition's underwriting system. Please ensure that you are including a valid website or email domain (e.g. localrestaurant.com, john@localrestaurant.com). You may also continue this quote without the domain if you wish.",
  displayBack: false,
  displayClose: true,
  displayRetry: true,
  closeButtonText: 'Edit Domain',
  retryButtonText: 'Continue without domain',
  header: 'We were unable to process the domain you provided',
  image: 'assets/img/issue.png',
};

export const COALITION_NON_BIND_ERROR: AppError = {
  body: 'We had some trouble setting up your quote for binding. If you get this error again, contact our Customer Care Team.',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const COALITION_BROKER_OF_RECORD_REFERRAL_ERROR: AppError = {
  body: COALITION_BROKER_OF_RECORD_REFERRAL_ERROR_MESSAGE,
  displayBack: true,
  displayClose: true,
  displayRetry: false,
  closeButtonText: 'Submit request',
  header: 'This quote has already been created by a different agency',
  image: 'assets/img/issue.png',
};

export const COALITION_BLOCKLISTED_DOMAIN_ERROR: AppError = {
  body: COALITION_BLOCKLISTED_DOMAIN_ERROR_MESSAGE,
  displayBack: false,
  displayClose: true,
  displayRetry: true,
  closeButtonText: 'Edit Domain',
  retryButtonText: 'Continue Without Domain',
  header: 'We were unable to process this domain',
  image: 'assets/img/issue.png',
};

export const COALITION_DUPLICATE_BROKER_ERROR: AppError = {
  body: COALITION_DUPLICATE_BROKER_ERROR_MESSAGE_MODAL,
  displayBack: true,
  displayClose: true,
  displayRetry: false,
  closeButtonText: 'Update my registration',
  header: 'Your email is registered with a different wholesale agency ',
  image: 'assets/img/issue.png',
};

export const COALITION_INVALID_AGENCY_DATA_ERROR: AppError = {
  body: "We're looking into resolving this issue and will reach out to help you get this account quoted.",
  displayBack: false,
  displayClose: true,
  displayRetry: false,
  closeButtonText: 'Back to account',
  header: 'Oops! Something went wrong',
  image: 'assets/img/issue.png',
};

export const HISCOX_UNKNOWN_ERROR: AppError = {
  body:
    'We had some trouble processing your quote. If you get this error again, please contact Hiscox through the Hiscox partner portal <a href="https://www.hiscox.com/partner-agent/attuneinsurance/manage-policy" target="_blank">here</a> ' +
    'or by calling <a href="tel:+18776555177">1-877-655-5177</a> to complete your quote. <br><br>',
  displayBack: false,
  displayClose: true,
  displayRetry: true,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const HISCOX_UNKNOWN_VALIDATION_ERROR: AppError = {
  body:
    'Hiscox was unable to process the submitted application. To finish your quote, please contact Hiscox through the Hiscox partner ' +
    'portal <a href="https://www.hiscox.com/partner-agent/attuneinsurance/manage-policy" target="_blank">here</a>  or by calling <a href="tel:+18776555177">1-877-655-5177</a>. <br><br>' +
    '<p class="p__small-text p__gray">Note: If you have this quote open in the Hiscox NOW Small Business Program portal in another browser tab, please close that tab before retrying.</p>',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Your application could not be processed',
  image: 'assets/img/server_error.png',
};

export const HISCOX_KNOWN_VALIDATION_ERROR: AppError = {
  body: 'Hiscox was unable to process the submitted application. Please correct the listed error(s) and then resubmit your quote.',
  displayBack: true,
  displayClose: true,
  displayRetry: false,
  header: 'Your application could not be processed',
  image: 'assets/img/server_error.png',
};

export const HISCOX_ZIP_ERROR: AppError = {
  body: 'Hiscox validates addresses via USPS. This sometimes results in an error as Google may provide an incorrect zip code. To prevent this, try validating the address through USPS <a href="https://tools.usps.com/zip-code-lookup.htm" target="_blank">here</a> and retrying.',
  displayBack: true,
  displayClose: true,
  displayRetry: false,
  header: 'Invalid zip code',
  image: 'assets/img/server_error.png',
};

export const HISCOX_UNKNOWN_PAYMENT_ERROR: AppError = {
  body:
    'We had some trouble setting up payment for your quote. If you get this error again, contact Hiscox. <br><br>' +
    '<p class="p__small-text p__gray">Note: If you have this quote open in the Hiscox NOW Small Business Program portal in another browser tab, please close that tab before retrying.</p>',
  displayBack: true,
  displayClose: false,
  displayRetry: true,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const HISCOX_UNKNOWN_CC_ERROR: AppError = {
  body: 'Please review and try again. <br> <br> <p class="p__small-text p__gray">Note: If you have this quote open in the Hiscox NOW Small Business Program portal in another browser tab, please close that tab before retrying.</p>',
  dialogMessage:
    'We had some trouble processing your credit card information due to the following reason(s):',
  displayBack: false,
  displayClose: true,
  displayRetry: false,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const HISCOX_UNKNOWN_BIND_ERROR: AppError = {
  body:
    'We had some trouble binding your quote. If you get this error again, contact Hiscox. <br><br>' +
    '<p class="p__small-text p__gray">Note: If you have this quote open in the Hiscox NOW Small Business Program portal in another browser tab, please close that tab before retrying.</p>',
  displayBack: true,
  displayClose: false,
  displayRetry: true,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const UNKNOWN_ERROR_WITHOUT_RETRY: AppError = {
  body:
    'We had some trouble processing your quote. If you get this error again, contact our Customer Care Team and they will complete the quote for you. <br><br>' +
    '<p class="p__small-text p__gray">Note: If you have this quote open in the Hiscox NOW Small Business Program portal in another browser tab, please close that tab before retrying.</p>',
  displayBack: false,
  displayClose: true,
  displayRetry: false,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const LIBERTY_MUTUAL_UNKNOWN_PAYMENT_ERROR: AppError = {
  body: 'We had some trouble setting up payment for your quote. If you get this error again, contact our Customer Care Team.',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const LIBERTY_MUTUAL_HYDRA_ERROR: AppError = {
  body: 'We had some trouble setting up payment for your quote. If you get this error again, contact our Customer Care Team.',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const LIBERTY_MUTUAL_UNKNOWN_BIND_ERROR: AppError = {
  body: 'We had some trouble binding your quote. If you get this error again, contact our Customer Care Team.',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const LIBERTY_MUTUAL_UNKNOWN_CC_ERROR: AppError = {
  body: 'Declined due to invalid credit card information.',
  displayBack: true,
  displayClose: false,
  displayRetry: true,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const LIBERTY_MUTUAL_DECLINED_ERROR_BOP: AppError = {
  body: `Here's a link to <a href="https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Liberty+Mutual+BOP+Product+Overview+and+Appetite+Guide+August+2021.pdf" target="_blank">Liberty Mutual's guidelines</a> to learn more.
  Feel free to contact our Customer Care Team if you have any questions or submit a request via our <a href="https://app.attuneinsurance.com/help-center">Help Center</a>.`,
  dialogMessage: 'This quote has been declined for the following reason(s):',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Quote declined',
  image: 'assets/img/declined.png',
};

// TODO: update guidelines link to point to CPSP document
export const LIBERTY_MUTUAL_DECLINED_ERROR_CPSP: AppError = {
  // CPSP guidelines temporarily disabled
  // Here's a link to <a href="https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/Liberty+Mutual+CP+Product+Overview+and+Appetite+Guide+November+2021.pdf" target="_blank">Liberty Mutual's guidelines</a> to learn more.
  body: `Feel free to contact our Customer Care Team if you have any questions or submit a request via our <a href="https://app.attuneinsurance.com/help-center">Help Center</a>.`,
  dialogMessage: 'This quote has been declined for the following reason(s):',
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: 'Quote declined',
  image: 'assets/img/declined.png',
};

export const LIBERTY_MUTUAL_CLASS_CODES_ERROR: AppError = {
  body: 'There was an issue loading the available class codes for this account. Please try again or return to the accounts page.',
  displayBack: true,
  displayClose: false,
  displayRetry: true,
  header: 'Oops...something went wrong',
  image: 'assets/img/server_error.png',
};

export const TIMEOUT_ERROR: AppError = {
  body: "Sorry — your quote is taking longer than usual to process. If you don't see an update on the account page after a minute or two, please contact our Customer Care Team.",
  displayBack: true,
  displayClose: false,
  displayRetry: false,
  header: "We're still working...",
  image: 'assets/img/timeout_error.png',
};

export const ISSUE_ERROR: AppError = {
  body: 'Please contact our Customer Care Team if you have any questions or problems fixing this issue.',
  displayBack: false,
  displayClose: true,
  displayRetry: false,
  header: 'We found an issue',
  image: 'assets/img/issue.png',
  message: '',
};

export const QUOTE_PRICE_CHANGE_ERROR = (oldPrice: number, newPrice: number): AppError => {
  return {
    body: 'Please contact our Customer Care Team if you have any questions or problems fixing this issue.',
    displayBack: false,
    displayClose: true,
    displayRetry: false,
    header: 'We wanted to let you know...',
    image: 'assets/img/issue.png',
    message: `The price of your quote has changed from ${oldPrice} to ${newPrice}.`,
  };
};

export const ISO_LOCATION_ERROR: AppError = {
  body: `We were unable to get a response from the ISO Location Service.
      Please try again later. If this error persists, send the account details to our Customer Care Team.
      Thank you for your patience.`,
  displayBack: false,
  displayClose: true,
  displayRetry: true,
  header: 'Failed to process your quote',
  image: 'assets/img/server_error.png',
};
