import { Component, Input } from '@angular/core';
import {
  ATTUNE_WC_GUIDE,
  BOP_GUIDELINE_LINK_ACCREDITED,
} from 'app/features/support/models/support-constants';
import { BookMix } from 'app/shared/services/book-mix.service';
import { AttuneProduct } from 'app/features/digital-carrier/models/types';

@Component({
  selector: 'app-activity-book-mix-progress',
  templateUrl: './activity-book-mix-progress.component.html',
})
export class BookMixProgressComponent {
  @Input() productType: AttuneProduct['product'];
  @Input() productBookMix: BookMix;

  attuneWcGuidelinesLink = ATTUNE_WC_GUIDE;
  attuneBopAccreditedGuidelinesLink = BOP_GUIDELINE_LINK_ACCREDITED;

  get preferredPercentage() {
    return Math.round((this.preferredCount / (this.preferredCount + this.nonPreferredCount)) * 100);
  }

  get totalTargetPreferredPoliciesCount() {
    const targetPercentagePreferred =
      this.productBookMix.producerCodeBookConfig.targetPercentage / 100;
    const totalPoliciesNeeded = this.nonPreferredCount / (1 - targetPercentagePreferred);
    const additionalPreferredPoliciesInterim = totalPoliciesNeeded * targetPercentagePreferred;
    return Math.max(0, Math.ceil(additionalPreferredPoliciesInterim));
  }

  get preferredCount() {
    // Tier 1 and Tier 2 are considered preferred
    const tier1Policies = this.productBookMix.producerCodeBookInfo?.[1]?.numPolicies ?? 0;
    const tier2Policies = this.productBookMix.producerCodeBookInfo?.[2]?.numPolicies ?? 0;
    return tier1Policies + tier2Policies;
  }

  get nonPreferredCount() {
    // Tier 2 and Tier 3 are considered non-preferred
    const tier3Policies = this.productBookMix.producerCodeBookInfo?.[3]?.numPolicies ?? 0;
    const tier4Policies = this.productBookMix.producerCodeBookInfo?.[4]?.numPolicies ?? 0;
    return tier3Policies + tier4Policies;
  }

  get productTypeName() {
    return this.productType;
  }

  getSectionHeading() {
    return this.productType === 'bop' ? "Businessowners' Policy" : "Workers' Compensation";
  }

  getGuidelinesLink() {
    return this.productType === 'bop'
      ? this.attuneBopAccreditedGuidelinesLink
      : this.attuneWcGuidelinesLink;
  }

  getAttunePaper() {
    return this.productType === 'bop' ? 'Accredited' : 'Built by Pinnacol';
  }

  getPercentPreferred() {
    return Math.ceil((this.preferredCount / (this.preferredCount + this.nonPreferredCount)) * 100);
  }

  getPercentNonPreferred() {
    return Math.ceil(
      (this.nonPreferredCount / (this.preferredCount + this.nonPreferredCount)) * 100
    );
  }

  getBookMixStatusMessage() {
    if (
      this.preferredPercentage >=
      Number(this.productBookMix.producerCodeBookConfig.targetPercentage)
    ) {
      return 'Healthy Balance';
    } else if (
      this.preferredPercentage < Number(this.productBookMix.producerCodeBookConfig.targetPercentage)
    ) {
      return 'Unsatisfactory Balance';
    }
  }
}
