import { Component, Input, OnInit } from '@angular/core';
import { BOOLEAN_FLAG_NAMES, FeatureFlagService } from 'app/core/services/feature-flag.service';
import { UserService } from 'app/core/services/user.service';
import { BookInfoResponse, BookMixService } from 'app/shared/services/book-mix.service';
import { some } from 'lodash';
import { combineLatest, Subscription, switchMap, filter, take } from 'rxjs';

@Component({
  selector: 'app-activity-book-mix-insights',
  templateUrl: './activity-book-mix-insights.component.html',
})
export class BookMixInsightsComponent implements OnInit {
  constructor(
    private bookMixService: BookMixService,
    private userService: UserService,
    private featureFlagService: FeatureFlagService
  ) {}

  @Input() producerCode: string;

  private sub: Subscription = new Subscription();

  loadingBookMixInformation = true;
  hasBopPolicies = false;
  hasWcPolicies = false;
  bookMixResponse: BookInfoResponse;

  bopPreferenceEnabled = false;
  wcPreferenceEnabled = false;

  ngOnInit() {
    const wcPreferenceEnabled$ = this.featureFlagService.isEnabled(
      BOOLEAN_FLAG_NAMES.WC_CLASS_PREFERENCE
    );
    const bopPreferenceEnabled$ = this.featureFlagService.isEnabled(
      BOOLEAN_FLAG_NAMES.BOP_CLASS_PREFERENCE
    );

    this.sub.add(
      wcPreferenceEnabled$.subscribe((isEnabled) => {
        this.wcPreferenceEnabled = isEnabled || false;
      })
    );
    this.sub.add(
      bopPreferenceEnabled$.subscribe((isEnabled) => {
        this.bopPreferenceEnabled = isEnabled || false;
      })
    );

    this.sub.add(
      combineLatest([wcPreferenceEnabled$, bopPreferenceEnabled$])
        .pipe(
          filter(([wcPreferenceEnabled, bopPreferenceEnabled]) => {
            return !!(wcPreferenceEnabled || bopPreferenceEnabled);
          }),
          take(1),
          switchMap(() => this.userService.getUser()),
          switchMap((user) => this.bookMixService.getBookMixInformation(user.producer))
        )
        .subscribe((bookMixInformation: BookInfoResponse) => {
          if (bookMixInformation) {
            this.bookMixResponse = bookMixInformation;
            this.hasBopPolicies = some(this.bookMixResponse?.bop?.producerCodeBookInfo, (tier) => {
              return tier.numPolicies > 0;
            });
            this.hasWcPolicies = some(this.bookMixResponse?.wc?.producerCodeBookInfo, (tier) => {
              return tier.numPolicies > 0;
            });
          }
          this.loadingBookMixInformation = false;
        })
    );
  }

  showBookMix() {
    if (this.loadingBookMixInformation) {
      return false;
    }
    const showBopBookMix = this.hasBopPolicies && this.bopPreferenceEnabled;
    const showWcBookMix = this.hasWcPolicies && this.wcPreferenceEnabled;
    return showBopBookMix || showWcBookMix;
  }
}
