import { of as observableOf, EMPTY, Subscription, timer, Subject, combineLatest } from 'rxjs';
import { finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import * as _ from 'lodash';

import { getNaicsCodes } from 'app/shared/insured-account/insured-account-helpers';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { User } from 'app/shared/models/user';
import { urlValidator, zipCodeValidator } from '../../../../shared/helpers/form-helpers';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { NaicsService, ProductEligibility } from '../../../../shared/services/naics.service';
import {
  GW_NEW_ACCOUNT_TEMP_EMAIL_ADDRESS,
  GW_NEW_ACCOUNT_TEMP_PHONE_NUMBER,
  LESSORS_OF_NON_RESIDENTIAL_BUILDINGS,
} from 'app/features/attune-bop/models/constants';
import { InformService } from 'app/core/services/inform.service';
import { brokerEmailTypes, UNACCEPTABLE_DBA_NAMES } from 'app/constants';
import { RESTRICTED_EMPLOYERS_STATES } from 'app/workers-comp/employers/constants';
import { UserService } from '../../../../core/services/user.service';
import {
  transformContacts,
  TransformedContacts,
} from '../../../../shared/helpers/broker-contact-helpers';
import { environment } from 'environments/environment';
import {
  OnboardingQueryParams,
  OnboardingService,
  SAMPLE_NOT_AVAILABLE_MESSAGE,
  SampleAccountTriggers,
} from 'app/shared/services/onboarding.service';
import { PreferenceService } from '../../../../shared/services/preference.service';
import { ZendeskService } from '../../../../shared/services/zendesk.service';
import { DEFAULT_SAMPLE, SampleAccountType } from 'app/shared/sample-accounts/sample-accounts';
import { shouldGetBopV2, shouldGetMeToo } from '../../../../shared/helpers/account-helpers';
import {
  BOP_GUIDELINE_LINK_ACCREDITED,
  BOP_GUIDELINE_LINK_BLACKBOARD,
  EMPLOYERS_WC_GUIDE,
  ATTUNE_WC_GUIDE,
} from 'app/features/support/models/support-constants';
import {
  ClassCodeSelection,
  InsurancePolicyAdminSource,
  InsuranceProductCode,
  isNeptuneQuotedQuoteResponse,
  NeptuneFloodQuotedQuoteResponse,
  ProductAvailability,
  ProductCombinationForAvailability,
} from 'app/features/digital-carrier/models/types';
import { DigitalCarrierQuoteService } from 'app/features/digital-carrier/services/digital-carrier-quote.service';
import { SentryService } from '../../../../core/services/sentry.service';
import { ReferralsService } from '../../../../shared/services/referrals.service';
import { HISCOX_GL_PL_GUIDE } from '../../../hiscox/models/constants';
import {
  validateAddressIsNotPO,
  validateNoSpecialCharactersInAddress,
} from 'app/features/attune-bop/models/form-validators';
import {
  FeatureFlagService,
  BOOLEAN_FLAG_NAMES,
  JSON_FLAG_NAMES,
} from '../../../../core/services/feature-flag.service';
import { SegmentService } from '../../../../core/services/segment.service';
import { getAttuneWCBrandName } from 'app/workers-comp/attune/helpers/branding';
import { PreferenceLevel } from '../../../../shared/services/book-mix.service';

type AttuneInsuranceProducts = InsuranceProductCode | 'hab';
type AttunePolicyAdminSources = InsurancePolicyAdminSource | 'attune';

@Component({
  selector: 'app-insured-account-new-form',
  templateUrl: './insured-account-new-form.component.html',
})
export class InsuredAccountNewFormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  loading = false;
  submitted = false;
  isBogusQuote = false;
  isBogusDbaName = false;
  isNewAccountComponent = true;
  isFormReadyToMount = true;
  displaySampleQuoteModal = false;
  isDevMode = isDevMode();

  // We do not want to indicate a product is ineligible before a state selection is made.
  // This value will be updated once a user selects a state.
  isBopStateEligible = true;
  isBopEligible = false;
  isLoadingEligibility = false;

  sampleAccountsEnabled = false;
  isSampleAccount = false;
  sampleAccountSelection = '';
  userSkippedSampleQuote = false;
  naicsSearchExpanded = true;

  // We do not want to indicate a product is ineligible before a state selection is made.
  // This value will be updated once a user selects a state.
  isEmployersWcStateEligible = true;
  isEmployersWcNaicsEligible = false;

  wcPreferenceLevel: PreferenceLevel | null = null;
  bopPreferenceLevel: PreferenceLevel | null = null;

  isAttuneWcEligible = false;
  isEverpeakEnabled = false;
  isAdpWcUser = false;
  attuneWcEnabledStates: string[];

  isGlNaicsEligible = false;
  isPlNaicsEligible = false;

  isLibertyMutualBopNaicsEligible = false;
  isLibertyMutualCpspNaicsEligible = false;

  isCyberNaicsEligible = false;

  productAvailabilities: ProductAvailability[];
  productAvailabilitiesLoading = true;

  creatingBop = false;
  creatingHab = false;
  creatingAttuneWc = false;
  creatingEmployersWc = false;
  creatingGl = false;
  creatingPl = false;
  creatingGlV4 = false;
  creatingPlV4 = false;
  creatingLibertyMutualBop = false;
  creatingLibertyMutualCpsp = false;
  creatingCoalitionCyberAdmitted = false;
  creatingNeptuneFlood = false;

  neptuneFloodQuote: NeptuneFloodQuotedQuoteResponse | null = null;
  showNeptuneFloodError = false;
  loadingNeptuneFlood = false;
  neptuneFloodCreatedAccountId: string;

  model: InsuredAccount = new InsuredAccount();
  currentContact: any;
  cachedAccounts?: InsuredAccount[];
  matchedDuplicate: InsuredAccount | undefined;

  attemptedNaicsValues: Record<string, string>[] = [];
  attemptedNaicsCount = 0;

  eligibilityLiveChatProducers = environment.eligibilityLiveChatProducers;
  userProducerCode: string;

  user: User;
  userContacts: TransformedContacts;

  private sub: Subscription = new Subscription();

  naicsEligibilitySubject: Subject<{
    naicsHash: string;
    state: string;
    eligibility: ProductEligibility;
  }> = new Subject();

  showLiveChatNudge: boolean;
  showLiveChatWhenOnline = false;
  hiscoxGlPlGuidelinesLink = HISCOX_GL_PL_GUIDE;
  employersWcGuidelinesLink = EMPLOYERS_WC_GUIDE;
  attuneWcGuidelinesLink = ATTUNE_WC_GUIDE;
  public getAttuneWCBrandName = getAttuneWCBrandName;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private insuredAccountService: InsuredAccountService,
    private amplitudeService: AmplitudeService,
    private informService: InformService,
    private naicsService: NaicsService,
    private userService: UserService,
    private onboardingService: OnboardingService,
    private zendeskService: ZendeskService,
    private digitalCarrierQuoteService: DigitalCarrierQuoteService,
    private sentryService: SentryService,
    private referralsService: ReferralsService,
    private featureFlagService: FeatureFlagService,
    private segmentService: SegmentService,
    private preferenceService: PreferenceService
  ) {
    this.form = this.formBuilder.group({
      companyName: ['', Validators.required],
      description: ['', Validators.maxLength(170)],
      doingBusinessAs: [''],
      website: [null, urlValidator],
      naicsCode: [null, Validators.required],
      phoneNumber: [GW_NEW_ACCOUNT_TEMP_PHONE_NUMBER],
      emailAddress: [GW_NEW_ACCOUNT_TEMP_EMAIL_ADDRESS],
      addressLine1: [
        null,
        [
          Validators.required,
          Validators.maxLength(60),
          validateAddressIsNotPO,
          validateNoSpecialCharactersInAddress,
        ],
      ],
      addressLine2: [
        null,
        [Validators.maxLength(60), validateAddressIsNotPO, validateNoSpecialCharactersInAddress],
      ],
      city: [null, Validators.required],
      state: [null, Validators.required],
      zip: [null, [Validators.required, zipCodeValidator]],
    });
  }

  createProduct(pas: AttunePolicyAdminSources, product: AttuneInsuranceProducts) {
    this.creatingBop = false;
    this.creatingHab = false;
    this.creatingAttuneWc = false;
    this.creatingEmployersWc = false;
    this.creatingGl = false;
    this.creatingPl = false;
    this.creatingGlV4 = false;
    this.creatingPlV4 = false;
    this.creatingLibertyMutualBop = false;
    this.creatingLibertyMutualCpsp = false;
    this.creatingCoalitionCyberAdmitted = false;
    this.creatingNeptuneFlood = false;

    if (pas === 'attune' && product === 'bop') {
      this.creatingBop = true;
    }
    if (pas === 'attune' && product === 'hab') {
      this.creatingHab = true;
    }
    if (pas === 'attune' && product === 'wc') {
      this.creatingAttuneWc = true;
    }
    if (pas === 'employers' && product === 'wc') {
      this.creatingEmployersWc = true;
    }
    if (pas === 'hiscox' && product === 'gl') {
      this.creatingGlV4 = true;
    }
    if (pas === 'hiscox' && product === 'pl') {
      this.creatingPlV4 = true;
    }
    if (pas === 'liberty_mutual' && product === 'bop') {
      this.creatingLibertyMutualBop = true;
    }
    if (pas === 'liberty_mutual' && product === 'cpsp') {
      this.creatingLibertyMutualCpsp = true;
    }
    if (pas === 'coalition' && product === 'cyber_admitted') {
      this.creatingCoalitionCyberAdmitted = true;
    }
    if (pas === 'neptune' && product === 'flood') {
      this.creatingNeptuneFlood = true;
    }
  }

  createNeptuneFlood(): void {
    this.createProduct('neptune', 'flood');
    this.submitForm();
  }

  isEditingAccount() {
    return !!(this.route.snapshot && this.route.snapshot.queryParams['existing-account']);
  }

  accountId() {
    if (this.route.snapshot) {
      return this.route.snapshot.queryParams['existing-account'];
    }
    return '';
  }

  getCurrentState() {
    const stateField = this.form.get('state');
    return (stateField && stateField.value) || '';
  }

  isMeToo() {
    return shouldGetMeToo(this.getCurrentState());
  }

  isBopV2() {
    if (this.getCurrentState()) {
      return shouldGetBopV2(this.getCurrentState());
    }
    // As nearly all states have been cutover to bop+ we want to show
    // bop+ badge/guidelines by default before a state is entered into the address details.
    return true;
  }

  getBopGuidelinesLink() {
    if (this.isBopV2()) {
      return BOP_GUIDELINE_LINK_ACCREDITED;
    } else {
      return BOP_GUIDELINE_LINK_BLACKBOARD;
    }
  }

  ngOnInit() {
    const { snapshot } = this.route;
    const referralUuid = snapshot && snapshot.queryParams['referral'];
    if (referralUuid) {
      this.referralsService.trackClick(referralUuid).subscribe(() => {
        this.amplitudeService.track({
          eventName: 'intra-referral-click',
          detail: referralUuid,
        });
      });
    }

    const code = snapshot && snapshot.queryParams['naicsCode.code'];
    const description = snapshot && snapshot.queryParams['naicsCode.description'];
    const hash = snapshot && snapshot.queryParams['naicsCode.hash'];
    const state = snapshot && snapshot.queryParams['state'];

    if (state) {
      this.form.patchValue({ state: state });
    }

    if (code && description && hash) {
      this.form.patchValue({
        naicsCode: {
          code,
          description,
          hash,
        },
      });
    }

    this.sub.add(
      this.featureFlagService
        .isEnabled(BOOLEAN_FLAG_NAMES.EVERPEAK_WORKERS_COMP)
        .subscribe((enabled) => {
          this.isEverpeakEnabled = enabled || false;
        })
    );

    this.sub.add(
      this.featureFlagService
        .getJsonFlagValue<string[]>(JSON_FLAG_NAMES.ATTUNE_WC_ENABLED_STATES)
        .subscribe((attuneWcEnabledStates) => {
          this.attuneWcEnabledStates = attuneWcEnabledStates || [];
        })
    );

    this.sub.add(
      this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.ADP_WC_ACCESS).subscribe((enabled) => {
        this.isAdpWcUser = enabled || false;
      })
    );

    this.sub.add(
      combineLatest([
        this.featureFlagService.isEnabled(BOOLEAN_FLAG_NAMES.BOP_CLASS_PREFERENCE),
        this.naicsEligibilitySubject,
      ])
        .pipe(
          switchMap(([bopPreferenceIsEnabled, { naicsHash, state: naicsState, eligibility }]) => {
            if (!!bopPreferenceIsEnabled && !!eligibility?.isBopEligible) {
              return this.getBopPreference(naicsHash, naicsState);
            }
            return observableOf(null);
          })
        )
        .subscribe((bopPreferenceLevel) => {
          this.bopPreferenceLevel = bopPreferenceLevel?.preferenceLevel ?? null;
        })
    );

    this.sub.add(
      this.naicsEligibilitySubject
        .pipe(
          switchMap(({ naicsHash, state: naicsState, eligibility }) => {
            if (eligibility?.isAttuneWcEligible) {
              return this.getWcPreference(naicsHash, naicsState);
            }
            return observableOf(null);
          })
        )
        .subscribe((wcPreferenceLevel) => {
          this.wcPreferenceLevel = wcPreferenceLevel?.preferenceLevel ?? null;
        })
    );

    // If we patched a value in, we need to update the product eligibility
    if (state && hash) {
      this.getProductEligibility(hash, state).subscribe(
        (eligibility) => this.processNaicsEligibility(eligibility, true),
        this.processNaicsFailure
      );
    } else if (hash) {
      this.naicsService
        .getProductEligibility(hash)
        .subscribe(
          (eligibility) => this.processNaicsEligibility(eligibility, true),
          this.processNaicsFailure
        );
    }

    const sampleAccountsEnabled$ = this.onboardingService.featureEnabled().pipe(
      tap((value) => (this.sampleAccountsEnabled = value === null ? false : value)),
      shareReplay()
    );

    this.sub.add(sampleAccountsEnabled$.subscribe());

    this.sub.add(
      this.insuredAccountService
        .getCurrentContact()
        .subscribe((currentContact) => (this.currentContact = currentContact))
    );

    this.sub.add(
      this.insuredAccountService
        .listCached()
        .subscribe((cachedAccounts) => (this.cachedAccounts = cachedAccounts))
    );

    this.sub.add(
      this.userService.getUser().subscribe((user) => {
        this.user = user;
        if (this.eligibilityLiveChatProducers.includes(this.user.producer)) {
          this.showLiveChatWhenOnline = true;
        }
        this.sub.add(
          this.insuredAccountService
            .getProducerCodeDetails(user.producer)
            .subscribe((producerCodeDetails) => {
              this.userContacts = transformContacts(
                _.get(producerCodeDetails, 'ProdCodeCommContacts_ATTN.Entry', []),
                'Contact',
                'ProdCodComContactRol_ATTN.Entry',
                'CommunicationType_ATTN'
              );

              // send event to segment
              this.segmentService.track({
                event: 'Account Create Started',
                properties: {
                  user: this.user,
                  producer_code: this.user.producer,
                },
              });
            })
        );
      })
    );

    this.subscribeToNaicsChanges();
    this.subscribeToStateChanges();
    this.liveChatPoll();

    this.naicsService.getProductAvailability().subscribe((resp) => {
      this.productAvailabilities = resp;
      this.productAvailabilitiesLoading = false;
    });

    this.sub.add(
      sampleAccountsEnabled$
        .pipe(
          tap((sampleAccountsEnabled) => this.setSampleAccountInformation(sampleAccountsEnabled))
        )
        .subscribe()
    );

    this.sub.add(
      sampleAccountsEnabled$
        .pipe(
          switchMap((sampleAccountsEnabled) => this.registerSampleQuoteSkip(sampleAccountsEnabled))
        )
        .subscribe()
    );

    if (this.isEditingAccount()) {
      this.sub.add(
        this.insuredAccountService.get(this.accountId()).subscribe((account: InsuredAccount) => {
          this.model = account;

          this.form.patchValue({
            companyName: account.companyName,
            organizationType: account.organizationType,
            phoneNumber: account.phoneNumber,
            emailAddress: account.emailAddress,
            addressLine1: account.addressLine1,
            addressLine2: account.addressLine2,
            city: account.city,
            state: account.state,
            zip: account.zip,
            website: account.website,
            naicsCode: account.naicsCode,
            description: account.description,
            doingBusinessAs: account.doingBusinessAs,
          });
        })
      );
    }
  }

  submitForm(): void {
    this.submitted = true;

    if (this.form.valid) {
      this.loading = true;

      if (this.creatingNeptuneFlood) {
        this.loadingNeptuneFlood = true;
      }

      if (this.isEditingAccount()) {
        this.updateAccount();
      } else {
        this.createAccount();
      }
    }
  }

  updateAccount() {
    const formData = this.form.value;
    if (formData.emailAddress === '' || formData.phoneNumber === '') {
      formData.emailAddress = GW_NEW_ACCOUNT_TEMP_EMAIL_ADDRESS;
      formData.phoneNumber = GW_NEW_ACCOUNT_TEMP_PHONE_NUMBER;
    }
    const payload = { id: this.accountId(), ...formData };
    this.loading = true;
    this.sub.add(
      this.insuredAccountService
        .edit(payload)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(() => {
          this.logNaicsAttempts(this.accountId());
          this.insuredAccountService.cachebust();
          this.amplitudeService.track({
            eventName: 'account_edit',
            detail: this.accountId(),
            useLegacyEventName: true,
          });
          this.navigateToQuoteCreate(this.accountId());
        })
    );
  }

  logNaicsAttempts(accountId: string) {
    // log all attemped NAICS values, for UW alerts triggered by accounts trying a large number of NAICS values
    // truncate the list a little bit more until you get a result that fits in the Amplitude limit
    let naicsAttemptsLog;
    let naicsValueCount = 15;
    let truncated = this.attemptedNaicsCount > naicsValueCount;
    do {
      naicsAttemptsLog = JSON.stringify({
        accountId,
        truncated,
        attemptCount: this.attemptedNaicsCount,
        values: this.attemptedNaicsValues.slice(-naicsValueCount),
      });
      naicsValueCount--;
      truncated = true;
    } while (naicsValueCount > 1 && naicsAttemptsLog.length > 1024);

    this.amplitudeService.track({
      eventName: 'naics-attempts-completed-account',
      detail: naicsAttemptsLog,
      useLegacyEventName: true,
    });
  }

  createNeptuneFloodQuote(accountId: string) {
    this.loadingNeptuneFlood = true;
    this.insuredAccountService.get(accountId).subscribe((account) => {
      this.sub.add(
        this.digitalCarrierQuoteService
          .passiveQuote(this.digitalCarrierQuoteService.parseAccountForQuoteSubmit(account))
          .subscribe(
            (response) => {
              if (isNeptuneQuotedQuoteResponse(response)) {
                this.neptuneFloodQuote = response;
              } else {
                this.showNeptuneFloodError = true;
              }
            },
            (err) => {
              this.sentryService.notify(
                'Neptune Flood: Error submitting Neptune Flood passive quote',
                {
                  severity: 'error',
                  metaData: {
                    insuredAccountId: this.accountId,
                    errorMessage: err.message,
                  },
                }
              );
              this.showNeptuneFloodError = true;
            },
            () => (this.loadingNeptuneFlood = false)
          )
      );
    });
  }

  createAccount() {
    const createAccountPayload: InsuredAccount = this.form.value;
    if (this.sampleAccountsEnabled && this.isSampleAccount) {
      createAccountPayload.sampleAccount = true;
    }

    this.sub.add(
      this.insuredAccountService
        .create(createAccountPayload)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe((response: string | null) => {
          if (response) {
            this.amplitudeService.track({
              eventName: 'account_creation',
              detail: response,
              useLegacyEventName: true,
            });
            this.logNaicsAttempts(response);
            this.followDefaultContacts(response);
            if (this.creatingNeptuneFlood) {
              this.neptuneFloodCreatedAccountId = response;
              this.createNeptuneFloodQuote(response);
            } else {
              this.navigateToQuoteCreate(response);
            }
          } else {
            this.navigateToAccounts();
          }
        })
    );
  }

  // For ADP producers, we want to give the ability to create an account *even if no lines are eligible*.
  // This is used as a way of tracking incoming leads.
  // This replaces the previous "eligibility override" logic, which allowed these producers to create
  // (declined) quotes even for class codes that were not BOP-eligible.
  showAdpOption() {
    return (
      environment.eligibilityOverrideProducers &&
      environment.eligibilityOverrideProducers.includes(this.user.producer)
    );
  }

  navigateToAccounts() {
    if (this.neptuneFloodCreatedAccountId) {
      this.router.navigate([`/accounts/${this.neptuneFloodCreatedAccountId}`]);
    }
    this.router.navigate([`/accounts`]);
  }

  followDefaultContacts(accountId: string) {
    if (this.userContacts) {
      const userContactEntries = Object.entries(this.userContacts);
      const defaultContactEntries = userContactEntries.filter(([id, contactValue]) => {
        return contactValue.isDefaultContact;
      });
      if (defaultContactEntries.length) {
        defaultContactEntries.forEach(([contactId, contact]) => {
          this.insuredAccountService.followAccount(accountId, contactId, contact.roles).subscribe();
        });
      } else if (this.currentContact) {
        this.insuredAccountService
          .followAccount(accountId, this.currentContact.PublicID, Object.keys(brokerEmailTypes))
          .subscribe();
      }
    }
  }

  getQuoteCreateRoute(accountId: string): { route: [string]; extras: NavigationExtras } {
    const accountQueryParam = { 'from-account-create': true };
    const classQueryParam = { [OnboardingQueryParams.CLASS]: this.sampleAccountSelection };

    if (this.sampleAccountsEnabled && this.isSampleAccount) {
      return {
        route: [`sample/accounts/${accountId}/bop/new`],
        extras: { queryParams: { ...classQueryParam } },
      };
    }

    if (this.creatingBop) {
      return {
        route: [`/accounts/${accountId}/bop/new`],
        extras: { queryParams: { ...accountQueryParam } },
      };
    } else if (this.creatingAttuneWc) {
      return {
        route: [`/accounts/${accountId}/attune/workers-comp/new`],
        extras: { queryParams: { ...accountQueryParam } },
      };
    } else if (this.creatingEmployersWc) {
      return {
        route: [`/accounts/${accountId}/workers-comp/new`],
        extras: { queryParams: { ...accountQueryParam } },
      };
    } else if (this.creatingHab) {
      return {
        route: [`/accounts/${accountId}/hab/new`],
        extras: { queryParams: { ...accountQueryParam } },
      };
    } else if (this.creatingGlV4) {
      return {
        route: [`/accounts/${accountId}/gl/new`],
        extras: { queryParams: { ...accountQueryParam } },
      };
    } else if (this.creatingPlV4) {
      return {
        route: [`/accounts/${accountId}/pl/new`],
        extras: { queryParams: { ...accountQueryParam } },
      };
    } else if (this.creatingLibertyMutualBop) {
      return {
        route: [`/accounts/${accountId}/lm-bop/new`],
        extras: { queryParams: { ...accountQueryParam } },
      };
    } else if (this.creatingLibertyMutualCpsp) {
      return {
        route: [`/accounts/${accountId}/lm-cpsp/new`],
        extras: { queryParams: { ...accountQueryParam } },
      };
    } else if (this.creatingCoalitionCyberAdmitted) {
      return {
        route: [`/accounts/${accountId}/cyber-admitted/new`],
        extras: { queryParams: { ...accountQueryParam } },
      };
    }

    return { route: ['/accounts/'], extras: { queryParams: { ...accountQueryParam } } };
  }

  navigateToQuoteCreate(accountId: string) {
    const { route, extras } = this.getQuoteCreateRoute(accountId);
    this.router.navigate(route, extras);
  }

  subscribeToNaicsChanges() {
    this.sub.add(
      (<UntypedFormControl>this.form.get('naicsCode')).valueChanges
        .pipe(
          map((naicsCode: NaicsCode) => naicsCode.hash),
          switchMap((naicsHash: string) => {
            this.isLoadingEligibility = true;
            const state = this.form.get('state') as UntypedFormControl;

            this.wcPreferenceLevel = null;
            this.bopPreferenceLevel = null;
            if (state && state.value) {
              return this.getProductEligibility(naicsHash, state.value);
            } else {
              return this.naicsService.getProductEligibility(naicsHash);
            }
          })
        )
        .subscribe((eligibility) => {
          this.processNaicsEligibility(eligibility, true);
        }, this.processNaicsFailure)
    );
  }

  getProductEligibility(naicsHash: string, state: string) {
    return this.naicsService
      .getProductEligibility(naicsHash, state)
      .pipe(
        tap((eligibility) => this.naicsEligibilitySubject.next({ naicsHash, state, eligibility }))
      );
  }

  getBopPreference(naicsHash: string, state: string) {
    return this.naicsService.getAccreditedBopNaicsMapping(naicsHash).pipe(
      switchMap((industry) => {
        if (industry.length === 1) {
          return this.preferenceService.getPreference('bop', industry[0].id, state);
        } else {
          return observableOf(null);
        }
      })
    );
  }

  getWcPreference(naicsHash: string, state: string) {
    return this.naicsService.getNcciWcNaicsMapping(naicsHash).pipe(
      switchMap((industry) => {
        if (industry.length >= 1) {
          const codes = _.map(industry, 'id');
          return this.preferenceService.getPreferenceForMultipleCodes('wc', codes, state);
        } else {
          return observableOf(null);
        }
      })
    );
  }

  processNaicsEligibility = (
    eligibility: ProductEligibility,
    updateAttemptCountsAndValues: boolean
  ) => {
    if (updateAttemptCountsAndValues) {
      this.attemptedNaicsCount++;
      this.attemptedNaicsValues.push({
        code: (<UntypedFormControl>this.form.get('naicsCode')).value.code,
        description: (<UntypedFormControl>this.form.get('naicsCode')).value.description,
      });
    }

    this.naicsSearchExpanded = false;

    this.isBopEligible = eligibility.isBopEligible;
    this.isAttuneWcEligible = eligibility.isAttuneWcEligible;
    this.isEmployersWcNaicsEligible = eligibility.isEmployersWcEligible;
    this.isLibertyMutualBopNaicsEligible = eligibility.isLibertyMutualBopEligible;
    this.isLibertyMutualCpspNaicsEligible = eligibility.isLibertyMutualCpspEligible;
    this.isCyberNaicsEligible = eligibility.isCyberEligible;
    this.isGlNaicsEligible = eligibility.isGlEligible;
    this.isPlNaicsEligible = eligibility.isPlEligible;

    const eligibilitySummary = {
      bop: this.isBopEligible,
      attunWc: this.isAttuneWcEligible,
      employersWc: this.isEmployersWcNaicsEligible,
      gl: this.isGlNaicsEligible,
      pl: this.isPlNaicsEligible,
      lm_bop: this.isLibertyMutualBopNaicsEligible,
      lm_cpsp: this.isLibertyMutualCpspNaicsEligible,
      cyber: this.isCyberNaicsEligible,
    };
    this.amplitudeService.trackWithOverride({
      eventName: 'new_account_line_eligibility',
      detail: 'eligibility_update',
      payloadOverride: { eligibilitySummary: JSON.stringify(eligibilitySummary) },
    });

    this.delayLoadingDone();
  };

  delayLoadingDone() {
    this.sub.add(
      timer(700).subscribe(() => {
        this.isLoadingEligibility = false;
      })
    );
  }

  processNaicsFailure = () => {
    this.isLoadingEligibility = false;
  };

  displayProductCard({ pasSource, product }: ProductCombinationForAvailability) {
    const productAvailability = _.find(this.productAvailabilities, {
      pasSource,
      product,
    }) as ProductAvailability;

    if (!productAvailability) {
      return false;
    }

    // The entire Product Card is intentionally not displayed if a broker (via
    // their producercode) doesn't have access to a certain insurance product.
    return productAvailability.classCodeSelection !== ClassCodeSelection.NONE;
  }

  isProductAvailable({ pasSource, product }: ProductCombinationForAvailability) {
    const productAvailability = _.find(this.productAvailabilities, {
      pasSource,
      product,
    }) as ProductAvailability;

    if (!productAvailability) {
      return false;
    }

    if (productAvailability.classCodeSelection === 'ALL') {
      return true;
    }

    const naicsCode = _.get(this.form.get('naicsCode'), 'value.code', '');

    return (
      productAvailability.classCodeSelection === 'PARTIAL' &&
      _.includes(productAvailability.classCodes.naics, naicsCode)
    );
  }

  subscribeToStateChanges() {
    this.sub.add(
      (<UntypedFormControl>this.form.get('state')).valueChanges.subscribe((state: string) => {
        if (state) {
          this.insuredAccountService
            .isProducerCatRestricted(this.user.producer, state)
            .subscribe((isRestricted) => {
              this.isBopStateEligible = !isRestricted;
            });

          this.isEmployersWcStateEligible = !RESTRICTED_EMPLOYERS_STATES.includes(state);

          const naicsFormControl = this.form.get('naicsCode') as UntypedFormControl;

          // if addLROStep was clicked/called before CA was selected, reset the naics code dropdown
          if (
            state === 'CA' &&
            naicsFormControl.value?.hash === LESSORS_OF_NON_RESIDENTIAL_BUILDINGS.hash
          ) {
            this.form.patchValue({
              naicsCode: [null],
            });
          }
          if (naicsFormControl && naicsFormControl.value) {
            this.isLoadingEligibility = true;
            const naicsHash = naicsFormControl.value.hash;

            this.getProductEligibility(naicsHash, state).subscribe(
              (eligibility) => this.processNaicsEligibility(eligibility, false),
              this.processNaicsFailure
            );
          }
        } else {
          this.isEmployersWcStateEligible = true;
        }
      })
    );
  }

  liveChatPoll() {
    this.sub.add(
      timer(1, 3000).subscribe(() => {
        this.showLiveChatNudge = this.checkChatAvailable();
      })
    );
  }

  setSampleAccountInformation(sampleAccountsEnabled: boolean | null) {
    if (!sampleAccountsEnabled) {
      this.isSampleAccount = false;
      this.sampleAccountSelection = '';
      return;
    }

    const [urlRoot] = this.route.snapshot.url;
    this.isSampleAccount = urlRoot.path === 'sample';

    if (this.isSampleAccount) {
      this.sampleAccountSelection =
        this.route.snapshot.queryParams[OnboardingQueryParams.CLASS] || DEFAULT_SAMPLE;

      const sampleAccountInfo = OnboardingService.getSampleAccountInfo(
        this.sampleAccountSelection as SampleAccountType
      );

      this.form.patchValue(sampleAccountInfo);
    }
  }

  registerSampleQuoteSkip(sampleAccountsEnabled: boolean | null) {
    if (sampleAccountsEnabled) {
      return this.route.queryParams.pipe(
        tap((params) => {
          this.userSkippedSampleQuote = !!params[OnboardingQueryParams.SKIPPED_SAMPLE_QUOTE];
        })
      );
    }

    return EMPTY;
  }

  goBack() {
    if (this.userSkippedSampleQuote) {
      this.displaySampleQuoteModal = true;
    } else {
      this.router.navigate(['/accounts']);
    }
  }

  handleSampleQuoteModalClose(result: { goToSampleQuote: boolean }) {
    if (result.goToSampleQuote) {
      this.displaySampleQuoteModal = false;
      this.router.navigate(['/onboarding', 'introduction'], {
        queryParams: { [OnboardingQueryParams.FROM_MODAL]: true },
      });
    } else {
      this.onboardingService
        .createSampleAccountForUser({
          sampleAccountTrigger: SampleAccountTriggers.CANCEL_QUOTE,
        })
        .pipe(
          tap((sampleAccountId: string) => {
            this.displaySampleQuoteModal = false;
            this.router.navigate(['/accounts', sampleAccountId]);
          })
        )
        .subscribe();
    }
  }

  checkChatAvailable() {
    if (!this.showLiveChatWhenOnline) {
      return false;
    }
    if (!this.zendeskService.ready()) {
      return false;
    }
    return this.zendeskService.isChatLive();
  }

  addLROClass() {
    this.form.patchValue({
      naicsCode: LESSORS_OF_NON_RESIDENTIAL_BUILDINGS,
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  checkForBogusQuote() {
    if (this.form.value.companyName) {
      const name = this.form.value.companyName.toLowerCase();
      this.isBogusQuote =
        name.includes('aig') ||
        name.includes('test') ||
        name.includes('american international group');
    }
    this.checkForDuplicateAccount();
  }

  checkForDuplicateAccount() {
    if (this.cachedAccounts && this.form.value.companyName) {
      this.matchedDuplicate = this.cachedAccounts.find((n: InsuredAccount) =>
        n.companyName.toLowerCase().includes(this.form.value.companyName.toLowerCase())
      );
      if (this.matchedDuplicate) {
        this.amplitudeService.track({
          eventName: 'new_account_duplicate_found',
          detail: this.matchedDuplicate.companyName,
          useLegacyEventName: true,
        });
      }
    } else {
      this.matchedDuplicate = undefined;
    }
  }

  checkForBogusDbaName() {
    const dbaName = this.form.value.doingBusinessAs;

    if (dbaName || dbaName === '') {
      this.isBogusDbaName = UNACCEPTABLE_DBA_NAMES.some((unacceptableDbaName) => {
        return unacceptableDbaName.toLowerCase() === dbaName.toLowerCase();
      });
    }
  }

  naicsFormatter(code: NaicsCode) {
    return code.description;
  }

  getNaicsCodes = (searchTerm: string) => {
    const naicsCodes = getNaicsCodes(this.naicsService, searchTerm);
    if (this.getCurrentState() === 'CA') {
      return naicsCodes.pipe(
        map((codes: NaicsCode[]) =>
          codes.filter((code: NaicsCode) => code.hash !== LESSORS_OF_NON_RESIDENTIAL_BUILDINGS.hash)
        )
      );
    }
    return naicsCodes;
  };

  displayProductRestrictionNote() {
    if (this.isSampleAccount) {
      this.informService.infoToast(SAMPLE_NOT_AVAILABLE_MESSAGE);
      return;
    }
  }

  public showSampleBopTip() {
    return this.sampleAccountsEnabled && this.isSampleAccount;
  }

  openChat() {
    const { firstName, lastName, userName } = this.user;
    const identity = {
      name: `${firstName} ${lastName}`,
      email: userName,
    };
    this.zendeskService.skipHelpCenter();
    this.zendeskService.widget.identify(identity);
    this.zendeskService.widget.addChatTags(['new-account-appetite']);
    this.zendeskService.widget.open();
    const descriptionForm = this.form.get('description');
    const description = descriptionForm && descriptionForm.value;
    const state = this.getCurrentState();
    const automatedMessageContent = [] as string[];
    if (state) {
      automatedMessageContent.push(`State: ${state}`);
    }
    if (description) {
      automatedMessageContent.push(`Business Description: ${description}`);
    }
    if (automatedMessageContent.length) {
      this.zendeskService.stageAutomaticMessage(automatedMessageContent.join('\n'));
    }
    this.zendeskService.unskipHelpCenter();
  }

  showEmployersProduct() {
    // We do not want to show the employers product
    // if we meet the conditions to show the Attune WC product.
    return !this.showAttuneWcProduct();
  }

  showAttuneWcProduct() {
    const attuneWCEligibleOrEmployersIneligible =
      this.isAttuneWcEligible ||
      !this.isEmployersWcNaicsEligible ||
      !this.isEmployersWcStateEligible ||
      !this.isProductAvailable({
        pasSource: 'employers',
        product: 'wc',
      });

    const state = this.getCurrentState();
    let inAllowedAttuneWcState = true;
    if (state) {
      inAllowedAttuneWcState = this.attuneWcEnabledStates.includes(state);
    }

    // Conditions to show Attune WC
    // 1. Attune WC enabled
    // 2. Attune WC is eligible OR employers product is ineligible/unavailable.
    // 3. Location state is in the list of enabled states for the broker
    return (
      this.isEverpeakEnabled && attuneWCEligibleOrEmployersIneligible && inAllowedAttuneWcState
    );
  }

  isBopPreferred() {
    return (
      !this.isLoadingEligibility &&
      this.isBopEligible &&
      this.isBopStateEligible &&
      this.bopPreferenceLevel &&
      ['1', '2'].includes(this.bopPreferenceLevel)
    );
  }

  isWcPreferred() {
    return (
      !this.isLoadingEligibility &&
      this.isAttuneWcEligible &&
      this.wcPreferenceLevel &&
      ['1', '2'].includes(this.wcPreferenceLevel)
    );
  }
}
