import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { V4_USER_API_URL, USER_API_URL } from 'app/constants';
import { AttuneProduct } from 'app/features/digital-carrier/models/types';
import { PreferenceLevel } from './book-mix.service';

export interface RecentQuotesResponse {
  totalNumber: number;
  recentQuotes: RecentQuotesData[];
}

export interface RecentBindsResponse {
  totalNumber: number;
  recentBinds: RecentBindsData[];
}

export interface RecentBindsStreakResponse {
  stats: {
    hasBoundThisMonth: boolean;
    streakLength: number;
    streakDeadline: string;
  };
  bindsPerMonth: { month: string; count: string }[];
}

export interface RecentQuotesData {
  accountId: string;
  effectiveDate: string;
  status: string;
  premium: string;
  accountName: string;
  product: string;
  pasId: string;
  pasSource: string;
}

export interface RecentBindsData {
  uuid: string;
  accountId: string;
  effectiveDate: string;
  boundDate: string;
  status: string;
  netPremium: string;
  accountName: string;
  product: string;
  policyNumber: string;
  pasSource: string;
  term: string;
  originalPremium: string;
  expirationDate: string;
  pasQuoteId: string;
  quoteId: string | null;
  producerCode: string;
  renewalPasSource: string | null;
  previousPolicyUuid: string | null;
  nextQuoteId: string | null;
  nextPolicyUuid: string | null;
  cancellationDate: string | null;
  cancellationCode: string | null;
  cancellationReason: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
  createdAt: string | null;
  classCodes: string[];
  classPreferenceLevel: PreferenceLevel;
  classCodeDescription?: string; // only for Attune WC
}

enum ProductType {
  BOP = 'BOP',
  GL = 'GL',
  PL = 'PL',
  WC = 'WC',
}

export interface BindsNearYouData {
  productType: ProductType;
  premium: string;
  classCodeDescription: string;
  classCode: string;
  boundDate: string;
}

export interface ClassCodeModuleData {
  zipCode: string;
  bindsNearYou: BindsNearYouData[];
}

@Injectable()
export class RecentActivitySummaryService {
  constructor(private http: HttpClient) {}

  getRecentQuotes(
    producerCode: string,
    index: number,
    limit: number
  ): Observable<RecentQuotesResponse> {
    const params = new HttpParams().appendAll({
      start: index,
      limit,
    });
    return this.http.get<RecentQuotesResponse>(`${V4_USER_API_URL}/${producerCode}/open-quotes`, {
      params,
    });
  }

  /**
   * This method is used to fetch the recent binds for the book mix list of recent binds and also
   * the recent binds for the list in the Activity page.
   *
   * @param producerCode for which the recent binds will be fetched
   * @param index start index, typically 0
   * @param limit max number of recent binds fetched; 3 for book mix list and 10 for activity view list
   * @param withPreferenceLevel false by default; true if recent binds should include only those with preference level
   * @param product optional, if recent binds are needed for a specific product
   * @param carrier options, if getting specific product and carrier recent binds
   * @returns
   */
  getRecentBinds(
    producerCode: string,
    index: number,
    limit: number,
    withPreferenceLevel: boolean = false,
    product?: AttuneProduct['product'],
    carrier?: AttuneProduct['pasSource']
  ): Observable<RecentBindsResponse> {
    let params = new HttpParams();
    const recentBindsUrl = `${V4_USER_API_URL}/${producerCode}/recent-binds`;
    if (product && carrier) {
      params = params.appendAll({
        start: index,
        limit,
        withPreferenceLevel,
        product,
        carrier,
      });
    } else {
      params = params.appendAll({
        start: index,
        limit,
        withPreferenceLevel,
      });
    }
    return this.http.get<RecentBindsResponse>(recentBindsUrl, {
      params,
    });
  }

  getBindsNearYou(producerCode: string): Observable<ClassCodeModuleData> {
    return this.http.get<ClassCodeModuleData>(`${USER_API_URL}/${producerCode}/binds-near-you`);
  }

  getRecentBindsForStreaks(): Observable<RecentBindsStreakResponse> {
    return this.http.get<RecentBindsStreakResponse>(`${V4_USER_API_URL}/streaks`);
  }
}
