<ng-container *ngIf="showBookMix()">
  <h3 class="js-book-mix-header">Agency book balance insights</h3>
  <ng-container *ngIf="hasBopPolicies && bopPreferenceEnabled">
    <div class="app-activity-book-mix-progress-container mb-2 js-bop-book-mix">
      <app-activity-book-mix-progress
        productType="bop"
        [productBookMix]="bookMixResponse.bop"
      ></app-activity-book-mix-progress>
    </div>
  </ng-container>
  <ng-container *ngIf="hasWcPolicies && wcPreferenceEnabled">
    <div class="app-activity-book-mix-progress-container js-wc-book-mix">
      <app-activity-book-mix-progress
        productType="wc"
        [productBookMix]="bookMixResponse.wc"
      ></app-activity-book-mix-progress>
    </div>
  </ng-container>
</ng-container>
