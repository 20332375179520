<app-quote-stepped-sidebar
  class="app-page-sidebar app-page-sidebar__quote app-page-sidebar__wc"
  product="wc"
  [steppedFormService]="formService"
  (navigateToStep)="handleNavigateToSlug($event)"
  [accountId]="accountId"
  [insAccount]="insuredAccount | async"
  sidebarTitle="Bind quote"
>
</app-quote-stepped-sidebar>

<div class="app-page-form-scrollable-wrapper">
  <div class="app-page-form">
    <a
      *ngIf="!isFirstStep()"
      class="emphasized-link form-previous-link"
      (click)="clickBackward()"
      (keyup.enter)="clickBackward()"
    >
      <span class="icon-full-arrow-left button-icon"></span>Previous step
    </a>

    <form [formGroup]="formService.form" (ngSubmit)="handleSubmit($event)" novalidate>
      <main *ngIf="!wcQuote" class="placeholder-form-container">
        <div class="placeholder-h1"></div>
        <div class="placeholder-form-label"></div>
        <div class="placeholder-form-input"></div>
        <div class="placeholder-form-label"></div>
        <div class="placeholder-form-input"></div>
        <div class="placeholder-form-label"></div>
        <div class="placeholder-form-input"></div>
        <div class="placeholder-form-label"></div>
        <div class="placeholder-form-input"></div>
        <div class="placeholder-form-submit"></div>
      </main>

      <main *ngIf="!(policyPaymentPresenter | async)">
        <h2>Loading presenter...</h2>
      </main>
      <main *ngIf="wcQuote && policyPaymentPresenter | async as policyPayment">
        <app-carrier-partner-servicing-info
          *ngIf="isCurrentStep('working-with-employers')"
          showHeading="{{ true }}"
          carrier="employers"
          product="Workers' Compensation"
        ></app-carrier-partner-servicing-info>
        <ng-container *ngIf="isCurrentStep('additional-requirements')">
          <header class="bind-quote-header">
            <h1>Additional requirements</h1>
          </header>

          <section
            *ngIf="accountDetails && accountDetails.state === 'FL'"
            class="bind-flow-subsection"
          >
            <h4 class="question-subsection-header js-acord-details">ACORD requirement</h4>
            <p>
              <strong>Florida requires an ACORD 130 to bind Workers' Compensation.</strong> Please
              download and fill out the form below, collect the insured's signature, and forward to
              <a href="mailto:help@attuneinsurance.com">help&#64;attuneinsurance.com</a>.
              <strong
                >If the form is not received within 30 days, the policy will be canceled.</strong
              >
            </p>
            <a
              class="document-download"
              href="https://s3.us-east-2.amazonaws.com/myattune-web-assets/public/documents/ACORD-130FL-Florida-Workers-Compensation-Application.pdf"
              target="_blank"
              style="margin-bottom: 4rem"
            >
              <img src="/assets/img/pdf_icon.svg" alt="" class="document-download-pdf-icon" />
              <span class="document-download-title">ACORD 130</span>
              <span class="document-download-icon icon-download"></span>
            </a>
          </section>

          <section class="bind-flow-subsection" *ngIf="wcQuote.hasExecutiveExclusion">
            <h3>Officer Exclusion details</h3>
            <p>
              If the insured has elected to exclude an officer or officers from its Workers'
              Compensation policy, the insured must comply with all applicable laws and regulations
              ("laws") and an appropriate Officer Exclusion certification form must be completed, in
              compliance with all laws, and signed by all required parties, including the insured.
              <strong>Please review the following: </strong>
            </p>
            <ul class="guideline-list">
              <li *ngFor="let term of executiveExclusionTerms">
                {{ term }}
              </li>
            </ul>
          </section>

          <section class="bind-flow-subsection">
            <app-form-field-checkbox
              inputId="accept-additional-requirement-terms"
              labelText="I have read and confirm the terms above."
              nameOfFormControl="agreedToAdditionalRequirementTerms"
              [submitted]="submitted()"
              [form]="formService.form.get('additionalRequirements')"
            >
            </app-form-field-checkbox>
          </section>
        </ng-container>
        <ng-container *ngIf="isCurrentStep('bind-quote')">
          <header class="bind-quote-header">
            <h1 [ngClass]="{ 'form-header-below-link': !isFirstStep() }">Bind quote</h1>
          </header>
          <div class="bind-quote-main">
            <app-form-field-text
              inputId="wc-bind-effectiveDate"
              [form]="formService.form.get('bindQuote')"
              [submitted]="submitted()"
              labelText="Effective Date"
              nameOfFormControl="effectiveDate"
              appDateMask
            >
            </app-form-field-text>
            <div class="helper-card-container">
              <app-form-field-text
                inputId="employerIdentificationNumber"
                [form]="formService.form.get('bindQuote')"
                [submitted]="submitted()"
                labelText="FEIN"
                nameOfFormControl="employerIdentificationNumber"
                placeholder="00-0000000"
                appFeinMask
                (focusOut)="checkFEINValue()"
              >
              </app-form-field-text>
              <div class="helper-card helper-card__no-margin">
                <div class="pill-tag pill-tag__main">Note</div>
                <p>Endorsing FEIN after bind may result in account audit and fines.</p>
              </div>
            </div>
            <div
              *ngIf="isFEINPlaceholderValue"
              class="dialog-text dialog-text__error js-fein-warning"
            >
              <strong>Is this a real FEIN number?</strong> FEINs are used to calculate premium, so
              please, no placeholder values. If you proceed with this FEIN, your account will be
              flagged for internal review.
            </div>
            <div class="validation" role="alert" *ngIf="effectiveDate.invalid">
              <p class="validation-message">Please enter a date that is today or after.</p>
            </div>

            <div
              class="bind-flow-subsection js-wc-bind-insured-details"
              *ngIf="insuredAccount | async as insuredAccount"
            >
              <h4 class="question-subsection-header">Insured details</h4>
              <dl>
                <dt>Legal business name</dt>
                <dd>{{ insuredAccount.companyName }}</dd>
              </dl>
              <dl>
                <dt>Mailing address</dt>
                <dd>
                  {{ insuredAccount.addressLine1 }} {{ insuredAccount.addressLine2 }}
                  {{ insuredAccount.city }},
                  {{ insuredAccount.state }}
                  {{ insuredAccount.zip }}
                </dd>
              </dl>
              <div class="form-split helper-card-container">
                <app-form-field-text
                  inputId="bind-emailAddress"
                  [form]="formService.form.get('bindQuote')"
                  [submitted]="submitted()"
                  labelText="Insured Email"
                  nameOfFormControl="emailAddress"
                  appRemoveWhitespaceMask
                >
                </app-form-field-text>
                <app-form-field-text
                  inputId="bind-phoneNumber"
                  [form]="formService.form.get('bindQuote')"
                  [submitted]="submitted()"
                  labelText="Phone Number"
                  nameOfFormControl="phoneNumber"
                >
                </app-form-field-text>
                <div class="helper-card helper-card__no-margin">
                  <div class="pill-tag pill-tag__main">Note</div>
                  <p>
                    Instructions for enrolling in the EMPLOYERS insured portal will be sent to the
                    insured email. Only the insured may enroll.
                  </p>
                </div>
              </div>
              <div
                *ngIf="
                  formService.form.get('bindQuote').controls.emailAddress.errors &&
                  formService.form.get('bindQuote').controls.emailAddress.errors.invalidEmailAddress
                "
                class="validation"
              >
                <span class="validation-message">Email Address must be a valid email address.</span>
              </div>
              <app-form-field-radio
                inputId="wc-bind-mailingBillingAddressesIdentical"
                [form]="formService.form.get('bindQuote')"
                [submitted]="submitted()"
                questionText="Is the insured mailing address the same as their billing address?"
                nameOfFormControl="mailingBillingAddressesIdentical"
              >
              </app-form-field-radio>
              <div
                *ngIf="!mailingAddressesMatch()"
                class="
                  js-wc-unableToBindMessage
                  dialog-text dialog-text__warning dialog-text__warning-icon
                "
              >
                We'll need to set the billing address for you. Please contact our Customer Care team
                by chat on our
                <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">helpdesk</a>
              </div>
            </div>

            <div class="bind-flow-subsection helper-card-container">
              <ng-container *ngIf="policyPayment">
                <ng-container *ngIf="showPaymentOptions()">
                  <app-form-field-radio
                    inputId="wc-bind-numberOfPayments"
                    [form]="formService.form.get('bindQuote')"
                    [submitted]="submitted()"
                    questionText="Payment plan"
                    [options]="paymentPlanOptions"
                    nameOfFormControl="numberOfPayments"
                  >
                  </app-form-field-radio>
                  <p
                    *ngIf="hasPrecisePayPaymentOption()"
                    class="p__field-description-text js-precise-pay-learn-more"
                  >
                    Need a pay-as-you-go plan? Check out PrecisePay
                    <a
                      target="_blank"
                      href="https://www.employers.com/resources/faqs/precise-pay-faqs"
                      >Learn more.</a
                    >
                  </p>
                </ng-container>
                <div *ngIf="policyPayment.installments > 0">
                  <h5 class="payment-subtitle">Downpayment</h5>
                  <dl class="dl__no-dotted-line">
                    <dt>Premium</dt>
                    <dd class="js-wc-payment-details-downPayment">
                      {{ policyPayment.downPaymentPremium | currency }}
                    </dd>
                  </dl>
                  <dl class="dl__subtotal dl__no-dotted-line">
                    <dt>Subtotal</dt>
                    <dd>
                      <strong class="js-wc-payment-details-downPayment-subtotal">{{
                        policyPayment.downPaymentSubtotal | currency
                      }}</strong>
                    </dd>
                  </dl>
                  <h5 class="payment-subtitle">Installments x {{ policyPayment.installments }}</h5>
                  <dl class="dl__no-dotted-line">
                    <dt>Installment Premium</dt>
                    <dd class="js-wc-payment-details-installment">
                      {{ policyPayment.installmentPremium | currency }}
                    </dd>
                  </dl>
                  <dl class="dl__subtotal dl__no-dotted-line">
                    <dt>Subtotal</dt>
                    <dd>
                      <strong class="js-wc-payment-details-downPayment-subtotal">{{
                        policyPayment.installmentPremium * policyPayment.installments | currency
                      }}</strong>
                    </dd>
                  </dl>
                </div>
                <div *ngIf="policyPayment.installments === 0">
                  <dl class="dl__no-dotted-line">
                    <dt>Premium</dt>
                    <dd class="js-wc-payment-details-premium">
                      {{ policyPayment.downPaymentPremium | currency }}
                    </dd>
                  </dl>
                </div>
                <div class="bind-quote-total">
                  <div class="bind-quote-total-lineitem">
                    <strong>Estimated total cost*</strong>
                    <div class="big-total js-wc-payment-details-total">
                      <span>$</span
                      >{{ policyPayment.estimatedTotalIntegral | number: '2.0-0' }}.<span
                        class="big-total-cents"
                        >{{ policyPayment.estimatedTotalFractional | number: '2.0-0' }}</span
                      >
                    </div>
                  </div>
                  <small class="gray-text"
                    >* Please refer to the quote letter for calculated taxes, fees, surcharges and
                    payment plan installment fees which may be applicable to this quotation.</small
                  >
                </div>
              </ng-container>
              <div class="helper-card helper-card__no-margin">
                <div class="pill-tag pill-tag__main">Note</div>
                <p>Accounts with premiums less than $1,000 only have the option of one payment.</p>
              </div>
            </div>
          </div>
        </ng-container>
      </main>

      <div class="form-footer">
        <div class="validation" role="alert" *ngIf="submitted() && !isCurrentStepValid()">
          <p class="validation-message">Please fill out all required fields</p>
        </div>
        <div
          *ngIf="uneditedPreviouslyReferred()"
          class="
            js-wc-unableToBindMessage
            dialog-text dialog-text__warning dialog-text__warning-icon
          "
          role="alert"
        >
          At this time, we're unable to automatically bind quotes that went through the referral
          process. Please contact
          <a href="https://attunehelp.zendesk.com/hc/en-us/requests/new">helpdesk</a>
          with the account you wish to bind.
        </div>

        <div
          *ngIf="showBindQuoteButton()"
          class="
            nav-button-group nav-button-group__left nav-button-group__centered
            js-bindQuoteButton
          "
        >
          <button
            *ngIf="!isFinalStep()"
            id="quote-form-next-button"
            [ngClass]="{
              button: true,
              'js-step-forward-button': true,
              button__discouraged: !this.isCurrentStepValid()
            }"
            type="submit"
            class="button__primary"
            [disabled]="submitted() && !isCurrentStepValid()"
          >
            Next
          </button>

          <app-form-field-checkbox
            *ngIf="isCurrentStep('working-with-employers')"
            inputId="doNotShowAgain"
            labelText="Don't show me this again"
            [form]="formService.form.get('workingWithEmployers')"
            nameOfFormControl="doNotShowAgain"
            cssClass="form-field__no-margin ml-3"
          ></app-form-field-checkbox>

          <button
            *ngIf="this.isFinalStep()"
            type="submit"
            [ngClass]="{
              button: true,
              button__primary: true,
              'js-bind-wc-quote-button': true,
              button__discouraged: !this.isCurrentStepValid()
            }"
            [disabled]="isBinding || (submitted() && !isCurrentStepValid())"
          >
            <div class="button-loader" *ngIf="isBinding">
              <svg class="circle-spinner">
                <circle
                  cx="11"
                  cy="11"
                  r="9"
                  stroke-width="2"
                  stroke-dasharray="0,1000"
                  d="M5 40 l215 0"
                  fill="transparent"
                />
              </svg>
            </div>
            <span *ngIf="!isBinding">Bind quote</span>
            <span *ngIf="isBinding">Binding quote...</span>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="app-page-form-side-rail"></div>
</div>

<app-quote-error-modal
  [open]="errorModalOpen"
  buttonText="Back to Account"
  (closeQuoteErrorModal)="navigateToAccountsPage()"
  errorHeader="Error"
>
  <p>
    We're having trouble issuing this policy. Please try again or contact the customer care team if
    you continue to have this issue.
  </p>
</app-quote-error-modal>
<app-price-difference-modal
  [displayPriceDiffModal]="displayPriceDiffModal"
  [originalTotalCost]="oldPremium"
  [newTotalCost]="newPremium"
  [isWcQuote]="true"
  (triggerNavigationToAccount)="navigateToAccountsPage()"
  (triggerBindServiceCall)="callBindService()"
></app-price-difference-modal>

<ng-container *ngIf="isBinding">
  <app-binding-animation [showProgressBar]="true" [finish$]="bindSuccess$"></app-binding-animation>
</ng-container>
