import { Component, Input } from '@angular/core';
import { AttuneProduct } from 'app/features/digital-carrier/models/types';

@Component({
  selector: 'app-activity-book-mix-progress-bar',
  templateUrl: './activity-book-mix-progress-bar.component.html',
})
export class ActivityBookMixProgressBarComponent {
  @Input() targetPercentage: number;
  @Input() preferredCount: number;
  @Input() nonPreferredCount: number;
  @Input() targetCount: number;
  @Input() calculationStartDate: number;
  @Input() productType: AttuneProduct['product'];

  get progressWidth() {
    const total = this.preferredCount + this.nonPreferredCount;
    const preferredWidth = (this.preferredCount / total) * 100;
    return `${preferredWidth}%`;
  }

  get midLabelTextPosition() {
    return `${this.targetPercentage}%`;
  }

  get midLabelMarkerLeftPosition() {
    return `${this.targetPercentage}%`;
  }

  getLeftLabel() {
    const totalPolicies = this.preferredCount + this.nonPreferredCount;
    return Math.round((this.preferredCount / totalPolicies) * 100);
  }

  getRightLabel() {
    const totalPolicies = this.preferredCount + this.nonPreferredCount;
    return Math.round((this.nonPreferredCount / totalPolicies) * 100);
  }

  getProgressMessage() {
    if (this.preferredCount < this.targetCount) {
      const policiesLeftToGo = this.targetCount - this.preferredCount;
      return `Bind ${policiesLeftToGo} more preferred policies to hit the target.`;
    } else {
      return 'You are on target, great job!';
    }
  }
}
