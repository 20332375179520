<ng-container>
  <app-left-navigation></app-left-navigation>
  <router-outlet></router-outlet>

  <section class="rewards-main">
    <header class="rewards-page-header">
      <div class="rewards-header-left">
        <h1 *ngIf="isRewardsTierEnabled === false" class="h1 h1__rewards">Attune Rewards</h1>
        <h1 *ngIf="isRewardsTierEnabled === true" class="h1 h1__rewards">My Rewards</h1>
      </div>
      <div *ngIf="isRewardsActivityEnabled === true" class="rewards-header-right">
        <button
          class="rewards-button button button__primary button__no-margin"
          aria-disabled="false"
          (click)="viewRewards()"
        >
          Redeem coin
        </button>
      </div>
    </header>

    <ng-container *ngIf="isRewardsTierEnabled === true">
      <p class="p p__rewards js-tier-message" *ngIf="rewardsTier !== 'platinum'">
        You're currently at the {{ rewardsTier | titlecase }} level of rewards. You earn
        {{ tierCoins }} coins for each new business bind. To explore tiered rewards and benefits,
        click here to
        <a (click)="showRewardsInfoModal = true" (keyup.enter)="showRewardsInfoModal = true">
          learn more.
        </a>
        Your rewards level refreshes annually on January 1st, and Achievements refresh every three
        months so you can earn additional points.
      </p>
      <p class="p p__rewards js-tier-message" *ngIf="rewardsTier === 'platinum'">
        Congratulations! 🎉 You've reached our highest Platinum level! You earn
        {{ tierCoins }} coins for each new business bind. Your rewards level refreshes annually on
        January 1st, and Achievements refresh every three months so you can earn additional points.
      </p>

      <div class="rewards-container">
        <div class="rewards-tier-main">
          <div class="rewards-tier__{{ userTier }}">
            <div class="user-details__{{ userTier }}">
              {{ user.firstName }} {{ user.lastName }} | Member since
              {{ activatedDate | date: 'MM/dd/yyyy' }}
            </div>
            <div class="sub-title__{{ userTier }}">
              <h3>Your Insurecoin Balance</h3>
            </div>
            <div class="coins-balance__{{ userTier }}">
              {{ balance }}
              <img class="balance-coin" src="/assets/img/rewards_tier_coin.svg" alt="attune coin" />
            </div>
          </div>
          <div class="tier-progress-container">
            <app-activity-incentive-cyber-binds
              *ngIf="progressBarConfig"
              [config]="progressBarConfig"
            ></app-activity-incentive-cyber-binds>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isRewardsTierEnabled === false">
      <article class="rewards-headline-text">
        <p class="p p__large-text">
          In addition to the special rewards below, you will receive
          <span class="pill-tag pill-tag__warning rewards-coin-tag" style="line-height: 1.6"
            >{{ coinsPerQuote }} <span class="sr-only"> points</span
            ><img class="rewards-page-coin" src="/assets/img/coin.svg" alt="coin"
          /></span>
          each time you quote a new insured account.
        </p>
      </article>
    </ng-container>

    <article class="rewards-page-list">
      <app-rewards-checklist class="reward-category"></app-rewards-checklist>
    </article>
  </section>

  <app-modal [open]="showUserOptInModal" (handleModalClose)="closeUserOptInModal()">
    <div class="invite-user-modal">
      <h2>Enroll in Attune Rewards</h2>
      <p>
        Before you begin earning, check the details below. This is the email address we’ll send
        rewards emails to.
        <strong>Please note, it does not need to match your Attune login credentials.</strong>
      </p>
      <form
        *ngIf="optinRewardsForm"
        [formGroup]="optinRewardsForm"
        (ngSubmit)="submitOptIn($event)"
        class="rewards-optin-form"
        novalidate
      >
        <app-form-field-text
          inputId="name"
          [submitted]="optinRewardsSubmitted"
          [form]="optinRewardsForm"
          labelText="Full name"
          nameOfFormControl="name"
          maxLength="60"
        >
        </app-form-field-text>
        <div
          class="validation__inline"
          *ngIf="optinRewardsSubmitted && optinRewardsForm.controls.name.errors"
        >
          <div class="validation-message">Please enter a name</div>
        </div>
        <app-form-field-text
          inputId="rewardsEmail"
          [form]="optinRewardsForm"
          [submitted]="optinRewardsSubmitted"
          labelText="Email address"
          specifierText="(to receive rewards)"
          nameOfFormControl="rewardsEmail"
          maxLength="60"
          appRemoveWhitespaceMask
        >
        </app-form-field-text>
        <div
          class="validation__inline"
          *ngIf="optinRewardsSubmitted && optinRewardsForm.controls.rewardsEmail.errors"
        >
          <div class="validation-message">Please enter a valid email address</div>
        </div>
        <app-form-field-checkbox
          inputId="termsAndConditions"
          [form]="optinRewardsForm"
          [submitted]="optinRewardsSubmitted"
          labelText="I agree to the rewards"
          nameOfFormControl="termsAndConditions"
        >
          <a href="https://www.attuneinsurance.com/legal/attune-rewards-program" target="_blank">
            Terms and Conditions</a
          >
        </app-form-field-checkbox>
      </form>
      <div
        class="validation__inline"
        *ngIf="optinRewardsSubmitted && optinRewardsForm.controls.termsAndConditions.errors"
      >
        <div class="validation-message">You must agree to the terms and conditions</div>
      </div>
      <div class="validation" *ngIf="optInError" role="alert">
        <div class="validation-message">
          There was an error with your submission. Please try again later.
        </div>
      </div>
      <button
        type="submit"
        class="button button__full-width button__no-margin opt-in-submit-button"
        (click)="submitOptIn($event)"
      >
        <ng-container *ngIf="!requestingOptIn"> Join now </ng-container>
        <div class="button-loader" *ngIf="requestingOptIn">
          <svg class="circle-spinner">
            <circle
              cx="11"
              cy="11"
              r="9"
              stroke-width="2"
              stroke-dasharray="0,1000"
              d="M5 40 l215 0"
              fill="transparent"
            />
          </svg>
        </div>
        <ng-container *ngIf="requestingOptIn"> Please wait... </ng-container>
      </button>
    </div>
  </app-modal>

  <app-modal [open]="showRewardOptions" (handleModalClose)="closeRewardsOptionsModal()">
    <div class="invite-user-modal">
      <h2>Redeem insurecoin</h2>
      <p *ngIf="redemptionProcessingState !== 'success'">
        A gift card link from Rewards Genius will be sent to
        <strong
          >{{ rewardsEmail }} (<a
            class="js-edit-email-link"
            (click)="displayEditEmailModal()"
            (keyup.enter)="displayEditEmailModal()"
            >edit</a
          >)</strong
        >
        a few minutes after redeeming.
      </p>
      <form
        (ngSubmit)="redeemRewards()"
        [formGroup]="redeemRewardsForm"
        class="redeem-rewards-form"
      >
        <ng-container *ngIf="redemptionProcessingState !== 'success'">
          <app-form-field-radio-image
            class="rewards-choose-gift-card"
            [form]="redeemRewardsForm"
            [submitted]="redeemRewardsSubmitted"
            inputId="chooseGiftCard"
            questionText="How much would you like on your gift card?"
            nameOfFormControl="chooseGiftCard"
            [options]="giftCardOptions"
            showErrorText="true"
            [disabledOptions]="disabledOptions"
          >
          </app-form-field-radio-image>
          <app-dialog-box
            *ngIf="showSomeRewardsUnavailableMessage"
            type="warning"
            class="js-redemption-warning"
          >
            <p>
              You can redeem $600 max each year, which means you can still redeem
              {{ (6000 - totalRedeemedThisYear) / 10 | currency: 'USD':'symbol':'1.0' }}
              this year.
            </p>
            <p></p
          ></app-dialog-box>

          <div class="rewards-total-line" *ngIf="giftCardDescription">
            <strong>{{ giftCardDescription }}</strong>
            <strong>{{ numberOfPoints }}<img src="/assets/img/coin.svg" alt="coin" /></strong>
          </div>
        </ng-container>
        <app-dialog-box *ngIf="redemptionProcessingState === 'success'" type="success">
          <p><strong>Successfully redeemed</strong></p>
          <p>
            Congrats! You've successfully redeemed {{ numberOfPoints }} insurecoin. Please look out
            for an email from Rewards Genius in the next few minutes.
          </p>
        </app-dialog-box>
        <app-dialog-box *ngIf="redemptionProcessingState === 'error'" type="danger">
          <p><strong>Oops...something went wrong</strong></p>
          <p>
            We ran into an error trying to redeem your insurecoin. Please try again or email
            rewards&#64;attuneinsurance.com if the problem persists.
          </p>
        </app-dialog-box>
        <div class="nav-button-group">
          <button
            type="button"
            (click)="closeRewardsOptionsModal()"
            class="button button__secondary"
          >
            Close
          </button>
          <button
            type="submit"
            class="button button__primary"
            [disabled]="redemptionProcessingState === 'processing'"
            *ngIf="redemptionProcessingState !== 'success'"
          >
            <span *ngIf="redemptionProcessingState === 'processing'"> Redeeming... </span>
            <span *ngIf="redemptionProcessingState === 'error'"> Try Again </span>
            <span *ngIf="redemptionProcessingState === 'notProcessing'"> Redeem </span>
          </button>
        </div>
      </form>
    </div>
  </app-modal>

  <app-modal [open]="showEditEmailModal" (handleModalClose)="closeEditEmailModal()">
    <div class="invite-user-modal">
      <h2>Edit rewards recipient</h2>
      <form
        (ngSubmit)="editRewardsEmail()"
        [formGroup]="editRewardsEmailForm"
        class="edit-rewards-email-form"
      >
        <app-form-field-text
          inputId="fullName"
          [submitted]="editEmailFormSubmitted"
          [form]="editRewardsEmailForm"
          labelText="Full name"
          nameOfFormControl="fullName"
        >
        </app-form-field-text>
        <app-form-field-text
          inputId="emailAddress"
          [submitted]="editEmailFormSubmitted"
          [form]="editRewardsEmailForm"
          labelText="Email address"
          nameOfFormControl="emailAddress"
          appRemoveWhitespaceMask
        >
        </app-form-field-text>
        <app-dialog-box *ngIf="editEmailProcessingState === 'error'" type="danger">
          <p><strong>Oops...something went wrong</strong></p>
          <p>
            We ran into an error trying to update the recipient information. Please try again or
            email rewards&#64;attuneinsurance.com if the problem persists.
          </p>
        </app-dialog-box>
        <div class="nav-button-group">
          <button type="button" (click)="closeEditEmailModal()" class="button button__secondary">
            Back
          </button>
          <button
            type="submit"
            class="button button__primary js-edit-email-submit-button"
            [disabled]="editEmailProcessingState === 'processing'"
          >
            <span *ngIf="editEmailProcessingState === 'processing'"> Saving... </span>
            <span *ngIf="editEmailProcessingState === 'error'"> Try Again </span>
            <span *ngIf="editEmailProcessingState === 'notProcessing'"> Save Changes </span>
          </button>
        </div>
      </form>
    </div>
  </app-modal>

  <app-modal [open]="showRewardsInfoModal" (handleModalClose)="showRewardsInfoModal = false">
    <div class="invite-user-modal">
      <h2>Reward Tiers</h2>
      <p>The more you engage, the greater your insurecoins per bind.</p>

      <table>
        <thead class="thead__rewards">
          <tr>
            <th></th>
            <th class="td__center td__bold">Bronze</th>
            <th class="td__center td__bold">Silver</th>
            <th class="td__center td__bold">Gold</th>
            <th class="td__center td__bold">Platinum</th>
          </tr>
        </thead>
        <tbody class="tbody__rewards">
          <tr>
            <td>Premium bound</td>
            <td *ngFor="let tier of tiers" class="td__center">
              ${{ tiersConfig[tier].premiumTarget | currency: 'USD':'':'1.0-0' }}
            </td>
          </tr>
          <tr>
            <td>Coins / bind</td>
            <td *ngFor="let tier of tiers" class="td__center">
              {{ tiersConfig[tier].coins }}
              <img
                class="balance-coin__micro"
                src="/assets/img/rewards_tier_coin.svg"
                alt="attune coin"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </app-modal>

  <article class="rewards-sidebar">
    <!-- `enabled === boolean` syntax is used to prevent showing before we receive flag -->
    <app-rewards-activity *ngIf="isRewardsActivityEnabled === true"></app-rewards-activity>
    <ng-container *ngIf="isRewardsActivityEnabled === false">
      <div class="rewards-shop-container">
        <div class="rewards-shop">
          <div class="balance">
            <h3 class="rewards-balance-text">Your balance</h3>
            <h2 class="rewards-balance-total">
              {{ formattedBalance }}<span class="sr-only"> points</span>
              <img class="big-coin" src="/assets/img/big_coin.png" alt="coin" />
            </h2>
          </div>
          <p class="rewards-shop-info">
            Use your points to redeem gift cards from great brands like Amazon, Starbucks, Uber,
            Walmart, and more!
          </p>
        </div>
        <div class="rewards-shop-icon">
          <img src="/assets/img/gift-cards-icon.png" alt="gift cards" />
        </div>
      </div>
      <button
        class="rewards-button button button__primary button__full-width button__no-margin"
        aria-disabled="false"
        (click)="viewRewards()"
      >
        View rewards
      </button>
      <app-dialog-box
        *ngIf="showNotEnoughRewardsMessage"
        type="danger"
        class="js-redemption-error dialog-text__full-width mt-4"
      >
        You need at least 50 insurecoins in order to redeem rewards. Quote more to earn rewards!
      </app-dialog-box>
      <app-dialog-box
        *ngIf="showRewardsCapMessage"
        type="success"
        class="js-redemption-error dialog-text__full-width mt-4"
      >
        You have maxed out the rewards you can redeem this year ($600 maximum). Congrats!
      </app-dialog-box>
    </ng-container>
  </article>
</ng-container>
