import {
  BundleOption,
  CoalitionCyberQuestion,
  CoalitionDocumentType,
  QuoteStatusWithCarrierDocuments,
  CyberProduct,
  CoverageGroupPatchValues,
  FirstPartyCoverageNestedQuestion,
  ThirdPartyCoverageNestedQuestion,
  AttestationForm,
  PathCyberProduct,
  CoalitionCyberMarket,
  FormValue,
  CyberMarketingGroup,
  CyberMarketingConfig,
} from './cyber-typings.model';
import { PolicyRenewalStatus } from '../../digital-carrier/models/types';

export const CYBER_MARKETING_GROUP_TO_CONFIG: Record<CyberMarketingGroup, CyberMarketingConfig> = {
  'accountants-bookkeepers': {
    body: 'Uncovered cyber risk can cost your client. Check out a case study that recounts how cyber insurance protected a law office from a malicious email that could have cost them $1.3M.',
    case: 'https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/law+firm+case+study.pdf',
    header: 'Accountants and bookkeepers are at risk',
  },
  'law-offices': {
    body: 'Uncovered cyber risk can cost your client. Check out a case study that recounts how cyber insurance protected a law office from a malicious email that could have cost them $1.3M.',
    case: 'https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/law+firm+case+study.pdf',
    header: 'Law offices are at risk',
  },
  'medical-offices': {
    body: 'Healthcare companies are vulnerable to cyber attacks. Check out a case study that recounts how cyber insurance helped a healthcare company reduce a ransom cost by 75%. ',
    case: 'https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/healthcare+case+study.pdf',
    header: 'Medical offices are at risk',
  },
  'real-state-agents': {
    body: 'Uncovered cyber risk can cost your client. Check out a case study that recounts how cyber insurance protected a law office from a malicious email that could have cost them $1.3M.',
    case: 'https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/law+firm+case+study.pdf',
    header: 'Real estate agents are at risk',
  },
  'real-state-prop-mgt': {
    body: 'No business is too small for a cyber attack. Check out a case study that recounts how cyber coverage protected a company with less than 25 employees against a phishing attack that could have cost them $1.3M.',
    case: 'https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/generic+small+biz+case+study.pdf',
    header: 'Real estate property managers are at risk',
  },
  'retail-shops-and-professional-services': {
    body: 'No business is too small for a cyber attack. Check out a case study that recounts how cyber coverage protected a company with less than 25 employees against a phishing attack that could have cost them $1.3M.',
    case: 'https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/generic+small+biz+case+study.pdf',
    header: 'Retail shops are at risk',
  },
  'wholesale-distributors': {
    body: 'No business is too small for a cyber attack. Check out a case study that recounts how cyber coverage protected a small specialty retailer against a malicious email.',
    case: 'https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/specialty+retail+case+study.pdf',
    header: 'Wholesalers are at risk',
  },
  contractors: {
    body: 'No business is too small for a cyber attack. Check out a case study that recounts how cyber coverage protected a company with less than 25 employees against a phishing attack that could have cost them $1.3M.',
    case: 'https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/generic+small+biz+case+study.pdf',
    header: 'Contractors are at risk',
  },
  insurance: {
    body: 'Uncovered cyber risk can cost your client. Check out a case study that recounts how cyber insurance protected a law office from a malicious email that could have cost them $1.3M.',
    case: 'https://myattune-web-assets.s3.us-east-2.amazonaws.com/public/documents/law+firm+case+study.pdf',
    header: 'Insurance agents are at risk',
  },
};

// This is from the domain validator that Coalition Cyber uses: https://validators.readthedocs.io/en/latest/_modules/validators/domain.html
export const CYBER_DOMAIN_REGEX =
  /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][-_.a-zA-Z0-9]{0,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,13}|[a-zA-Z0-9-]{2,30}.[a-zA-Z]{2,3})$/;

export const COMPANY_EMPLOYEE_COUNT_OPTIONS = {
  '1-25': '1-25',
  '26-50': '26-50',
  '51-250': '51-250',
  '251-1000': '251-1000',
  '1001+': '1001+',
} as const;

export const PRIOR_CLAIMS_AMOUNT_OPTIONS = {
  '< $50,000': '<50,000',
  '$50,000 - 100,000': '50,000 - 100,000',
  '$100,000 - 250,000': '100,000 - 250,000',
  '$250,000 - 500,000': '250,000 - 500,000',
  '> $500,000': '>500,000',
} as const;

export const COALITION_CYBER_INVOICE_SURPLUS_GUIDEWIRE_NAME = 'Cyber Liability (Non-Admitted)';
export const COALITION_CYBER_INVOICE_SURPLUS_DISPLAY_NAME = 'Cyber Liability (Surplus)';

export const USER_INPUT_DEBOUNCE_TIME_IN_MS = 1000;

export const SURPLUS_NOT_YET_AVAILABLE =
  'We are currently unable to provide access to the surplus market. The Attune team is working diligently to enable quoting in this market as soon as possible.';

export const COALITION_BROKER_OF_RECORD_REFERRAL_REASON = 'BROKER_OF_RECORD';

export const BROKER_OF_RECORD_CHANGE_REQUEST_FORM_URL = '/support/form/request-bor';

export const COALITION_BROKER_OF_RECORD_REFERRAL_ERROR_MESSAGE =
  'We were unable to process this quote because another agency has created a quote for this account. To request a Broker of Record change and unlock quoting for this account, please submit a request to our Customer Care team. Our team will respond to your request within 48 business hours.';

export const COALITION_RENEWAL_CALL_TO_ACTION_MESSAGE = (pathToQuoteFlow: string) =>
  `To renew coverage for this client, please review and submit the pre-filled renewal application
  linked below. This application reflects last year’s submission - please make any updates necessary.
  Note that a signed signature bundle will be required even after application submission.
  <div class="dialog-text-links">
    <a href="${pathToQuoteFlow}" class="dialog-text-link">
      Review and submit renewal application 
    </a>
  </div>
  `;

export const COALITION_BROKER_OF_RECORD_REFERRAL_DIALOG_MESSAGE = `
  ${COALITION_BROKER_OF_RECORD_REFERRAL_ERROR_MESSAGE}
  <div class="dialog-text-links">
    <a href="${BROKER_OF_RECORD_CHANGE_REQUEST_FORM_URL}" class="dialog-text-link">
      Request change
    </a>
  </div>
`;

export const COALITION_BLOCKLISTED_DOMAIN_RESPONSE_ERROR =
  'Invalid field value for domain_names: blocklisted-domain';

export const COALITION_BLOCKLISTED_DOMAIN_ERROR_MESSAGE =
  'The domain you provided cannot be included in the quote because it is associated with a typically shared hosting provider (i.e. Wix, Godaddy, Squarespace). You may continue this quote without a domain or include a different valid website or email domain (e.g. localrestaurant.com, john@localrestaurant.com).';

export const COALITION_DUPLICATE_BROKER_ERROR_MESSAGE_MODAL = `We were unable to process this quote because your email is already registered in Coalition's system, either with a different wholesale agency or as a Coalition policy holder. To access Coalition Cyber through Attune, you will be required to update your registration.
<br>
<br>
If you choose to update your registration, you will receive a confirmation email within 24 hours from the our Customer Care Team. `;

export const SURPLUS_NOT_AVAILABLE_IN_STATE =
  'We are currently unable to provide access to the surplus market in this state. The Attune team is working diligently to enable quoting in this market as soon as possible.';
export const ADMITTED_EXCEEDS_MAXIMUM_REVENUE =
  'Risks with $250M in revenue or greater are not eligible for the Coalition Cyber admitted product.';

export const CYBER_ADMITTED_BIND_FAQS: Faq[] = [
  {
    question: 'How do you bill?',
    answer:
      'Attune is a 100% paperless direct bill general agency, meaning that we deliver invoices to your insured via email using the contact information you provide on the this page. Invoices are sent via email immediately after bind. Brokers can access these invoices (and their real-time payment status) via the “Invoices” tab.',
  },
  {
    question: 'What payments plans do you offer?',
    answer: 'We offer only a single payment option for Coalition cyber policies.',
  },
  {
    question: 'What payment methods do you accept?',
    answer:
      'We accept all major credit cards and ACH payments. We strongly discourage your insured from making payment by check, as it takes 2-3 weeks for processing.',
  },
  {
    question: 'How do I make changes to the policy after it is bound?',
    answer:
      'You can contact our Customer Care Team via the <a href="https://app.attuneinsurance.com/help-center" target="_blank">Attune Help Center</a> to complete any changes or endorsements.',
  },
  {
    question: 'How do I finalize the policy once it is bound?',
    answer:
      'Coalition requires that all insureds e-sign the policy bundle after the policy is bound. The link to complete this e-signature will be sent directly to the insured after the policy is bound via the address you provided on this page.',
  },
];

export const CYBER_MAX_ALLOWED_BIND_DATE_OFFSET_FUTURE = 29;

export const ADMITTED_KNOCK_OUT_STATES = ['AK', 'WY'];

// $250,000,000 or above is ineligible for Admitted products
export const ADMITTED_MAX_REVENUE = 250 * 1000 * 1000;

export const COALITION_CYBER_ISO_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss[Z]';

export const RETENTION_LIMIT_DIVISOR = 5;
// aggregate limit
export const LIMIT_OPTIONS = [
  25_000, 50_000, 100_000, 250_000, 500_000, 750_000, 1_000_000, 2_000_000, 2_500_000, 3_000_000,
  4_000_000, 5_000_000,
] as const;

export type CyberLimitOption = typeof LIMIT_OPTIONS[number];

export const RETENTION_OPTIONS = [
  500, 750, 1_000, 1_500, 2_500, 5_000, 6_000, 7_500, 10_000, 12_500, 15_000, 20_000, 25_000,
  50_000, 75_000, 100_000, 150_000, 175_000, 200_000, 250_000, 300_000, 500_000, 750_000, 1_000_000,
] as const;

export type CyberRetentionOption = typeof RETENTION_OPTIONS[number];

const ESSENTIAL_AGGREGATE_LIMIT: CyberLimitOption = 100_000;
const MOST_POPULAR_AGGREGATE_LIMIT: CyberLimitOption = 250_000;

// The underlying value for a slider control is an index rather than a dollar amount.
// We map this index to a dollar amount when displaying the slider control, and when
// sending the form data to SQ.
export const INITIAL_AGGREGATE_LIMIT_INDEX = LIMIT_OPTIONS.indexOf(ESSENTIAL_AGGREGATE_LIMIT);
export const ESSENTIAL_AGGREGATE_LIMIT_INDEX = LIMIT_OPTIONS.indexOf(ESSENTIAL_AGGREGATE_LIMIT);
export const MOST_POPULAR_AGGREGATE_LIMIT_INDEX = LIMIT_OPTIONS.indexOf(
  MOST_POPULAR_AGGREGATE_LIMIT
);

const INITIAL_DEFAULT_RETENTION: CyberRetentionOption = 2_500;

export const INITIAL_DEFAULT_RETENTION_INDEX = RETENTION_OPTIONS.indexOf(INITIAL_DEFAULT_RETENTION);
export const ESSENTIAL_DEFAULT_RETENTION_INDEX =
  RETENTION_OPTIONS.indexOf(INITIAL_DEFAULT_RETENTION);
export const MOST_POPULAR_DEFAULT_RETENTION_INDEX =
  RETENTION_OPTIONS.indexOf(INITIAL_DEFAULT_RETENTION);

export const INITIAL_RENTENTION_RANGE_MIN_INDEX = RETENTION_OPTIONS.indexOf(1_000);
export const INITIAL_RETENTION_RANGE__MAX_INDEX = RETENTION_OPTIONS.indexOf(20_000);

export const GOVT_RISK_IDS = [103, 298, 299, 921, 934];

// Minimum SIR as of 07-25-2024
// Values at: https://coalitioninc.stoplight.io/docs/distribution-api/minimum-retention
/* MINIMUM SIR
            revenue
limits   |≥0  |  1m|  5m| 10m| 25m| 50m|250m|500m
≥0       |  1k|2.5k|  5k| 10k| 10k| 25k| 25k| 50k
2m       |  5k|  5k|  5k| 10k| 10k| 25k| 25k| 50k
5m       | 10k| 15k| 25k| 25k| 25k| 25k| 25k| 50k
 */
export const MIMIMUM_SIR_LIMITS = [0, 2_000_000, 5_000_000] as const;
export const MIMIMUM_SIR_REVENUES = [
  0, 1_000_000, 5_000_000, 10_000_000, 25_000_000, 50_000_000, 250_000_000, 500_000_000,
] as const;
export const MINIMUM_SIR_GRAPH = [
  [1_000, 5_000, 10_000],
  [2_500, 5_000, 15_000],
  [5_000, 5_000, 25_000],
  [10_000, 10_000, 25_000],
  [10_000, 10_000, 25_000],
  [25_000, 25_000, 25_000],
  [25_000, 25_000, 25_000],
  [50_000, 50_000, 50_000],
] as const;

/* MINIMUM SIR for Govt risk
            revenue
limits   |≥0  |10m  |25m
≥0       |5k  |10k  |50k
2m       |10k |15k  |50k
5m       |15k |25k  |50k
 */
export const MIMIMUM_SIR_LIMITS_GOVT = [0, 2_000_000, 5_000_000] as const;
export const MIMIMUM_SIR_REVENUES_GOVT = [0, 10_000_000, 25_000_000] as const;
export const MINIMUM_SIR_GRAPH_GOVT = [
  [5_000, 10_000, 15_000],
  [10_000, 15_000, 20_000],
  [50_000, 50_000, 50_000],
] as const;

export const ESSENTIAL_COVERAGES = {
  [CoalitionCyberQuestion.THIRD_PARTY_COVERAGES]: {
    [ThirdPartyCoverageNestedQuestion.BIPD_THIRD_PARTY]: false,
    [ThirdPartyCoverageNestedQuestion.MEDIA_LIABILITY]: false,
    [ThirdPartyCoverageNestedQuestion.NETWORK]: true,
    [ThirdPartyCoverageNestedQuestion.PCI]: false,
    [ThirdPartyCoverageNestedQuestion.POLLUTION]: false,
    [ThirdPartyCoverageNestedQuestion.REGULATORY_DEFENSE]: true,
  },
  [CoalitionCyberQuestion.FIRST_PARTY_COVERAGES]: {
    [FirstPartyCoverageNestedQuestion.ASSET_RESTORATION]: true,
    [FirstPartyCoverageNestedQuestion.BIPD_FIRST_PARTY]: false,
    [FirstPartyCoverageNestedQuestion.BREACH_RESPONSE]: true,
    [FirstPartyCoverageNestedQuestion.BREACH_RESPONSE_COSTS]: true,
    [FirstPartyCoverageNestedQuestion.BREACH_RESPONSE_SERVICES]: true,
    [FirstPartyCoverageNestedQuestion.BUSINESS_INTERRUPTION]: true,
    [FirstPartyCoverageNestedQuestion.COMPUTER_REPLACEMENT]: false,
    [FirstPartyCoverageNestedQuestion.CRISIS_MANAGEMENT]: true,
    [FirstPartyCoverageNestedQuestion.EXTORTION]: true,
    [FirstPartyCoverageNestedQuestion.FUNDS_TRANSFER]: false,
    [FirstPartyCoverageNestedQuestion.REPUTATION]: false,
    [FirstPartyCoverageNestedQuestion.REPUTATIONAL_HARM]: true,
    [FirstPartyCoverageNestedQuestion.SERVICE_FRAUD]: false,
    [FirstPartyCoverageNestedQuestion.TECH_EO]: false,
  },
};

export const MOST_POPULAR_COVERAGES = {
  [CoalitionCyberQuestion.THIRD_PARTY_COVERAGES]: {
    [ThirdPartyCoverageNestedQuestion.BIPD_THIRD_PARTY]: false,
    [ThirdPartyCoverageNestedQuestion.MEDIA_LIABILITY]: true,
    [ThirdPartyCoverageNestedQuestion.NETWORK]: true,
    [ThirdPartyCoverageNestedQuestion.PCI]: true,
    [ThirdPartyCoverageNestedQuestion.POLLUTION]: false,
    [ThirdPartyCoverageNestedQuestion.REGULATORY_DEFENSE]: true,
  },
  [CoalitionCyberQuestion.FIRST_PARTY_COVERAGES]: {
    [FirstPartyCoverageNestedQuestion.ASSET_RESTORATION]: true,
    [FirstPartyCoverageNestedQuestion.BIPD_FIRST_PARTY]: false,
    [FirstPartyCoverageNestedQuestion.BREACH_RESPONSE]: true,
    [FirstPartyCoverageNestedQuestion.BREACH_RESPONSE_COSTS]: true,
    [FirstPartyCoverageNestedQuestion.BREACH_RESPONSE_SERVICES]: true,
    [FirstPartyCoverageNestedQuestion.BUSINESS_INTERRUPTION]: true,
    [FirstPartyCoverageNestedQuestion.COMPUTER_REPLACEMENT]: true,
    [FirstPartyCoverageNestedQuestion.CRISIS_MANAGEMENT]: true,
    [FirstPartyCoverageNestedQuestion.EXTORTION]: true,
    [FirstPartyCoverageNestedQuestion.FUNDS_TRANSFER]: true,
    [FirstPartyCoverageNestedQuestion.REPUTATION]: false,
    [FirstPartyCoverageNestedQuestion.REPUTATIONAL_HARM]: true,
    [FirstPartyCoverageNestedQuestion.SERVICE_FRAUD]: true,
    [FirstPartyCoverageNestedQuestion.TECH_EO]: false,
  },
};

export const COVERAGE_VALUES_BY_BUNDLE_OPTION: Record<BundleOption, CoverageGroupPatchValues> = {
  [BundleOption.ESSENTIAL]: {
    [CoalitionCyberQuestion.AGGREGATE_LIMIT]: ESSENTIAL_AGGREGATE_LIMIT_INDEX,
    [CoalitionCyberQuestion.DEFAULT_RETENTION]: INITIAL_DEFAULT_RETENTION_INDEX,
    ...ESSENTIAL_COVERAGES,
  },
  [BundleOption.MOST_POPULAR]: {
    [CoalitionCyberQuestion.AGGREGATE_LIMIT]: LIMIT_OPTIONS.indexOf(MOST_POPULAR_AGGREGATE_LIMIT),
    [CoalitionCyberQuestion.DEFAULT_RETENTION]: INITIAL_DEFAULT_RETENTION_INDEX,
    ...MOST_POPULAR_COVERAGES,
  },
  [BundleOption.CUSTOM]: {
    [CoalitionCyberQuestion.THIRD_PARTY_COVERAGES]: {},
    [CoalitionCyberQuestion.FIRST_PARTY_COVERAGES]: {},
  },
};

export const CYBER_DEFAULT_UNDERWRITING_QUESTIONS_FOR_REVENUES_UNDER_1M: Partial<
  Record<CoalitionCyberQuestion, FormValue>
> = {
  [CoalitionCyberQuestion.PRIOR_CLAIMS]: 'No',
  [CoalitionCyberQuestion.AWARE_OF_NEW_CLAIMS]: 'No',
  [CoalitionCyberQuestion.ENCRYPTS_DATA]: 'Yes',
  [CoalitionCyberQuestion.PII_PHI]: 'Yes',
  [CoalitionCyberQuestion.PII_PHI_COUNT]: '<100,000',
  [CoalitionCyberQuestion.CC_CUSTOMER_COUNT]: '<100,000',
  [CoalitionCyberQuestion.CONTENT_COMPLAINTS]: 'No',
  [CoalitionCyberQuestion.REVIEWS_CONTENT]: 'Yes',
  [CoalitionCyberQuestion.BACKUP_AND_RESTORE]: 'Yes',
  [CoalitionCyberQuestion.DUAL_CONTROL]: 'Yes',
};

const COALITION_QUOTE_DETAILS_PANE_DOCUMENTS: CoalitionDocumentType[] = ['CYBER_RISK_ASSESSMENT'];

const COALITION_UNSIGNED_POLICY_DETAILS_PANE_DOCUMENTS: CoalitionDocumentType[] = [
  'BINDER',
  'SIGNATURE_BUNDLE',
  'SPECIMEN_POLICY',
  'CYBER_RISK_ASSESSMENT',
];

const COALITION_SIGNED_POLICY_DETAILS_PANE_DOCUMENTS: CoalitionDocumentType[] = [
  'BINDER',
  'SIGNED_SIGNATURE_BUNDLE',
  'POLICY_BUNDLE',
  'CYBER_RISK_ASSESSMENT',
];

export const visibleCarrierDocumentsByQuoteStatus: Record<
  QuoteStatusWithCarrierDocuments,
  CoalitionDocumentType[]
> = {
  quoted: COALITION_QUOTE_DETAILS_PANE_DOCUMENTS,
  bound_with_subjectivity: COALITION_UNSIGNED_POLICY_DETAILS_PANE_DOCUMENTS,
  bound: COALITION_SIGNED_POLICY_DETAILS_PANE_DOCUMENTS,
};

const MANUAL_RENEWAL_QUOTE_DETAILS_PANE_DOCUMENTS: CoalitionDocumentType[] = [
  'RENEWAL_SIGNATURE_BUNDLE',
];

export const visibleCarrierDocumentsByRenewalStatus: Partial<
  Record<PolicyRenewalStatus, CoalitionDocumentType[]>
> = {
  manual: MANUAL_RENEWAL_QUOTE_DETAILS_PANE_DOCUMENTS,
};

export const pathToProduct: Record<PathCyberProduct, CyberProduct> = {
  ['cyber-admitted']: 'cyber_admitted',
  ['cyber-surplus']: 'cyber_surplus',
};

export const productToPath: Record<CyberProduct, PathCyberProduct> = {
  cyber_admitted: 'cyber-admitted',
  cyber_surplus: 'cyber-surplus',
};

export const productToMarketDisplay: Record<CyberProduct, string> = {
  cyber_admitted: 'Admitted',
  cyber_surplus: 'Surplus',
};

export const pathToMarket: Record<PathCyberProduct, CoalitionCyberMarket> = {
  ['cyber-admitted']: 'ADMITTED',
  ['cyber-surplus']: 'SURPLUS',
};

export const DECLINATION_REASONS_DISPLAY = {
  A: 'Capacity Reached',
  B: 'Underwriting Reason',
  C: 'Other',
};

export const DECLINATION_REASONS = {
  A: 'A. Capacity Reached',
  B: 'B. Underwriting Reason',
  C: 'C. Other',
};

// Some of the repeated/ common simple text questions for attestation forms.
// Some questions have slight variations in wording and as such do not use these constants.
export const AUTHORIZED_CARRIER = {
  id: 'authorizedCarrier',
  labelText: 'Authorized Carrier',
};

export const CONTACT_PHONE_EMAIL = {
  id: 'contact',
  labelText: 'Telephone Number/Email',
};

const getResultCode = (
  codeTexts: string[],
  labelText = 'Result Codes'
): { display: Record<string, string>; value: Record<string, string>; labelText: string } => {
  const result: {
    display: Record<string, string>;
    value: Record<string, string>;
    labelText: string;
  } = {
    display: {},
    value: {},
    labelText,
  };
  codeTexts.forEach((text, index) => {
    const code = String.fromCharCode(65 + index);
    result.display[code] = text;
    result.value[code] = `${code}: ${text}`;
  });
  return result;
};

export const ATTESTATION_FORM_BY_STATE: Record<string, AttestationForm> = {
  IL: {
    attestationText:
      'I attest that I have sought to obtain cyber liability coverage for the insured herein from the following authorized insurers currently writing this type of coverage.',
    requiredCarriers: 3,
    simpleTextQuestions: [AUTHORIZED_CARRIER, CONTACT_PHONE_EMAIL],
    carrierQuestions: {
      date: true,
      reason: true,
    },
  },
  MA: {
    attestationText:
      "I depose and say that I was engaged directly by the Assured named herein or informed by the Assured's Insurance licensed Agent/Broker that after diligent efforts, he/she is unable to procure in companies admitted to do business in this Commonwealth the amount and/or type of insurance necessary to protect the insurable interests described above. This Affidavit is made to comply with the requirements of Section 168 of Chapter 175 of the General Laws, and to authorize me as a licensed special insurance broker under said section to procure insurance for said insurable interests beyond that which companies admitted to do business in the Commonwealth are willing to write thereon.",
    requiredCarriers: 0,
    simpleTextQuestions: [],
    carrierQuestions: {},
  },
  FL: {
    attestationText:
      'I attest that I have sought to obtain cyber liability coverage for the insured herein from the following authorized insurers currently writing this type of coverage.',
    requiredCarriers: 3,
    simpleTextQuestions: [AUTHORIZED_CARRIER, CONTACT_PHONE_EMAIL],
    carrierQuestions: {
      date: true,
      reason: true,
    },
  },
  NJ: {
    attestationText:
      'I was engaged by the insured named herein to procure insurance of the kind described herein and in the amount shown. I have made a diligent effort first to place this coverage with authorized insurers, each of which is authorized in New Jersey to write insurance of the kind requested and is an insurer that I had a good faith reason to believe might consider writing the type of coverage described herein. The following insurers are among those that I contacted relative to this risk or to substantially similar risks within the past 30 days.',
    requiredCarriers: 3,
    simpleTextQuestions: [AUTHORIZED_CARRIER, CONTACT_PHONE_EMAIL],
    carrierQuestions: {
      date: true,
      resultCode: getResultCode([
        'Having made a diligent effort, I was unable to obtain an offer/quote from this authorized insurer in the admitted market, which declined to accept all or any part of the risk.',
        'Having made a diligent effort, the only offer(s)/quote(s) obtained reflected such a substantial increase in premium over similar coverage placed within the preceding 12 months that comparable coverage is, as a practical matter, unavailable from this authorized insurer in the admitted market.',
        'Having made a diligent effort, the only offer(s)/quote(s) obtained reflect(s) such a substantial reduction in coverage from coverage placed within the preceding 12 months for substantially similar premium that comparable coverage is, as a practical matter, unavailable from this authorized insurer in the admitted market.',
      ]),
    },
  },
  SC: {
    attestationText:
      'I attest that I have sought to obtain cyber liability coverage for the insured herein from the following authorized insurers currently writing this type of coverage.',
    requiredCarriers: 3,
    simpleTextQuestions: [AUTHORIZED_CARRIER, CONTACT_PHONE_EMAIL],
    carrierQuestions: {
      date: true,
      reason: true,
    },
  },
  AL: {
    attestationText:
      'I attest that I have sought to obtain cyber liability coverage for the insured herein from the following authorized insurers currently writing this type of coverage.',
    requiredCarriers: 3,
    simpleTextQuestions: [AUTHORIZED_CARRIER],
    carrierQuestions: {
      reason: true,
    },
  },
  NC: {
    attestationText:
      'I hereby acknowledge that I have been unable, after a diligent search, to obtain from insurance companies authorized to do business in North Carolina the full amount or kind of insurance necessary to protect the risks. I, therefore, request that I be permitted to place the amount of insurance required to protect such risk(s) in insurance companies that are not licensed to do business in the State, pursuant to Article 21 of Chapter 58 of the General Statutes of North Carolina.',
    requiredCarriers: 0,
    simpleTextQuestions: [],
    carrierQuestions: {},
  },
  MS: {
    attestationText:
      'As required by Miss. Code. Ann. 83-21-23, I certify that I engaged in a diligent effort to place the risk with an admitted company or companies.',
    requiredCarriers: 0,
    simpleTextQuestions: [],
    carrierQuestions: {},
  },
  OK: {
    attestationText:
      'I attest that I have sought to obtain cyber liability coverage for the insured herein from the following authorized insurers currently writing this type of coverage.',
    requiredCarriers: 3,
    simpleTextQuestions: [AUTHORIZED_CARRIER, CONTACT_PHONE_EMAIL],
    carrierQuestions: {
      date: true,
      reason: true,
    },
  },
  CO: {
    attestationText:
      'I have determined that the full amount of insurance required was not procurable, after diligent effort had been made to do so, from among at least three (3) admitted insurers authorized to and actually transacting that line of business in this state. Or that the coverage was not procurable because there were less than three (3) admitted insurers authorized to and actually transacting that line of business in this state. Further, that placing the insurance with a non-admitted insurer was not for the purpose of securing a lower premium rate than which would be accepted by an admitted insurer unless the premium rate quoted by the admitted insurer must be ten (10) percent higher than that rate quoted by the non-admitted insurer. In addition, the policy benefits and provisions within the policies being compared shall be comparatively similar.',
    requiredCarriers: 3,
    simpleTextQuestions: [AUTHORIZED_CARRIER],
    carrierQuestions: {
      reason: true,
    },
  },
  MI: {
    attestationText:
      'I attest that I have sought to obtain cyber liability coverage for the insured herein from the following authorized insurers currently writing this type of coverage',
    requiredCarriers: 3,
    simpleTextQuestions: [AUTHORIZED_CARRIER, CONTACT_PHONE_EMAIL],
    carrierQuestions: {
      date: true,
      reason: true,
    },
  },
  MO: {
    attestationText:
      'I attest that I have sought to obtain cyber liability coverage for the insured herein from the following authorized insurers currently writing this type of coverage.',
    requiredCarriers: 3,
    simpleTextQuestions: [AUTHORIZED_CARRIER, CONTACT_PHONE_EMAIL],
    carrierQuestions: {
      date: true,
      reason: true,
    },
  },
  RI: {
    attestationText:
      'I swear under penalty of perjury as follows. I was engaged by the insured named herein, either directly or by a licensed Rhode Island producer, to obtain insurance against the risk(s) described below. Said insured or his(her) producer was unable to obtain the required insurance with insurers licensed to transact business in the State of Rhode Island. A diligent effort has been made on behalf of the insured to procure the insurance from insurers licensed to insure these risks in the State of Rhode Island. The following insurers, licensed to write the type of insurance which is the subject of this affidavit within the State of Rhode Island, have declined the coverage referenced above (please note that the name of the officer of the insurer or the producer that declined risk must be identified).',
    requiredCarriers: 3,
    simpleTextQuestions: [
      AUTHORIZED_CARRIER,
      {
        id: 'nameDecliner',
        labelText: 'Name of Officer or Producer that Declined Risk',
      },
    ],
    carrierQuestions: {},
  },
  TX: {
    attestationText:
      'I attest that I have sought to obtain cyber liability coverage for the insured herein from the following authorized insurers currently writing this type of coverage.',
    requiredCarriers: 1,
    simpleTextQuestions: [
      {
        id: 'surplusLinesCarrier',
        labelText: 'Surplus Lines Carrier',
      },
      {
        id: 'quoteNumber',
        labelText: 'Quote number',
      },
    ],
    carrierQuestions: {
      declinationReason: true,
    },
  },
  NV: {
    attestationText:
      'In pursuant of 685A.215 of NRS, I attest that I have sought to obtain cyber liability coverage for the insured herein from the following authorized insurers currently writing this type of coverage.',
    requiredCarriers: 3,
    simpleTextQuestions: [
      {
        id: 'authorizedAdmittedInsurer',
        labelText: 'Admitted Insurer',
      },
      {
        id: 'underwriter',
        labelText: 'Underwriter',
      },
      {
        id: 'contact',
        labelText: 'Telephone Number',
      },
    ],
    carrierQuestions: {
      resultCode: getResultCode(
        [
          'Unacceptable class of business',
          'Age of building',
          'Declined to quote',
          'Doesn’t fit underwriting requirement',
          'No Market',
          'No Prior Insurance',
          'Excessive claims',
          'Other',
        ],
        'Reason for Declination'
      ),
    },
  },
  TN: {
    attestationText:
      'I attest that I have sought to obtain cyber liability coverage for the insured herein from the following authorized insurers currently writing this type of coverage.',
    requiredCarriers: 1,
    simpleTextQuestions: [
      {
        id: 'authorizedInsurer',
        labelText: 'Insurer',
      },
      {
        id: 'naic',
        labelText: 'NAIC#',
      },
    ],
    carrierQuestions: {},
  },
  NY: {
    attestationText:
      'I attest that I have sought to obtain cyber liability coverage for the insured herein with three authorized insurers where the following conditions were satisfied: \n' +
      '<ul class="bulleted-list">' +
      '<li>each carrier is authorized in New York to write coverages of the kind requested</li>' +
      '<li>each carrier was reasonably thought to consider writing the type of coverage or class of insurance involved</li>' +
      '<li>each carrier declined the risk herein</li>' +
      '</ul>',
    requiredCarriers: 0,
    simpleTextQuestions: [],
    carrierQuestions: {},
  },
};

export const CYBER_CALL_TO_ACTION_MESSAGES: { text: string; icon: string }[] = [
  {
    text: 'Get an online, bindable quote',
    icon: 'assets/img/check.svg',
  },
  {
    text: 'See the final price instead of an indication as above',
    icon: 'assets/img/check.svg',
  },
  {
    text: 'Access a Coalition Risk Assessment (CRA) if you added a domain',
    icon: 'assets/img/check.svg',
  },
];
export const DUPLICATE_BROKER_ERROR_MESSAGE = 'DUPLICATE BROKER ERROR';
export const INVALID_AGENCY_DATA_ERROR_MESSAGE = 'INVALID AGENCY DATA ERROR';

/**
 * This dictionary contains all NAICS codes that are supported by Coalition's Distribution
 * API that can be used in lieu of an industry ID when making a request. We map the 6-digit
 * NAICS code to its corresponding industry, since we need the industry ID in order to look
 * up any
 */
export const NAICS_CODES_TO_INDUSTRY_IDS: Record<string, number> = {
  '111110': 633,
  '111120': 633,
  '111130': 633,
  '111140': 633,
  '111150': 633,
  '111160': 633,
  '111191': 633,
  '111199': 633,
  '111211': 633,
  '111219': 633,
  '111310': 633,
  '111320': 633,
  '111331': 633,
  '111332': 633,
  '111333': 633,
  '111334': 633,
  '111335': 633,
  '111336': 633,
  '111339': 633,
  '111411': 633,
  '111419': 1039,
  '111421': 633,
  '111422': 633,
  '111910': 633,
  '111920': 633,
  '111930': 633,
  '111940': 633,
  '111991': 633,
  '111992': 633,
  '111998': 1039,
  '112111': 633,
  '112112': 633,
  '112120': 633,
  '112130': 633,
  '112210': 633,
  '112310': 633,
  '112320': 633,
  '112330': 633,
  '112340': 633,
  '112390': 633,
  '112410': 633,
  '112420': 633,
  '112511': 633,
  '112512': 633,
  '112519': 633,
  '112910': 633,
  '112920': 633,
  '112930': 633,
  '112990': 633,
  '113110': 58,
  '113210': 58,
  '113310': 58,
  '114111': 636,
  '114112': 636,
  '114119': 636,
  '114210': 636,
  '115111': 101,
  '115112': 101,
  '115113': 101,
  '115114': 101,
  '115115': 101,
  '115116': 101,
  '115210': 101,
  '115310': 101,
  '211120': 16,
  '211130': 16,
  '212111': 21,
  '212112': 21,
  '212113': 21,
  '212210': 43,
  '212221': 43,
  '212222': 43,
  '212230': 43,
  '212291': 43,
  '212299': 43,
  '212311': 36,
  '212312': 36,
  '212313': 36,
  '212319': 36,
  '212321': 36,
  '212322': 36,
  '212324': 36,
  '212325': 36,
  '212391': 43,
  '212392': 43,
  '212393': 43,
  '212399': 43,
  '213112': 10,
  '213113': 22,
  '213114': 43,
  '213115': 43,
  '221111': 11,
  '221112': 11,
  '221113': 11,
  '221114': 11,
  '221115': 11,
  '221116': 11,
  '221117': 11,
  '221118': 11,
  '221121': 11,
  '221122': 11,
  '221210': 11,
  '236115': 240,
  '236116': 240,
  '236117': 240,
  '236118': 123,
  '236220': 123,
  '237210': 116,
  '237310': 350,
  '237990': 350,
  '238110': 350,
  '238120': 350,
  '238130': 350,
  '238140': 350,
  '238150': 350,
  '238160': 350,
  '238170': 350,
  '238190': 350,
  '238210': 350,
  '238220': 350,
  '238290': 350,
  '238310': 350,
  '238320': 350,
  '238330': 350,
  '238340': 350,
  '238350': 350,
  '238390': 350,
  '238910': 350,
  '238990': 350,
  '311111': 638,
  '311119': 638,
  '311211': 638,
  '311212': 638,
  '311213': 638,
  '311221': 638,
  '311224': 638,
  '311225': 638,
  '311230': 638,
  '311313': 638,
  '311314': 638,
  '311340': 638,
  '311351': 638,
  '311352': 638,
  '311411': 638,
  '311412': 638,
  '311421': 638,
  '311422': 638,
  '311423': 638,
  '311511': 638,
  '311512': 638,
  '311513': 638,
  '311514': 638,
  '311520': 638,
  '311611': 638,
  '311612': 638,
  '311613': 638,
  '311615': 638,
  '311710': 638,
  '311811': 638,
  '311812': 638,
  '311813': 638,
  '311821': 638,
  '311824': 638,
  '311830': 638,
  '311911': 638,
  '311919': 638,
  '311920': 638,
  '311930': 638,
  '311941': 638,
  '311942': 638,
  '311991': 638,
  '311999': 638,
  '312111': 631,
  '312112': 631,
  '312113': 631,
  '312120': 628,
  '312130': 630,
  '312140': 630,
  '312230': 641,
  '313110': 253,
  '313210': 253,
  '313220': 255,
  '313230': 253,
  '313240': 253,
  '313310': 253,
  '313320': 253,
  '314110': 253,
  '314120': 253,
  '314910': 253,
  '314994': 253,
  '314999': 253,
  '315110': 249,
  '315190': 249,
  '315210': 249,
  '315220': 249,
  '315240': 249,
  '315280': 249,
  '315990': 249,
  '316110': 250,
  '316210': 250,
  '316992': 250,
  '316998': 250,
  '321113': 57,
  '321114': 57,
  '321211': 57,
  '321212': 57,
  '321213': 57,
  '321214': 57,
  '321219': 57,
  '321911': 57,
  '321912': 57,
  '321918': 57,
  '321920': 57,
  '321991': 57,
  '321992': 57,
  '321999': 57,
  '322110': 59,
  '322121': 60,
  '322122': 60,
  '322130': 56,
  '322211': 56,
  '322212': 56,
  '322219': 56,
  '322220': 56,
  '322230': 56,
  '322291': 56,
  '322299': 56,
  '323117': 415,
  '323120': 415,
  '324110': 29,
  '324121': 29,
  '324122': 29,
  '324191': 29,
  '324199': 29,
  '325110': 26,
  '325120': 26,
  '325130': 26,
  '325180': 26,
  '325193': 26,
  '325194': 26,
  '325199': 26,
  '325211': 31,
  '325212': 31,
  '325220': 31,
  '325311': 31,
  '325312': 31,
  '325314': 31,
  '325320': 31,
  '325411': 1015,
  '325412': 1015,
  '325413': 1015,
  '325414': 1015,
  '325510': 31,
  '325520': 31,
  '325611': 251,
  '325612': 251,
  '325613': 251,
  '325620': 251,
  '325910': 31,
  '325920': 31,
  '325991': 31,
  '325992': 31,
  '325998': 31,
  '326111': 28,
  '326112': 28,
  '326113': 28,
  '326121': 28,
  '326122': 28,
  '326130': 28,
  '326140': 28,
  '326150': 28,
  '326160': 28,
  '326191': 28,
  '326199': 28,
  '326211': 30,
  '326212': 30,
  '326220': 30,
  '326291': 30,
  '326299': 30,
  '327110': 39,
  '327120': 39,
  '327211': 39,
  '327212': 39,
  '327213': 39,
  '327215': 39,
  '327310': 39,
  '327320': 39,
  '327331': 39,
  '327332': 39,
  '327390': 39,
  '327410': 39,
  '327420': 39,
  '327910': 39,
  '327991': 39,
  '327992': 39,
  '327993': 39,
  '327999': 39,
  '331110': 43,
  '331210': 43,
  '331221': 43,
  '331222': 43,
  '331313': 43,
  '331314': 43,
  '331315': 43,
  '331318': 43,
  '331410': 43,
  '331420': 43,
  '331491': 43,
  '331492': 43,
  '331511': 43,
  '331512': 43,
  '331513': 43,
  '331523': 43,
  '331524': 43,
  '331529': 43,
  '332111': 49,
  '332112': 49,
  '332114': 49,
  '332117': 49,
  '332119': 49,
  '332215': 49,
  '332216': 49,
  '332311': 49,
  '332312': 49,
  '332313': 49,
  '332321': 49,
  '332322': 49,
  '332323': 49,
  '332410': 49,
  '332420': 49,
  '332431': 49,
  '332439': 49,
  '332510': 49,
  '332613': 49,
  '332618': 49,
  '332710': 49,
  '332721': 49,
  '332722': 49,
  '332811': 49,
  '332812': 49,
  '332813': 49,
  '332911': 49,
  '332912': 49,
  '332913': 49,
  '332919': 49,
  '332991': 49,
  '332992': 49,
  '332993': 49,
  '332994': 49,
  '332996': 49,
  '332999': 49,
  '333111': 48,
  '333112': 48,
  '333120': 48,
  '333131': 48,
  '333132': 48,
  '333241': 27,
  '333242': 27,
  '333243': 27,
  '333244': 27,
  '333249': 27,
  '333314': 48,
  '333316': 48,
  '333318': 48,
  '333413': 48,
  '333414': 48,
  '333415': 48,
  '333511': 48,
  '333514': 48,
  '333515': 48,
  '333517': 48,
  '333519': 48,
  '333611': 48,
  '333612': 48,
  '333613': 48,
  '333618': 48,
  '333912': 48,
  '333914': 48,
  '333921': 48,
  '333922': 48,
  '333923': 48,
  '333924': 48,
  '333991': 48,
  '333992': 48,
  '333993': 48,
  '333994': 48,
  '333995': 48,
  '333996': 48,
  '333997': 48,
  '333999': 48,
  '334111': 235,
  '334112': 235,
  '334118': 235,
  '334210': 235,
  '334220': 235,
  '334290': 235,
  '334310': 235,
  '334412': 235,
  '334413': 235,
  '334416': 235,
  '334417': 235,
  '334418': 235,
  '334419': 235,
  '334510': 235,
  '334511': 235,
  '334512': 235,
  '334513': 235,
  '334514': 235,
  '334515': 235,
  '334516': 235,
  '334517': 235,
  '334519': 235,
  '334613': 109,
  '334614': 109,
  '335121': 593,
  '335122': 593,
  '335129': 593,
  '335210': 598,
  '335220': 598,
  '335911': 1037,
  '335912': 1037,
  '336111': 229,
  '336112': 229,
  '336211': 229,
  '336212': 229,
  '336213': 229,
  '336214': 229,
  '336310': 229,
  '336320': 229,
  '336330': 229,
  '336340': 229,
  '336350': 229,
  '336360': 229,
  '336370': 229,
  '336390': 229,
  '336411': 64,
  '336412': 64,
  '336413': 64,
  '336414': 64,
  '336415': 64,
  '336419': 64,
  '336510': 50,
  '336611': 50,
  '336612': 324,
  '336991': 230,
  '336992': 64,
  '336999': 50,
  '337110': 67,
  '337121': 67,
  '337122': 67,
  '337124': 67,
  '337125': 67,
  '337127': 67,
  '337211': 67,
  '337212': 67,
  '337214': 67,
  '337215': 67,
  '337910': 67,
  '337920': 67,
  '339112': 651,
  '339113': 651,
  '339114': 653,
  '339115': 651,
  '339116': 653,
  '339910': 244,
  '339920': 247,
  '339930': 244,
  '339940': 106,
  '339950': 244,
  '339991': 244,
  '339992': 245,
  '339993': 244,
  '339994': 244,
  '339995': 244,
  '339999': 244,
  '423110': 226,
  '423120': 226,
  '423130': 227,
  '423140': 226,
  '423210': 444,
  '423220': 427,
  '423310': 36,
  '423320': 36,
  '423330': 36,
  '423390': 36,
  '423410': 427,
  '423420': 427,
  '423430': 427,
  '423440': 427,
  '423450': 650,
  '423460': 439,
  '423490': 427,
  '423510': 427,
  '423520': 427,
  '423610': 427,
  '423620': 444,
  '423690': 427,
  '423710': 427,
  '423720': 427,
  '423730': 427,
  '423740': 427,
  '423810': 427,
  '423820': 427,
  '423830': 427,
  '423840': 427,
  '423850': 427,
  '423860': 427,
  '423910': 455,
  '423920': 427,
  '423930': 427,
  '423940': 458,
  '423990': 491,
  '424110': 475,
  '424120': 542,
  '424130': 466,
  '424210': 452,
  '424310': 472,
  '424320': 472,
  '424330': 472,
  '424340': 472,
  '424410': 607,
  '424420': 607,
  '424430': 607,
  '424440': 607,
  '424450': 607,
  '424460': 607,
  '424470': 607,
  '424480': 607,
  '424490': 607,
  '424510': 607,
  '424520': 607,
  '424590': 607,
  '424610': 28,
  '424690': 26,
  '424710': 20,
  '424720': 20,
  '424810': 606,
  '424820': 606,
  '424910': 477,
  '424920': 463,
  '424930': 479,
  '424940': 491,
  '424950': 433,
  '424990': 456,
  '425110': 496,
  '425120': 496,
  '441110': 588,
  '441120': 588,
  '441210': 568,
  '441222': 324,
  '441228': 231,
  '441310': 225,
  '441320': 227,
  '442110': 592,
  '442210': 590,
  '442291': 590,
  '442299': 590,
  '443141': 521,
  '443142': 521,
  '444110': 36,
  '444120': 36,
  '444130': 524,
  '444190': 36,
  '444210': 36,
  '444220': 36,
  '445110': 611,
  '445120': 1020,
  '445210': 612,
  '445220': 610,
  '445230': 610,
  '445291': 617,
  '445292': 613,
  '445299': 610,
  '445310': 624,
  '446110': 605,
  '446120': 1054,
  '446130': 563,
  '446191': 535,
  '446199': 555,
  '447110': 589,
  '447190': 589,
  '448110': 512,
  '448120': 512,
  '448130': 512,
  '448140': 512,
  '448150': 512,
  '448190': 512,
  '448210': 512,
  '448310': 512,
  '448320': 512,
  '451110': 516,
  '451120': 547,
  '451130': 560,
  '451140': 246,
  '451211': 540,
  '451212': 540,
  '452210': 508,
  '452311': 625,
  '452319': 509,
  '453110': 529,
  '453210': 106,
  '453220': 529,
  '453310': 529,
  '453910': 529,
  '453920': 569,
  '453930': 529,
  '453991': 641,
  '453998': 529,
  '454110': 124,
  '454210': 497,
  '454310': 555,
  '454390': 622,
  '481111': 205,
  '481112': 192,
  '481211': 196,
  '481212': 196,
  '481219': 196,
  '482111': 210,
  '482112': 210,
  '483111': 199,
  '483112': 200,
  '483113': 199,
  '483114': 200,
  '483211': 199,
  '483212': 200,
  '484110': 214,
  '484121': 214,
  '484122': 214,
  '484210': 325,
  '484220': 211,
  '484230': 211,
  '485111': 215,
  '485112': 202,
  '485113': 215,
  '485119': 202,
  '485210': 215,
  '485310': 215,
  '485320': 215,
  '485410': 215,
  '485510': 215,
  '485991': 217,
  '485999': 215,
  '486110': 20,
  '486210': 20,
  '486910': 20,
  '486990': 20,
  '487110': 215,
  '487210': 200,
  '487990': 205,
  '488111': 219,
  '488119': 218,
  '488190': 218,
  '488210': 218,
  '488310': 218,
  '488320': 218,
  '488330': 218,
  '488390': 218,
  '488410': 345,
  '488490': 218,
  '488510': 218,
  '488991': 218,
  '488999': 218,
  '492110': 193,
  '492210': 193,
  '493110': 131,
  '493120': 131,
  '493130': 131,
  '493190': 131,
  '511110': 419,
  '511120': 419,
  '511130': 417,
  '511140': 413,
  '511191': 414,
  '511199': 418,
  '511210': 1052,
  '512110': 397,
  '512120': 406,
  '512131': 397,
  '512132': 397,
  '512191': 402,
  '512199': 404,
  '512230': 416,
  '512240': 397,
  '512250': 397,
  '512290': 397,
  '515111': 392,
  '515112': 391,
  '515120': 393,
  '515210': 391,
  '517911': 1017,
  '517919': 1017,
  '518210': 1011,
  '519110': 423,
  '519120': 298,
  '519130': 394,
  '519190': 109,
  '522190': 1055,
  '522210': 1055,
  '522291': 1056,
  '522292': 1056,
  '522293': 1056,
  '522294': 1056,
  '522298': 1056,
  '522310': 1034,
  '522320': 1055,
  '524210': 1002,
  '524298': 176,
  '525110': 1023,
  '531110': 128,
  '531130': 325,
  '531190': 128,
  '531320': 143,
  '531390': 128,
  '532111': 213,
  '532112': 213,
  '532120': 213,
  '532210': 331,
  '532281': 331,
  '532282': 331,
  '532283': 331,
  '532284': 331,
  '532289': 331,
  '532310': 128,
  '532411': 128,
  '532412': 128,
  '532420': 128,
  '532490': 128,
  '533110': 182,
  '541110': 181,
  '541120': 182,
  '541191': 119,
  '541199': 141,
  '541211': 150,
  '541213': 152,
  '541214': 150,
  '541219': 149,
  '541310': 185,
  '541320': 373,
  '541330': 186,
  '541340': 141,
  '541350': 117,
  '541360': 116,
  '541370': 116,
  '541380': 184,
  '541410': 146,
  '541420': 125,
  '541430': 156,
  '541490': 125,
  '541511': 1051,
  '541512': 1050,
  '541513': 1050,
  '541519': 1051,
  '541611': 163,
  '541612': 161,
  '541613': 166,
  '541614': 163,
  '541618': 163,
  '541620': 141,
  '541690': 141,
  '541713': 183,
  '541714': 183,
  '541715': 183,
  '541720': 183,
  '541810': 386,
  '541820': 388,
  '541830': 407,
  '541840': 812,
  '541850': 386,
  '541860': 385,
  '541870': 386,
  '541890': 386,
  '541910': 147,
  '541921': 330,
  '541922': 330,
  '541930': 421,
  '541940': 707,
  '541990': 188,
  '561110': 113,
  '561210': 102,
  '561311': 138,
  '561312': 137,
  '561320': 137,
  '561330': 137,
  '561410': 110,
  '561421': 122,
  '561422': 390,
  '561431': 102,
  '561439': 102,
  '561440': 103,
  '561450': 102,
  '561491': 121,
  '561492': 180,
  '561499': 102,
  '561510': 495,
  '561520': 495,
  '561591': 495,
  '561599': 495,
  '561611': 374,
  '561612': 378,
  '561613': 378,
  '561621': 236,
  '561622': 380,
  '561710': 98,
  '561720': 100,
  '561730': 360,
  '561740': 367,
  '561790': 123,
  '561910': 325,
  '561920': 155,
  '561990': 188,
  '562910': 1033,
  '562920': 1033,
  '562991': 1033,
  '562998': 1033,
  '611110': 302,
  '611210': 305,
  '611310': 300,
  '611410': 304,
  '611420': 305,
  '611430': 158,
  '611511': 303,
  '611512': 303,
  '611513': 157,
  '611519': 303,
  '611610': 299,
  '611620': 276,
  '611630': 299,
  '611691': 311,
  '611692': 306,
  '611699': 299,
  '611710': 310,
  '621111': 656,
  '621112': 694,
  '621210': 657,
  '621310': 666,
  '621320': 689,
  '621330': 678,
  '621340': 686,
  '621391': 693,
  '621399': 656,
  '621410': 661,
  '621420': 661,
  '621491': 661,
  '621492': 661,
  '621493': 661,
  '621498': 661,
  '621511': 661,
  '621512': 661,
  '621610': 658,
  '621910': 216,
  '621991': 699,
  '621999': 661,
  '623110': 660,
  '623311': 1031,
  '623312': 1031,
  '624190': 322,
  '624229': 145,
  '624310': 322,
  '624410': 307,
  '711110': 402,
  '711120': 402,
  '711130': 402,
  '711190': 402,
  '711211': 408,
  '711212': 409,
  '711219': 408,
  '711310': 412,
  '711320': 399,
  '711410': 388,
  '711510': 399,
  '712110': 272,
  '712120': 272,
  '712130': 274,
  '712190': 272,
  '713110': 261,
  '713120': 263,
  '713210': 260,
  '713290': 260,
  '713910': 277,
  '713920': 277,
  '713930': 267,
  '713940': 267,
  '713950': 266,
  '713990': 264,
  '721110': 259,
  '721120': 259,
  '721191': 259,
  '721199': 259,
  '721211': 278,
  '721214': 278,
  '722310': 279,
  '722320': 279,
  '722330': 279,
  '722410': 1040,
  '722511': 279,
  '722513': 279,
  '722514': 279,
  '722515': 286,
  '811111': 342,
  '811112': 342,
  '811113': 342,
  '811118': 342,
  '811121': 342,
  '811122': 342,
  '811191': 342,
  '811192': 342,
  '811198': 342,
  '811211': 129,
  '811212': 129,
  '811213': 129,
  '811219': 129,
  '811310': 129,
  '811411': 332,
  '811412': 336,
  '811420': 332,
  '811430': 332,
  '811490': 332,
  '812111': 327,
  '812112': 326,
  '812113': 326,
  '812191': 276,
  '812199': 326,
  '812210': 328,
  '812220': 328,
  '812310': 319,
  '812320': 319,
  '812331': 130,
  '812332': 130,
  '812910': 314,
  '812921': 109,
  '812922': 109,
  '812930': 344,
  '812990': 136,
  '814110': 338,
  '921110': 1029,
  '921120': 1029,
  '921130': 1029,
  '921140': 1029,
  '921150': 1029,
  '921190': 1029,
  '922120': 1022,
  '922140': 118,
  '922160': 1021,
  '924110': 1013,
  '926120': 1028,
  '926130': 1025,
  '926140': 1030,
  '926150': 1013,
  '927110': 1030,
  '928110': 1030,
  '928120': 1030,
  '213111': 9,
  '221310': 70,
  '221320': 97,
  '221330': 87,
  '236210': 350,
  '237110': 350,
  '237120': 350,
  '237130': 350,
  '323111': 90,
  '323113': 91,
  '335110': 49,
  '335311': 49,
  '335312': 49,
  '335313': 49,
  '335314': 49,
  '335921': 49,
  '335929': 49,
  '335931': 49,
  '335932': 49,
  '335991': 49,
  '335999': 49,
  '336120': 229,
  '491110': 193,
  '517311': 913,
  '517312': 913,
  '517410': 913,
  '521110': 724,
  '522110': 727,
  '522120': 732,
  '522130': 744,
  '522220': 1055,
  '522390': 747,
  '523110': 760,
  '523120': 759,
  '523130': 758,
  '523140': 758,
  '523210': 763,
  '523910': 760,
  '523920': 755,
  '523930': 754,
  '523991': 756,
  '523999': 755,
  '524113': 777,
  '524114': 777,
  '524126': 780,
  '524127': 780,
  '524128': 777,
  '524130': 782,
  '524291': 774,
  '524292': 775,
  '525120': 1002,
  '525190': 1002,
  '525910': 755,
  '525920': 1012,
  '525990': 752,
  '531120': 924,
  '531210': 924,
  '531311': 926,
  '531312': 933,
  '551111': 737,
  '551112': 737,
  '551114': 737,
  '562111': 96,
  '562112': 96,
  '562119': 96,
  '562211': 96,
  '562212': 96,
  '562213': 96,
  '562219': 96,
  '622110': 706,
  '622210': 706,
  '622310': 706,
  '623210': 705,
  '623220': 702,
  '623990': 309,
  '624110': 308,
  '624120': 1031,
  '624210': 963,
  '624221': 964,
  '624230': 964,
  '721310': 931,
  '813110': 967,
  '813211': 970,
  '813212': 965,
  '813219': 966,
  '813311': 975,
  '813312': 979,
  '813319': 975,
  '813410': 975,
  '813910': 972,
  '813920': 971,
  '813930': 982,
  '813940': 969,
  '813990': 973,
  '922110': 949,
  '922130': 181,
  '922150': 939,
  '922190': 949,
  '923110': 953,
  '923120': 951,
  '923130': 956,
  '923140': 952,
  '924120': 950,
  '925110': 955,
  '925120': 957,
  '926110': 954,
};
