import { Component, Input, OnInit } from '@angular/core';
import { IncentivesNewUsersService } from '../services/incentives-new-users.service';
import * as moment from 'moment';
import { US_DATE_MASK } from 'app/constants';

@Component({
  selector: 'app-incentives-new-users',
  templateUrl: 'incentives-new-users.component.html',
})
export class IncentivesNewUsersComponent implements OnInit {
  isEligible = false;
  incentiveEndDate: string;

  @Input()
  clear = false;

  constructor(private incentivesService: IncentivesNewUsersService) {}

  ngOnInit() {
    this.incentivesService.getUserCreationTime().subscribe((creationTime) => {
      if (!creationTime) {
        this.isEligible = false;
        return;
      }
      if (creationTime.isAfter(moment('09/01/2024', US_DATE_MASK))) {
        this.isEligible = false;
        return;
      }
      this.incentiveEndDate = creationTime.add(30, 'days').endOf('day').format('MMMM Do');
      const thirtyDaysAgo = moment().subtract(30, 'days');
      this.isEligible = thirtyDaysAgo.isBefore(creationTime.endOf('day'));
    });
  }
}
