import { Component, Input, OnInit } from '@angular/core';

import {
  RecentActivitySummaryService,
  RecentBindsData,
  RecentBindsResponse,
} from 'app/shared/services/recent-activity-summary.service';
import { buildingClassifications } from 'app/features/attune-bop/models/building-classifications';
import { Subscription, switchMap } from 'rxjs';
import { UserService } from 'app/core/services/user.service';
import { User } from 'app/shared/models/user';
import { AttuneProduct } from 'app/features/digital-carrier/models/types';

@Component({
  selector: 'app-activity-policies-drop-drown-list',
  templateUrl: './activity-policies-drop-drown-list.component.html',
})
export class ActivityPoliciesDropDrownListComponent implements OnInit {
  constructor(
    private userService: UserService,
    private recentActivitySummaryService: RecentActivitySummaryService
  ) {}

  @Input() productType: AttuneProduct['product'];
  private sub: Subscription = new Subscription();

  title: string = 'Latest policies';
  listItems: RecentBindsData[];
  isExpanded = false;
  startIndex = 0;
  limitIndex = 3;
  carrier: AttuneProduct['pasSource'] = 'attune_gw';

  ngOnInit() {
    this.sub.add(
      this.userService
        .getUser()
        .pipe(
          switchMap((user: User) => {
            return this.recentActivitySummaryService.getRecentBinds(
              user.producer,
              this.startIndex,
              this.limitIndex,
              true,
              this.productType,
              this.carrier
            );
          })
        )
        .subscribe((recentBindsData: RecentBindsResponse) => {
          this.listItems = recentBindsData.recentBinds;
        })
    );
  }

  getDisplayPreference(policy: RecentBindsData) {
    if (policy.classPreferenceLevel) {
      switch (policy.classPreferenceLevel) {
        case '1':
          return 'strongly preferred';
        case '2':
          return 'preferred';
        case '3':
        case '4':
          return 'non-preferred';
        default:
          break;
      }
    }
    return 'non-preferred';
  }

  getPolicyPreference(policy: RecentBindsData) {
    if (policy.classPreferenceLevel) {
      switch (policy.classPreferenceLevel) {
        case '1':
          return 'strongly_preferred';
        case '2':
          return 'preferred';
        case '3':
        case '4':
          return 'non_preferred';
        default:
          break;
      }
    }

    return 'non_preferred';
  }

  getRouterLink(policy: RecentBindsData) {
    return `/accounts/${policy.accountId}/terms/${policy.policyNumber}/${policy.term}`;
  }

  getDescription(policy: RecentBindsData) {
    if (policy.product === 'bop' && policy.classCodes) {
      const highestRiskBuidingBopCode = policy.classCodes[0];
      const [classificationMatch] = buildingClassifications.filter(
        (classification) => classification.code === highestRiskBuidingBopCode
      );
      return classificationMatch.description;
    }
    if (policy.product === 'wc' && policy.classCodeDescription) {
      return policy.classCodeDescription;
    }

    return 'Not available';
  }
}
