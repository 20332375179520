import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { SentryService } from 'app/core/services/sentry.service';
import { API_V3_BASE, ISO_DATE_MASK } from 'app/constants';
import { WcFormEmployeeClassificationCode } from 'app/workers-comp/employers/models/wc-policy';
import { HttpClient } from '@angular/common/http';
import * as Fuse from 'fuse.js';
import * as _ from 'lodash';
import * as moment from 'moment';

interface WcClassCode {
  code: string; // 8810-02
  classCode: string; // 8810
  classSeq: string; // 02
  easyRate: boolean;
  quoteable: boolean;
  description: string; // COMPUTER PROGRAMMERS & STUFF
  industryGroup: string;
}

const CLASS_CODE_SEARCH_OPTIONS = {
  distance: 1000,
  keys: ['description', 'classCode', 'industryGroup'],
  location: 0,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.65,
};

@Injectable()
export class WcClassCodesService {
  classCodesByStateEffectiveDate: { [key: string]: BehaviorSubject<any> } = {};
  constructor(private http: HttpClient, private sentryService: SentryService) {}

  /**
   * A function that requests the Employers Workers Comp class codes for a state & effective date.
   *
   * @param state - A 2 letter state code all caps.
   * @param effectiveDate - The effective date in the ISO_DATE_MASK form.
   * @returns An observable of a list of class codes.
   *
   * @throws An error if the parameters are invalid formats.
   */
  getClassCodes(state: string, effectiveDate: string): Observable<any> {
    if (state.length !== 2) {
      throw new Error('Invalid state code format');
    }

    if (!moment(effectiveDate, ISO_DATE_MASK, true).isValid()) {
      throw new Error('Invalid effective date format');
    }

    const key = `${state}-${effectiveDate}`;
    if (!this.availableOrFetching(key)) {
      this.classCodesByStateEffectiveDate[key] = new BehaviorSubject(null);
      this.http
        .get(`${API_V3_BASE}/employers-appetite/${state}`, {
          params: {
            effectiveDate,
          },
        })
        .subscribe(
          (result: any) => {
            const classCodes = this.generateClassCodeOptions(<WcClassCode[]>result.classCodes);
            const companionCodes = result.companionClassCodes;
            const fuse = new Fuse(classCodes, CLASS_CODE_SEARCH_OPTIONS);

            this.classCodesByStateEffectiveDate[key].next({ classCodes, companionCodes, fuse });
          },
          (resp: any) => {
            this.sentryService.notify('Failed to load WC Class Codes', {
              severity: 'error',
              metaData: {
                state: state,
                responseStatusCode: resp ? resp.status : null,
                responseError: resp ? resp.error : null,
                underlyingErrorMessage: resp.error && resp.error.message,
              },
            });

            this.classCodesByStateEffectiveDate[key].next({ error: true });
          }
        );
    }

    return this.classCodesByStateEffectiveDate[key].asObservable();
  }

  private availableOrFetching(key: string) {
    return (
      this.classCodesByStateEffectiveDate[key] &&
      _.isEmpty(_.get(this.classCodesByStateEffectiveDate[key], 'value.error'))
    );
  }

  private generateClassCodeOptions(classCodes: WcClassCode[]): WcFormEmployeeClassificationCode[] {
    return classCodes.map((code) => ({
      classCode: code.classCode,
      classSeq: code.classSeq,
      description: code.description,
      // Note: industryGroup and label are useful for the Fuse fuzzy searching, they are not needed in payload
      easyRate: code.easyRate,
      quoteable: code.quoteable,
      industryGroup: code.industryGroup || '',
    }));
  }
}
