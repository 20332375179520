import { forkJoin, Observable, Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SentryService } from 'app/core/services/sentry.service';

import { AmplitudeService } from 'app/core/services/amplitude.service';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { HiscoxQuoteService } from 'app/features/hiscox/services/hiscox-quote.service';
import { UserService } from 'app/core/services/user.service';

import { zendeskLeftSnap, removeZendeskLeftSnap } from 'app/shared/helpers/style-helpers';
import * as _ from 'lodash';
import { User } from 'app/shared/models/user';
import { RouteFormStep } from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { InformService } from 'app/core/services/inform.service';
import { UsStateService } from 'app/shared/services/us-state.service';
import { HISCOX_PRODUCTS, HISCOX_API_VERSION } from '../../models/hiscox-types';

import { FormDslSteppedFormComponent } from 'app/shared/form-dsl/components/form-dsl-stepped-form/form-dsl-stepped-form.component';
import { FormDslSteppedFormService } from 'app/shared/form-dsl/services/form-dsl-stepped-form.service';

import {
  BUSINESS_CLASS_OVERRIDES,
  HiscoxGlFormDataFieldV4,
  QUOTE_API_ERROR_STATUS_CODES,
} from 'app/features/hiscox/models/gl-constants';
import {
  HISCOX_UNKNOWN_ERROR,
  HISCOX_KNOWN_VALIDATION_ERROR,
  HISCOX_UNKNOWN_VALIDATION_ERROR,
  HISCOX_ZIP_ERROR,
} from 'app/shared/quote-error-modal/errors';
import { FormDslData } from 'app/shared/form-dsl/constants/form-dsl-typings';
import { mergeSeveralFormSteps } from 'app/shared/form-dsl/utils/form-dsl-helpers';
import {
  addressComponentIsCity,
  addressComponentIsCounty,
  addressComponentIsZipCode,
} from '../../../../shared/helpers/google-maps-helpers';
import { AttuneEventName, SegmentService } from 'app/core/services/segment.service';

@Component({
  selector: 'app-hiscox-quote-form.app-page.app-page__form',
  templateUrl: './hiscox-quote-form.component.html',
  providers: [FormDslSteppedFormService],
})
export class HiscoxQuoteFormComponent
  extends FormDslSteppedFormComponent
  implements OnInit, OnDestroy
{
  accountId: string;
  version: HISCOX_API_VERSION = HISCOX_API_VERSION.v4;
  productType: HISCOX_PRODUCTS.gl | HISCOX_PRODUCTS.pl;
  tsRequestId: string;
  accountDetails: InsuredAccount;
  insuredAccount: Observable<InsuredAccount | null>;
  currentStep: RouteFormStep;
  isDevMode = isDevMode();
  isEditing = false;
  isSubmittingForm = false;
  isStreetNumberMissing = false;
  quotedSuccess$ = new Subject();
  quoteResultModalOpen = false;
  requestQuoteErrors: Array<string>;
  requestQuoteErrorResponse: ErrorResponse | null;
  user: User;
  quoteForm: any = {};
  quoteRequest: any = {};
  quoteFormString: string;
  organizationTypes: { [key: string]: string } = {
    'Individual/Sole Proprietor': 'Individual/Sole Proprietor',
    'Joint Venture': 'Joint Venture',
    'Limited Liability Company': 'Limited Liability Company',
    Partnership: 'Partnership',
    Trust: 'Trust',
    'Corporation or other Organization (other than the above)':
      'Corporation or other Organization (other than the above)',
  };

  protected insuredAccountSubject = new Subject<InsuredAccount>();

  constructor(
    protected route: ActivatedRoute,
    protected informService: InformService,
    protected insuredAccountService: InsuredAccountService,
    protected router: Router,
    protected amplitudeService: AmplitudeService,
    protected userService: UserService,
    protected usStateService: UsStateService,
    protected hiscoxQuoteService: HiscoxQuoteService,
    public formService: FormDslSteppedFormService,
    protected sentryService: SentryService,
    protected segmentService: SegmentService
  ) {
    super(formService);
  }

  getValidationMessage() {
    return this.formService.getValidationMessage() || 'Please fill out all required fields.';
  }

  generateRequestId() {
    this.tsRequestId = uuidv4();
    this.amplitudeService.setNewQuoteTSID(this.tsRequestId);
  }

  getFormData() {
    const formDataPrior = this.formService.getValue();
    const formDataValues = _.values(formDataPrior);
    const formDataResult = mergeSeveralFormSteps(formDataValues);
    return formDataResult;
  }

  getErrorType() {
    if (this.requestQuoteErrors?.includes('Invalid Zip Code')) {
      return HISCOX_ZIP_ERROR;
    }
    if (
      (this.requestQuoteErrorResponse as any)?.http_status ===
      QUOTE_API_ERROR_STATUS_CODES.VALIDATION
    ) {
      return this.requestQuoteErrors?.length
        ? HISCOX_KNOWN_VALIDATION_ERROR
        : HISCOX_UNKNOWN_VALIDATION_ERROR;
    }
    return HISCOX_UNKNOWN_ERROR;
  }

  hasDisplayableQuoteErrors() {
    return !!this.requestQuoteErrors && this.requestQuoteErrors.length > 0;
  }

  getQuoteErrors() {
    const response = <ErrorResponse>this.requestQuoteErrorResponse;
    if (response?.status === QUOTE_API_ERROR_STATUS_CODES.VALIDATION) {
      // Result of SQ's schema validation, not Hiscox's
      return [response.error?.error];
    }
    const errors: string[] = [];

    const responseErrors = _.isArray(response.error) ? response.error : [response.error];
    responseErrors.forEach((error) => {
      if (!_.isString(error)) {
        return;
      }
      if (error === 'Invalid Zip Code') {
        errors.push('Invalid Zip Code');
      }
      if (error.includes('/CoverQuoteRq/RatingInfo/LOI')) {
        errors.push('Each Occurrence Limit (Per Claim Limit) is invalid');
      }
      if (error.includes('/CoverQuoteRq/RatingInfo/AggLOI')) {
        errors.push('Aggregate limit is invalid');
      }
    });
    return errors;
  }

  resetQuoteResults() {
    this.requestQuoteErrors = [];
    this.requestQuoteErrorResponse = null;
  }

  handleQuoteResultModalClose(result: { close: boolean; retry: boolean }) {
    if (!result.close && !result.retry) {
      this.router.navigate(['/accounts', this.accountId]);
    }

    this.resetQuoteResults();

    if (result.close) {
      this.quoteResultModalOpen = false;
    }

    if (result.retry) {
      this.submitForm();
    }
  }

  ngOnInit() {
    zendeskLeftSnap();
    this.accountId = this.route.snapshot.params['accountId'];

    const productIndex = this.route.snapshot.url.length - 2;
    this.productType = this.route.snapshot.url[productIndex].path as HISCOX_PRODUCTS;

    this.insuredAccount = this.insuredAccountSubject.asObservable();

    const formActionIndex = this.route.snapshot.url.length - 1;
    const formAction = this.route.snapshot.url[formActionIndex].path;

    this.isEditing = formAction === 'edit';

    if (this.isEditing) {
      this.sub.add(
        forkJoin([
          this.insuredAccountSubject.pipe(first()),
          this.hiscoxQuoteService.getQuote(this.productType, this.accountId),
        ]).subscribe(([insuredAccountDetails, quoteResponse]) => {
          const oldFrontendRequest = quoteResponse.origFrontendRequest;
          const oldFormData = oldFrontendRequest.formData;
          const state = _.get(
            oldFormData,
            'BusinessInfo_MailingAddress_AddrInfo_StateOrProvCd',
            ''
          );
          const classOfBusiness = _.get(oldFormData, 'BusinessInfo_ClassOfBusinessCd');

          // configure edit form for form-dsl component
          this.configureEditForm(oldFormData, classOfBusiness, state, insuredAccountDetails);
          // load user details
          this.loadUserDetails();
          // start form-dsl component
          super.ngOnInit();
          // submit form configuration and data for form-dsl component
          this.setFormConfig(this.formConfig);

          // Set deductible value to null if it does not match the expected value, so it can be validated
          if (this.productType === HISCOX_PRODUCTS.gl) {
            if (
              BUSINESS_CLASS_OVERRIDES['RATINGINFO_DEDUCTIBLE_EXCEPTIONS'].includes(
                classOfBusiness
              ) &&
              this.formData[
                HiscoxGlFormDataFieldV4
                  .PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_DEDUCTIBLE
              ] !== '1000'
            ) {
              this.formData[
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_DEDUCTIBLE
              ] = null;
            } else if (
              this.formData[
                HiscoxGlFormDataFieldV4
                  .PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_DEDUCTIBLE
              ] !== '0'
            ) {
              this.formData[
                HiscoxGlFormDataFieldV4.PRODUCTQUOTERQS_GENERALLIABILITYQUOTERQ_COVERQUOTERQ_RATINGINFO_DEDUCTIBLE
              ] = null;
            }
          }

          this.setFormData(this.formData);
        })
      );
    } else {
      this.sub.add(
        this.insuredAccountSubject.subscribe((accountDetails) => {
          // configure new form for form-dsl component
          this.configureNewForm(accountDetails);
          // load user details
          this.loadUserDetails();
          // start form-dsl component
          super.ngOnInit();
          // submit form configuration and data for form-dsl component
          this.setFormConfig(this.formConfig);
          this.setFormData(this.formData);
        })
      );
    }
    // load account details
    this.loadAccountDetails();
  }

  sendSegmentEvent(eventName: AttuneEventName) {
    const address = {
      addressLine1: this.accountDetails?.addressLine1,
      addressLine2: this.accountDetails?.addressLine2,
      city: this.accountDetails?.city,
      state: this.accountDetails?.state,
      zip: this.accountDetails?.zip,
    };

    // Note: classCode is vendor specific and it is not available on "Quote Started"
    let classCode;
    if (eventName === 'Quote Attempted') {
      classCode = {
        class_of_business: this.formService.form
          .get('quoteBasic.BusinessInfo_ClassOfBusinessCd')
          ?.value?.split('.')[0],
        naics_code: this.formService.form
          .get('quoteBasic.BusinessInfo_ClassOfBusinessCd')
          ?.value?.split('.')[1],
      };
    }

    this.segmentService.track({
      event: eventName,
      properties: {
        product: this.productType,
        carrier: 'hiscox',
        naics_code: this.accountDetails.naicsCode,
        class_code: classCode,
        primary_state: this.accountDetails?.state,
        insured_address: address,
        insured_email: this.accountDetails.emailAddress,
        business_name: this.accountDetails?.companyName,
      },
    });
  }

  ngOnDestroy() {
    removeZendeskLeftSnap();
    this.sub.unsubscribe();
  }

  navigateToAccountsPage() {
    this.insuredAccountService.cachebust();
    this.router.navigate(['/accounts', this.route.snapshot.params.accountId]);
  }

  handleRedirectError() {
    this.router.navigate(['accounts'], { replaceUrl: true });
    this.informService.errorToast(
      `We encountered an error loading the Hiscox quote flow.`,
      null,
      null,
      'Okay',
      null,
      0
    );
  }

  protected loadAccountDetails() {
    this.sub.add(
      this.insuredAccountService
        .get(this.accountId)
        .pipe(
          filter((insuredAccount: InsuredAccount) => {
            this.accountDetails = insuredAccount;
            this.sendSegmentEvent('Quote Started');
            return this.accountId === insuredAccount.id.toString();
          })
        )
        .subscribe((insuredAccount) => {
          this.insuredAccountSubject.next(insuredAccount);
        })
    );
  }

  // Note: This is implemented in HiscoxGlQuotePageComponent and HiscoxPlQuotePageComponent
  protected configureNewForm(accountDetails: InsuredAccount) {}

  // Note: This is implemented in HiscoxGlQuotePageComponent and HiscoxPlQuotePageComponent
  protected configureEditForm(
    oldFormData: FormDslData,
    classOfBusiness: string,
    state: string,
    insuredAccountDetails: InsuredAccount
  ) {}

  protected handleAddressAutocomplete(addressInfo: google.maps.places.PlaceResult) {
    if (!addressInfo || !addressInfo.address_components) {
      return;
    }

    let addressLine1 = '';
    let hasStreetNumber = false;
    addressInfo.address_components.forEach((component: google.maps.GeocoderAddressComponent) => {
      if (component.types.includes('street_number')) {
        addressLine1 += component.long_name + ' ';
        hasStreetNumber = true;
      } else if (component.types.includes('route')) {
        addressLine1 += component.long_name;
      } else if (addressComponentIsCity(component)) {
        this.formData[HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_CITY] =
          component.long_name;
      } else if (addressComponentIsCounty(component)) {
        this.formData[HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_COUNTY] =
          component.long_name;
      } else if (addressComponentIsZipCode(component)) {
        this.formData[HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_POSTALCODE] =
          component.long_name;
      }
    });
    this.formData[HiscoxGlFormDataFieldV4.BUSINESSINFO_MAILINGADDRESS_ADDRINFO_ADDR1] =
      addressLine1;
    this.setFormData(this.formData);
    this.isStreetNumberMissing = !hasStreetNumber;
    if (this.isStreetNumberMissing) {
      this.amplitudeService.track({
        eventName: 'street_number_missing',
        detail: JSON.stringify(addressInfo),
      });
      this.sentryService.notify('Selected address autocomplete is missing street number', {
        severity: 'warning',
        metaData: {
          addressInfo,
        },
      });
    }
  }

  // Note: this is implemented in HiscoxGlQuotePageComponent and HiscoxPlQuotePageComponent
  protected loadUserDetails() {
    this.sub.add(this.userService.getUser().subscribe((user) => (this.user = user)));
  }

  showPageRetryGrowl(retryMethod: Function) {
    this.informService.minorErrorToast(
      `We encountered an error while loading information about this quote. Please try refreshing the page.`,
      null,
      'Failed to retrieve quote.',
      'Retry',
      retryMethod,
      0
    );
  }

  hasNonEligibleError() {
    const quoteBasicForm = this.formService.form.get('quoteBasic');
    const quoteFormErrors = quoteBasicForm && quoteBasicForm.errors;
    if (quoteFormErrors !== null) {
      return quoteFormErrors['cobStateComboNotEligible'] !== null
        ? quoteFormErrors['cobStateComboNotEligible']
        : false;
    }
    return false;
  }
}
