<div *ngIf="!authenticated" class="help-center-header" role="presentation"></div>
<section class="help-center-section" [ngClass]="{ 'help-center-section__support': authenticated }">
  <h1 *ngIf="authenticated; else unauthenticated">Help center</h1>

  <ng-template #unauthenticated>
    <div class="help-center-header-content">
      <app-logo-with-text [darkText]="false"></app-logo-with-text>
      <p class="p p__help-center-header">
        Have an appointment?
        <a class="a a__help-center-header" href="/login?redirect=%2Fsupport">Sign in</a>
      </p>
    </div>
    <h1 class="h1 h1__help-center">Submit a request</h1>
  </ng-template>

  <ng-container *ngFor="let response of ticketResponses">
    <app-dialog-box
      *ngIf="response.status === 'SUCCESS'; else error"
      type="success"
      class="dialog-text__margin-bottom"
      header="Request received"
      [dismissable]="true"
    >
      Thank you for your submission, please allow up to 1 business day for our team to respond to
      your request and 24 hours for all bind related inquiries. You will receive an email from
      help&#64;attuneinsurance.com with your request number for reference. We will reach out to
      {{ response.email }} with any follow ups.
    </app-dialog-box>

    <ng-template #error>
      <app-dialog-box
        type="danger"
        class="dialog-text__margin-bottom"
        header="Submission failed"
        [dismissable]="true"
      >
        An error occurred processing your submission. Please try again or contact our team at
        <a href="tel:18885304650">1-888-530-4650</a>. We apologize for any inconvenience.
      </app-dialog-box>
    </ng-template>
  </ng-container>

  <app-support-help-center-form
    (ticketResponse)="handleTicketResponse($event)"
    [authenticated]="authenticated"
  >
  </app-support-help-center-form>
</section>
