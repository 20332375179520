import * as _ from 'lodash';
import {
  WcEmployeeClassification,
  WcExecutive,
  WcFormBasicInfo,
  WcFormEmployeeClassification,
  WcFormLocation,
  WcFormLocationManyEmployees,
  WcFormLossPolicyPeriod,
  WcFormPolicyInfo,
  WcFormStateBureauInfo,
  WcFormUwInfo,
  WcFormValue,
  WcLocation,
  WcLossPolicyPeriod,
  WcQuotePayload,
  WcStateBureauInfo,
} from '../models/wc-policy';

import {
  API_DATE_FORMAT,
  EMP_LIABILITY_MAP,
  EMP_TAX_ID_KEY_BY_STATE,
  EMPLOYERS_QUESTION_SLUG,
} from 'app/workers-comp/employers/constants';
import * as moment from 'moment';
import { US_DATE_MASK } from '../../../constants';
import { Injectable } from '@angular/core';

@Injectable()
export class WcQuoteSubmissionTranslator {
  constructor() {}

  public getFormValues(quoteSubmission: WcQuotePayload) {
    const basicInfo = this.constructBasicInfo(quoteSubmission);
    const locations = this.constructLocations(quoteSubmission);
    const policyInfo = this.constructPolicyInfo(quoteSubmission);
    const lossHistory = this.constructLossHistory(quoteSubmission);
    const underwritingInfo = this.constructUnderwritingInfo(quoteSubmission);

    const wcFormValues: WcFormValue = {
      basicInfo: basicInfo,
      locations: locations,
      lossInfo: {
        lossHistory: lossHistory,
      },
      policyInfo: policyInfo,
      underwritingInfo: underwritingInfo,
      uuid: quoteSubmission.uuid,
    };

    return wcFormValues;
  }

  private constructBasicInfo(quoteSubmission: WcQuotePayload): WcFormBasicInfo {
    if (!quoteSubmission.account) {
      return {} as WcFormBasicInfo;
    }

    const einString = _.toString(quoteSubmission.account.employerIdentificationNumber);
    const employerIdentificationNumber = einString.slice(0, 2) + '-' + einString.slice(2, 9);

    return {
      effectiveDate: moment(quoteSubmission.policy.effectiveDate, API_DATE_FORMAT).format(
        US_DATE_MASK
      ),
      employerIdentificationNumber: employerIdentificationNumber,
      numberOfLocations: quoteSubmission.account.locations.length,
      yearsInBusiness: _.toString(quoteSubmission.account.yearsInBusiness),
      yearsOfIndustryExperience: _.toString(quoteSubmission.account.yearsOfIndustryExperience),
    };
  }

  private constructLocations(quoteSubmission: WcQuotePayload): WcFormLocation[] {
    if (!quoteSubmission.account || !quoteSubmission.account.locations) {
      return [];
    }

    return quoteSubmission.account.locations.map((accountLocation: WcLocation) => {
      const currentState = accountLocation.address.state;
      const employeeClassifications: WcFormEmployeeClassification[] = (
        accountLocation.employeeClassifications || []
      ).map((submissionEmployeeClassification: WcEmployeeClassification) => {
        // Note: These codes get hotswapped to full codes coming from the backend
        // as soon as the class code options are loaded.
        return {
          code: {
            classCode: submissionEmployeeClassification.code,
            classSeq: '',
            description: submissionEmployeeClassification.codeDescription,
            easyRate: true,
            quoteable: true,
          },
          remuneration: '' + submissionEmployeeClassification.remuneration,
        } as WcFormEmployeeClassification;
      });

      const executives = (accountLocation.executives || []).map((submissionExec: WcExecutive) => {
        return {
          firstName: submissionExec.firstName,
          lastName: submissionExec.lastName,
          ownership: '' + submissionExec.ownership,
          title: submissionExec.title,
          isIncluded: submissionExec.isIncluded,
          questions: submissionExec.questions,
        };
      });

      const wcFormLocation: WcFormLocation = {
        addressLine1: _.toString(_.get(accountLocation.address, 'addressLine1')),
        addressLine2: _.toString(_.get(accountLocation.address, 'addressLine2')),
        city: _.toString(_.get(accountLocation.address, 'city')),
        employeeClassifications: employeeClassifications,
        employeeCount: _.toString(accountLocation.employeeCount),
        executives: executives,
        state: _.toString(_.get(accountLocation.address, 'state')),
        zip: _.toString(_.get(accountLocation.address, 'zip')),
      };

      if (accountLocation.employersLocationInfo) {
        wcFormLocation.manyEmployees = this.constructManyEmployees(accountLocation);
      }
      return wcFormLocation;
    });
  }

  private constructManyEmployees(accountLocation: WcLocation): WcFormLocationManyEmployees {
    if (!accountLocation.employersLocationInfo) {
      return {} as WcFormLocationManyEmployees;
    }
    return {
      constructionType: _.toString(accountLocation.employersLocationInfo.employersConstructionCode),
      employeesInShift1: _.toString(accountLocation.employersLocationInfo.employeesInShift1),
      employeesInShift2: _.toString(accountLocation.employersLocationInfo.employeesInShift2),
      employeesInShift3: _.toString(accountLocation.employersLocationInfo.employeesInShift3),
      storiesCount: _.toString(accountLocation.employersLocationInfo.employersNumStories),
      yearBuilt: moment(
        accountLocation.employersLocationInfo.employersConstructionDate,
        API_DATE_FORMAT
      ).format('YYYY'),
    };
  }

  private constructPolicyInfo(quoteSubmission: WcQuotePayload): WcFormPolicyInfo {
    if (!quoteSubmission.account || !quoteSubmission.policy) {
      return {} as WcFormPolicyInfo;
    }
    const stateWorkersCompBureauInfo = (
      quoteSubmission.account.stateWorkersCompBureauInfo || []
    ).map((submissionBureauInfo: WcStateBureauInfo): WcFormStateBureauInfo => {
      let taxOrUnemploymentId;
      if (EMP_TAX_ID_KEY_BY_STATE[submissionBureauInfo.state]) {
        taxOrUnemploymentId = quoteSubmission.account[
          EMP_TAX_ID_KEY_BY_STATE[submissionBureauInfo.state].accountKey
        ] as string;
      }
      return {
        state: submissionBureauInfo.state,
        taxOrUnemploymentId: taxOrUnemploymentId,
      };
    });
    let employersLiabilityLimits = '500k';
    // Reverse the mapping from key to value by matching which value was submitted back to the key
    _.each(EMP_LIABILITY_MAP, (value, key) => {
      if (_.isEqual(value, quoteSubmission.policy.employersLiabilityLimits)) {
        employersLiabilityLimits = key;
      }
    });
    return {
      employersLiabilityLimits: employersLiabilityLimits as '500k' | '1m' | '2m' | '100k',
      stateWorkersCompBureauInfo: stateWorkersCompBureauInfo,
    };
  }

  private constructLossHistory(quoteSubmission: WcQuotePayload): WcFormLossPolicyPeriod[] {
    if (!quoteSubmission.wcLossHistory) {
      return [];
    }
    return quoteSubmission.wcLossHistory.map((submissionLossPeriod: WcLossPolicyPeriod) => {
      const formLossPeriod: WcFormLossPolicyPeriod = {
        hasClaims: submissionLossPeriod.numClaims !== 0,
        policyEffectiveDate: moment(
          submissionLossPeriod.policyEffectiveDate,
          API_DATE_FORMAT
        ).format(US_DATE_MASK),
        policyExpirationDate: moment(
          submissionLossPeriod.policyExpirationDate,
          API_DATE_FORMAT
        ).format(US_DATE_MASK),
      };
      if (!formLossPeriod.hasClaims) {
        return formLossPeriod;
      }
      formLossPeriod.numClaims = _.toString(submissionLossPeriod.numClaims);
      formLossPeriod.amountPaid = _.toString(submissionLossPeriod.amountPaid);
      formLossPeriod.amountReserved = _.toString(submissionLossPeriod.amountReserved);
      return formLossPeriod;
    });
  }

  private constructUnderwritingInfo(quoteSubmission: WcQuotePayload): WcFormUwInfo {
    if (!quoteSubmission.uwAnswers) {
      return {} as WcFormUwInfo;
    }
    const underwritingInfo = {} as WcFormUwInfo;
    quoteSubmission.uwAnswers.forEach((submissionUwAnswer: any) => {
      underwritingInfo[submissionUwAnswer.slug as EMPLOYERS_QUESTION_SLUG] =
        submissionUwAnswer.answer;
      return;
    });
    return underwritingInfo;
  }
}
