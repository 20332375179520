import { switchMap, mergeMap, map } from 'rxjs/operators';
import { Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { allErrorsRecursively, getControl } from 'app/shared/helpers/form-helpers';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AmplitudeService } from 'app/core/services/amplitude.service';
import { SentryService } from 'app/core/services/sentry.service';
import { RewardsService } from 'app/shared/services/rewards.service';
import {
  DraftQuote,
  DraftQuoteService,
  DraftInsuranceProduct,
  DraftOrigin,
} from 'app/shared/services/draft-quote.service';
import { InsuredAccount } from 'app/features/insured-account/models/insured-account.model';
import { InsuredAccountService } from 'app/features/insured-account/services/insured-account.service';
import { QUOTE_API_ERROR_STATUS_CODES } from '../constants'; // Errors
import { FEINPlaceholderValues } from 'app/workers-comp/shared/constants';
import { US_DATE_MASK } from 'app/constants';
import { UNKNOWN_ERROR, ISSUE_ERROR } from 'app/shared/quote-error-modal/errors';
import { scrollToTop } from 'app/shared/helpers/scroll-helpers';
import {
  Subject,
  timer,
  Subscription,
  EMPTY,
  of as observableOf,
  forkJoin,
  ReplaySubject,
  interval,
  zip,
} from 'rxjs';
import { User } from 'app/shared/models/user';
import { UserService } from 'app/core/services/user.service';
import {
  RouteFormStep,
  FORM_SERVICE_TOKEN,
} from 'app/shared/form-dsl/services/form-dsl-stepped-form-base.service';
import { WcPolicy, WcFormValue } from '../models/wc-policy';
import { WcPolicyFactory } from 'app/workers-comp/employers/models/wc-policy-factory';
import { WcQuoteFormService } from 'app/workers-comp/employers/services/workers-comp-form.service';
import { WcQuotePayload } from 'app/workers-comp/employers/models/wc-policy';
import { WcQuoteService } from 'app/workers-comp/employers/services/workers-comp-quote.service';
import { WcQuoteSubmissionTranslator } from '../services/workers-comp-quote-submission-translator.service';
import { zendeskLeftSnap, removeZendeskLeftSnap } from 'app/shared/helpers/style-helpers';
import { disallowAccountEdits } from 'app/shared/helpers/account-helpers';
import { UWAlertService } from 'app/shared/services/uw-alert.service';
import { OnboardingService } from 'app/shared/services/onboarding.service';
import { ActionName } from 'app/shared/rewards/rewards-types';
import { environment } from 'environments/environment';
import { AttuneEventName, SegmentService } from 'app/core/services/segment.service';
import { ProductFeedbackService } from 'app/shared/services/product-feedback.service';
import { QuoteFeedbackForm } from 'app/shared/helpers/quote-feedback-helpers';

@Component({
  providers: [
    WcPolicyFactory,
    WcQuoteFormService,
    // This allows us to inject the same form service instance to a child component via an injection token.
    {
      provide: FORM_SERVICE_TOKEN,
      useExisting: WcQuoteFormService,
    },
  ],
  selector: 'app-wc.app-page.app-page__form',
  templateUrl: './wc-quote-form.component.html',
})
export class WcQuoteFormComponent implements OnInit, OnDestroy {
  accountId: string;
  formValuesForEdit: WcFormValue;
  action: 'edit' | 'new';
  insAccount: InsuredAccount;
  user: User;
  currentStep: RouteFormStep;
  isDevMode = isDevMode();
  isRequestingQuote = false;
  quotedSuccess$ = new Subject();
  quoteResultModalOpen = false;
  requestQuoteValidationErrors: Array<string>;
  createQuoteErrorResponse: ErrorResponse | null;
  currentDraftId$: ReplaySubject<any> = new ReplaySubject(1);
  lastSavedDraftFormValue: WcFormValue;
  isFEINPlaceholderValue = false;
  organizationTypeReadOnly = false;
  feinFieldOnPolicyPage = false;
  wcCrossSellPromptProducers = environment.wcCrossSellPromptProducers;

  private sub: Subscription = new Subscription();

  constructor(
    public formService: WcQuoteFormService,
    private router: Router,
    private route: ActivatedRoute,
    private insuredAccountService: InsuredAccountService,
    private userService: UserService,
    private wcQuoteService: WcQuoteService,
    private wcPolicyFactory: WcPolicyFactory,
    private amplitudeService: AmplitudeService,
    private wcQuoteSubmissionTranslator: WcQuoteSubmissionTranslator,
    private draftQuoteService: DraftQuoteService,
    private onboardingService: OnboardingService,
    protected uwAlertService: UWAlertService,
    private sentryService: SentryService,
    private rewardsService: RewardsService,
    private segmentService: SegmentService,
    private productFeedbackService: ProductFeedbackService
  ) {}

  ngOnInit() {
    const today = moment().utc();
    this.amplitudeService.setNewWcID(_.get(this.formService.get('uuid'), 'value'));

    this.sub.add(
      this.formService.submittedForm$.subscribe(() => {
        this.submitForm();
      })
    );

    this.sub.add(
      this.userService.getUser().subscribe((user) => {
        this.user = user;
        if (this.wcCrossSellPromptProducers.includes(this.user.producer)) {
          this.formService.moveFeinFieldToPolicyPage();
          this.feinFieldOnPolicyPage = true;
        }
      })
    );

    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            this.accountId = params['accountId'];
            this.action = params['action'];
            return this.insuredAccountService.get(this.accountId);
          })
        )
        .subscribe(
          (insuredAccount: InsuredAccount) => {
            if (this.accountId === insuredAccount.id.toString()) {
              this.insAccount = insuredAccount;
              this.sendSegmentEvent('Quote Started');
            }
            if (this.fromAccountCreate()) {
              this.formService.showNewAccountStep();
            }
          },
          (error) => {
            console.warn('*** error fetching account information ***' + error.ToString());
          }
        )
    );

    this.sub.add(
      zip(
        this.insuredAccountService.get(this.accountId),
        this.wcQuoteService.getQuote(this.accountId)
      )
        .pipe(
          map(([insuredAccount, wcQuote]) => {
            this.organizationTypeReadOnly = disallowAccountEdits(insuredAccount, wcQuote);
            if (this.organizationTypeReadOnly) {
              const organizationType = getControl(
                this.formService.form,
                'basicInfo.organizationType'
              );
              organizationType.patchValue(insuredAccount.organizationType);
            }
          })
        )
        .subscribe()
    );

    this.sub.add(
      this.formService.decrementedStep$.subscribe((prevStep: RouteFormStep) => {
        this.onDecrementedStep(prevStep);
      })
    );

    this.sub.add(
      this.route.params
        .pipe(
          switchMap((params) => {
            let getQuoteSubmission;
            switch (params['action']) {
              case 'edit':
                this.action = 'edit';
                break;
              case 'new':
                this.action = 'new';
                break;
              default:
                this.router.navigateByUrl(`/accounts/${params['accountId']}/workers-comp/new`); // TODO - find a better solution, maybe this should be a 404 - throw error /catch
                getQuoteSubmission = observableOf(null);
                break;
            }
            if (this.action === 'edit') {
              getQuoteSubmission = this.wcQuoteService.getQuoteSubmission(params['accountId']);
            } else {
              getQuoteSubmission = observableOf(null);
            }

            return forkJoin(
              this.draftQuoteService.get(params['accountId']),
              getQuoteSubmission,
              observableOf(params['accountId'])
            );
          })
        )
        .subscribe(
          ([drafts, quoteSubmission, accountId]: [DraftQuote[], WcQuotePayload | null, string]) => {
            const currentDraft = drafts.filter((draft) => draft.product === 'WC')[0];

            if (quoteSubmission) {
              const formValues = this.wcQuoteSubmissionTranslator.getFormValues(quoteSubmission);

              const formerEffectiveDate = moment(formValues.basicInfo.effectiveDate, US_DATE_MASK);
              if (formerEffectiveDate.isBefore(today)) {
                formValues.basicInfo.effectiveDate = today.format(US_DATE_MASK);
              }

              this.formService.patchFormControlsForEdit(formValues);
              this.amplitudeService.setNewWcID(formValues.uuid);
              // TODO (olex) - Can remove if form is initialized before above line^?
              this.formValuesForEdit = formValues;
            } else if (currentDraft) {
              const formData = currentDraft.formData as WcFormValue;

              const formerEffectiveDate = moment(formData.basicInfo.effectiveDate, US_DATE_MASK);
              if (formerEffectiveDate.isBefore(today)) {
                formData.basicInfo.effectiveDate = today.format(US_DATE_MASK);
              }

              this.formService.patchFormControlsForEdit(formData);

              this.amplitudeService.setNewWcID(formData.uuid);
              this.formValuesForEdit = formData;

              this.currentDraftId$.next(currentDraft.id);
            } else {
              const bopDataStringified = localStorage.getItem('bopDataForWCQuote');
              if (bopDataStringified) {
                const bopData = JSON.parse(bopDataStringified);
                if (bopData && bopData.accountId === accountId) {
                  this.formService.form.patchValue(bopData);
                }
              }
              // create a new draft here
              this.draftQuoteService
                .create({
                  accountId: accountId,
                  formValue: this.formService.form.value,
                  product: DraftInsuranceProduct.WC,
                  origin: DraftOrigin.QuoteFlow,
                })
                .subscribe((response: { id: string }) => {
                  const draftId = response && response.id;
                  if (draftId) {
                    this.currentDraftId$.next(draftId);
                  }
                });
            }
          }
        )
    );

    // Save drafts every 5 seconds if form value changes.
    const FIVE_SECONDS_IN_MS = 5 * 1000;
    this.sub.add(
      interval(FIVE_SECONDS_IN_MS)
        .pipe(
          mergeMap(() => this.currentDraftId$),
          mergeMap((currentDraftId) => {
            // If the form value has changed, update draft
            if (!_.isEqual(this.lastSavedDraftFormValue, this.formService.form.value)) {
              this.lastSavedDraftFormValue = this.formService.form.value;

              return this.draftQuoteService.update(
                this.accountId,
                currentDraftId,
                this.formService.form.value
              );
            } else {
              return EMPTY;
            }
          })
        )
        .subscribe()
    );

    this.sub.add(
      this.formService.currentStep$.subscribe((step: RouteFormStep) => {
        this.navigateToCurrentStep();
      })
    );

    this.setQuoteFeedbackStep();
    zendeskLeftSnap();
    // If the number of locations updated, update number of steps
    this.formService.syncAllSteps();
    this.navigateToCurrentStep();
  }

  ngOnDestroy() {
    removeZendeskLeftSnap();
    this.amplitudeService.unsetWcIDs();
    this.sub.unsubscribe();
  }

  onDecrementedStep(_prevStep: RouteFormStep) {
    if (_prevStep.slug === 'account') {
      this.returnToAccountCreate();
    }
  }

  returnToAccountCreate() {
    this.router.navigate(['/accounts/new'], {
      queryParams: { 'existing-account': this.accountId },
    });
  }

  fromAccountCreate() {
    return this.route.snapshot && this.route.snapshot.queryParams['from-account-create'];
  }

  hasFeedbackQueryParam() {
    return this.route.snapshot && this.route.snapshot.queryParams['quote-feedback'];
  }

  handleSubmit(submitEvent?: Event): boolean {
    if (submitEvent) {
      submitEvent.preventDefault();
    }

    return this.formService.stepForward();
  }

  loadingValuesForEdit() {
    return this.action === 'edit' && !this.formValuesForEdit;
  }

  policyData() {
    if (this.formService.form.value && this.insAccount && this.user) {
      return this.wcPolicyFactory.build(this.formService.form.value, this.insAccount, this.user);
    } else {
      this.sentryService.notify('Missing required data to build WC quote payload.', {
        severity: 'error',
        metaData: {
          userData: this.user,
          insAccount: this.insAccount,
          formValue: this.formService.form.value,
        },
      });
    }
    return <WcPolicy>{};
  }

  locationsFormArray() {
    return <UntypedFormArray>this.formService.locationsFormArray();
  }

  onAddLocations(numberToAdd: number) {
    _.times(numberToAdd, () => this.formService.addLocation());
  }

  onRemoveLocations(numberToRemove: number) {
    _.times(numberToRemove, () => this.formService.removeLastLocation());
  }

  tooManyLocations(): boolean {
    return this.locationsFormArray().length > 6;
  }

  // Counted from 1, user-visible location index
  locationIndex() {
    const currentStepSlug = this.formService.getCurrentStep().slug;
    if (/^location-\d+/.test(currentStepSlug)) {
      return Number(currentStepSlug.split('-')[1]);
    }

    return null;
  }

  currentLocation() {
    const locationsArray = this.locationsFormArray();
    const loc = this.locationIndex();
    if (loc) {
      return locationsArray && locationsArray.at(loc - 1);
    }
    return null;
  }

  populateHappyPath() {
    this.formService.fillInHappyPath();
  }

  goBackToAccount() {
    this.router.navigate(['accounts', this.accountId]);
  }

  resetQuoteResults() {
    this.requestQuoteValidationErrors = [];
    this.createQuoteErrorResponse = null;
  }

  handleQuoteResultModalClose(result: { close: boolean; retry: boolean }) {
    this.resetQuoteResults();

    if (result.close) {
      this.quoteResultModalOpen = false;
    }

    if (result.retry) {
      this.submitForm();
    }
  }

  checkFEINValue() {
    const FEINValue = (<UntypedFormControl>(
      this.formService.form.get('basicInfo.employerIdentificationNumber')
    )).value;
    if (FEINValue) {
      this.isFEINPlaceholderValue = FEINPlaceholderValues.includes(FEINValue);
    }
  }

  sendSegmentEvent(eventName: AttuneEventName) {
    const address = {
      addressLine1: this.insAccount.addressLine1,
      addressLine2: this.insAccount.addressLine2,
      city: this.insAccount.city,
      state: this.insAccount.state,
      zip: this.insAccount.zip,
    };
    // Note: class_code, which is vendor specific, is not available on "Quote Started" event
    let classCode;
    if (eventName === 'Quote Attempted') {
      classCode = this.locationsFormArray()?.at(0)?.get('employeeClassifications.0.code')?.value;
    }
    this.segmentService.track({
      event: eventName,
      properties: {
        product: 'wc',
        carrier: 'employers',
        naics_code: this.insAccount.naicsCode,
        class_code: classCode,
        primary_state: this.insAccount.state,
        insured_address: address,
        insured_email: this.insAccount?.emailAddress,
        business_name: this.insAccount?.companyName.toString(),
      },
    });
  }

  submitForm() {
    this.isRequestingQuote = true;

    const organizationType = <UntypedFormControl>(
      this.formService.form.get('basicInfo.organizationType')
    );
    const accountUpdateSub = this.insuredAccountService.updateOrgTypeIfNecessary(
      organizationType.value
    );

    this.sendSegmentEvent('Quote Attempted');

    this.sub.add(
      accountUpdateSub
        .pipe(
          switchMap((insuredAccount) => {
            this.uwAlertService
              .runUWAlert(insuredAccount.id, {
                insuredAccount: insuredAccount,
                newWCQuote: this.policyData(),
              })
              .subscribe();
            return this.wcQuoteService.requestQuote(this.policyData(), this.action === 'edit');
          })
        )
        .subscribe((resp: any) => {
          if (resp && resp.status && typeof resp.status === 'string') {
            // TODO(olex): event name should be explicit ('quoted' / 'declined' / 'pending_refer' ?)
            this.amplitudeService.track({
              eventName: String(resp.status),
              detail: 'workers_comp',
              useLegacyEventName: true,
            });
          }

          if (resp && resp.error) {
            this.isRequestingQuote = false;
            this.createQuoteErrorResponse = resp;
            this.requestQuoteValidationErrors = this.getQuoteValidationErrors();
            this.quoteResultModalOpen = true;
            this.amplitudeService.track({
              eventName: 'wc_error',
              detail: JSON.stringify(resp.error),
              useLegacyEventName: true,
            });
            return;
          }

          // Successful quote
          this.quotedSuccess$.next(true);
          this.rewardsService.submitRewardAction({
            actionName: ActionName.QUOTE_FOR_ACCOUNT,
            data: {
              insuredAccountId: this.insAccount.id,
              accountName: this.insAccount.companyName,
              carrierName: 'employers',
              product: 'wc',
            },
          });
          timer(3000).subscribe(() => {
            this.isRequestingQuote = false;
            this.router.navigate(['/accounts', this.insAccount ? this.insAccount.id : '']);
          });
        })
    );

    this.submitProductFeedbackIfApplicable();
  }

  getErrorType() {
    if (this.hasDisplayableQuoteValidationErrors()) {
      return ISSUE_ERROR;
    }
    return UNKNOWN_ERROR;
  }

  hasQuoteValidationErrors() {
    return this.hasDisplayableQuoteValidationErrors() || this.hasOtherQuoteValidationErrors();
  }

  hasDisplayableQuoteValidationErrors() {
    return !!this.requestQuoteValidationErrors && this.requestQuoteValidationErrors.length > 0;
  }

  hasOtherQuoteValidationErrors() {
    return (
      !!this.createQuoteErrorResponse &&
      this.createQuoteErrorResponse.status === QUOTE_API_ERROR_STATUS_CODES.VALIDATION &&
      !this.hasDisplayableQuoteValidationErrors()
    );
  }

  hasOtherQuoteErrors() {
    return (
      !!this.createQuoteErrorResponse &&
      this.createQuoteErrorResponse.status !== QUOTE_API_ERROR_STATUS_CODES.VALIDATION
    );
  }

  private getQuoteValidationErrors() {
    const response = <ErrorResponse>this.createQuoteErrorResponse;
    if (response.status === QUOTE_API_ERROR_STATUS_CODES.VALIDATION) {
      const errorObject = response.error.error;

      if (Object.prototype.hasOwnProperty.call(errorObject, 'quoteErrors')) {
        return errorObject.quoteErrors;
      } else if (Object.prototype.hasOwnProperty.call(errorObject, 'employersApiErrors')) {
        return errorObject.employersApiErrors.concat(errorObject.employersApiWarnings);
      }
    }

    return [];
  }

  allErrors(): any {
    return allErrorsRecursively(this.formService.form);
  }

  isCurrentStep(slug: string) {
    if (!this.currentStep) {
      return false;
    }
    const currentSlug = this.currentStep.slug;
    const slugRegex = new RegExp('^' + slug + '$');
    const result = currentSlug ? currentSlug.match(slugRegex) : false;
    return result;
  }

  submitted() {
    return this.formService.submitted;
  }

  clickBackward() {
    this.formService.stepBackward();
  }

  isFinalStep() {
    return this.formService.isFinalStep();
  }

  isFirstStep() {
    return this.formService.isFirstStep();
  }

  isCurrentStepValid() {
    return this.formService.isCurrentStepValid();
  }

  // TODO (olex): Break out the sections into components and use routes to move between form steps
  handleNavigateToSlug(slug: string) {
    if (slug === 'account') {
      this.returnToAccountCreate();
      return;
    }

    const step = this.formService.findStep({
      args: {},
      slug,
    });
    if (!step) {
      throw new Error(`Unable to navigate to unknown step: ${slug}.`);
    }
    const difference = this.formService.stepDifference(this.currentStep, step);
    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        this.formService.stepForward();
      }
    } else {
      this.formService.stepWithoutValidation(step);
      this.navigateToCurrentStep();
    }
  }

  protected navigateToCurrentStep(): void {
    this.currentStep = this.formService.getCurrentStep();
    scrollToTop();
  }

  getQuoteSuccessSubject() {
    return this.quotedSuccess$.asObservable();
  }

  setQuoteFeedbackStep() {
    // When we navigate from the alternative carrier card
    // we collect feedback on why brokers are seeking a second quote w/ a diff carrier.
    if (this.hasFeedbackQueryParam()) {
      return this.formService.showQuoteFeedbackStep();
    }
  }

  submitProductFeedbackIfApplicable() {
    const feedbackForm = this.formService.form.get('quoteFeedback') as QuoteFeedbackForm;
    // Submit feedback if this formgroup is enabled and feedback step has been shown.
    if (feedbackForm && feedbackForm.enabled && this.hasFeedbackQueryParam()) {
      this.sub.add(
        this.productFeedbackService
          .submitProductFeedback({
            accountId: this.accountId,
            pasSource: 'employers',
            product: 'wc',
            quoteUuid: this.formService.form.value.uuid,
            feedbackForm: feedbackForm,
          })
          .subscribe()
      );
    }
  }
}
