<section>
  <div
    class="policies-overview-pane policies-overview-recent-data-pane"
    *ngIf="loadingData && !estimatedDataLength"
  >
    <h3>{{ getSectionHeading() }}</h3>

    <table>
      <tbody>
        <tr>
          <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    class="policies-overview-pane policies-overview-recent-data-pane"
    *ngIf="!loadingData || estimatedDataLength"
  >
    <div class="policies-overview-pane-header">
      <h3 [ngClass]="{ 'gray-text': dataCount === 0 }">
        {{ getSectionHeading() }}
        <span *ngIf="hideData">({{ dataCount }})</span>
        <span class="gray-text" *ngIf="dataCount === 0">(0)</span>
      </h3>

      <a
        class="policy-pane-toggle"
        *ngIf="!hideData && dataCount !== 0"
        (click)="hideData = true; ref.detectChanges()"
        (keyup.enter)="hideData = true; ref.detectChanges()"
      >
        Hide
      </a>

      <a
        class="policy-pane-toggle"
        *ngIf="hideData && dataCount !== 0"
        (click)="hideData = false; ref.detectChanges()"
        (keyup.enter)="hideData = false; ref.detectChanges()"
      >
        Show
      </a>
    </div>

    <table *ngIf="!hideData && dataCount !== 0" class="js-recent-data-list-table">
      <thead>
        <th>LOB</th>
        <th>Account</th>
        <th>Effective</th>
        <th>Status</th>
        <th>Premium</th>
      </thead>
      <tbody>
        <ng-container *ngIf="loadingData">
          <tr *ngFor="let i of buildArray(estimatedDataLength)" class="policies-overview-row">
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell"><div class="table-loading-cell"></div></td>
            <td class="policies-overview-table-cell td__right">
              <div class="table-loading-cell"></div>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="!loadingData">
          <tr class="policies-overview-row" *ngFor="let dataRow of data">
            <td class="policies-overview-table-cell">
              <div
                [className]="
                  'product-badge product-badge__table-cell product-badge__' + dataRow.product
                "
              >
                <div
                  [className]="
                    'product-badge-carrier-icon product-badge-carrier-icon__table-cell product-badge-carrier-icon__' +
                    getCarrierName(dataRow)
                  "
                ></div>
              </div>
            </td>
            <td class="policies-overview-table-cell js-account-name-cell">
              {{ getAccountName(dataRow) }}
            </td>
            <td class="policies-overview-table-cell">
              {{ formatDate(dataRow.effectiveDate) }}
            </td>
            <td class="policies-overview-table-cell js-recent-data-status-cell">
              <span ngClass="pill-tag {{ getCssClassForPillTag(dataRow) }}">{{
                getFormattedStatus(dataRow.status)
              }}</span>
            </td>
            <td class="policies-overview-table-cell js-premium-cell">
              {{ getPremium(dataRow) }}
            </td>
            <td class="policies-overview-table-cell td__right">
              <a
                class="button button__small button__transparent button__no-margin"
                [routerLink]="getRouterLink(dataRow)"
                ><strong>DETAILS</strong></a
              >
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <div class="policies-overview-page-selector page-selector-with-link">
      <div
        class="js-policies-renewal-page-selector"
        *ngIf="!hideData && dataCount > policyPageLength"
      >
        <a
          *ngIf="dataSelectedIndex > 0"
          (click)="loadPreviousData()"
          (keyup.enter)="loadPreviousData()"
          class="js-policies-recent-data-previous"
          ><span class="icon-arrow-left button-icon"></span
        ></a>
        <span class="gray-text" *ngIf="dataSelectedIndex <= 0"
          ><span class="icon-arrow-left button-icon"></span
        ></span>

        <span>
          {{ getFirstItemNumber(dataSelectedIndex) }}-{{
            getLastItemNumber(dataSelectedIndex, dataCount, policyPageLength)
          }}
        </span>
        <span class="gray-text"> of </span>
        <span> {{ dataCount }} </span>

        <a
          *ngIf="dataSelectedIndex + policyPageLength < dataCount"
          (click)="loadNextData()"
          (keyup.enter)="loadNextData()"
          class="js-policies-recent-data-next"
          ><span class="icon-arrow-right button-icon"></span
        ></a>
        <span class="gray-text" *ngIf="dataSelectedIndex + policyPageLength >= dataCount"
          ><span class="icon-arrow-right button-icon"></span
        ></span>
      </div>
    </div>
  </div>
</section>
