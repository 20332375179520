import { BopQuotePayload } from 'app/features/attune-bop/models/bop-policy';
import { parsedFutureMomentOrNow } from 'app/bop/guidewire/translation-helpers';
import { BopAdditionalInsuredBusiness } from 'app/features/attune-bop/models/bop-additional-insured-business';
import {
  BOP_V2_UW_COMPANY,
  BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_10000,
  BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_25000,
} from 'app/features/attune-bop/models/constants';
import { numberToMoneyString } from 'app/shared/helpers/number-format-helpers';
import { US_DATE_MASK } from 'app/constants';
import * as moment from 'moment';
import { findClassification } from '../services/attune-bop-building-classification.service';
import { shouldGetMeToo } from 'app/shared/helpers/account-helpers';

const translateToLessorsRiskForm = (building: QSBuilding): BopBuildingLessorsRisk | undefined => {
  const lessorsRiskData = building.lessorsRisk;
  if (!lessorsRiskData) {
    return undefined;
  }

  return {
    ...lessorsRiskData,
    lessorsRiskTenants: lessorsRiskData.lessorsRiskTenants.map((tenant) => {
      return {
        ...tenant,
        occupancyStatus: tenant.occupancyStatus,
        squareFootage: tenant.squareFootage.toLocaleString('en-US'),
        rent: tenant.rent ? numberToMoneyString(tenant.rent) : undefined,
      };
    }),
    rentRollFiles: lessorsRiskData.rentRollFiles
      ? lessorsRiskData.rentRollFiles.map((file) => {
          return {
            s3Key: file.s3Key,
            s3Bucket: file.s3Bucket,
            name: file.originalFilename,
            status: 'done',
            // Not needed when patching the form for an edit.
            size: '',
            s3url: '',
            message: '',
          };
        })
      : [],
  };
};

const translateV3ToAdditionalInsureds = (
  attachments?: QSAttachments
): BopAdditionalInsuredBusiness[] => {
  if (!attachments || !attachments.additionalInsuredBusinesses) {
    return [];
  }

  return attachments.additionalInsuredBusinesses.map(
    (ai: QSBopAdditionalInsured): BopAdditionalInsuredBusiness => {
      // buildings and locations are 1-indexed in the form
      const locationNum = ai.locationNum !== undefined ? ai.locationNum + 1 : 1;
      const buildingNum = ai.buildingNum !== undefined ? ai.buildingNum + 1 : 1;
      const relatedLocation = `location-${locationNum}-${buildingNum}`;
      return {
        ...ai,
        relatedLocation: relatedLocation,
      };
    }
  );
};

const translateV3ToFormBuilding = (building: QSBuilding): DeepPartial<BopBuilding> => {
  const exp = building.exposure;
  const cov = building.coverage;

  const classCodeInfo = findClassification(exp.businessType, exp.classification.descriptionCode);

  return {
    exposure: {
      buildingLimit: numberToMoneyString(exp.buildingLimit),
      businessType: exp.businessType,
      classification: {
        alcoholSales: String(exp.alcoholSales) || undefined,
        code: {
          code: classCodeInfo ? classCodeInfo.code : '',
          descriptionCode: exp.classification.descriptionCode,
        },
      },
      constructionType: exp.constructionType,
      electricPlumbingHVACUpdated: exp.electricPlumbingHVACUpdated,
      hasAutomaticSprinklerSystem: exp.hasAutomaticSprinklerSystem,
      lessorsRisk: exp.lessorsRisk,
      limitForBusinessPersonalProperty:
        numberToMoneyString(exp.limitForBusinessPersonalProperty) || '',
      payroll: numberToMoneyString(exp.payroll),
      percentSalesPersonalDevices: exp.percentSalesPersonalDevices,
      roofUpdated: exp.roofUpdated,
      squareFootage: exp.squareFootage.toLocaleString('en-US'),
      storyCount: exp.storyCount,
      totalSales: numberToMoneyString(exp.totalSales),
      yearBuilt: exp.yearBuilt,
    },
    coverage: {
      ...cov,
      equipmentBreakdownCoverageOptedIn: cov.equipmentBreakdownCoverage,
      windCoverageOptedIn: !!cov.windCoverage,
      windDeductible: cov.windCoverage ? cov.windCoverage.windDeductible : undefined,
      windDeductiblePercent: cov.windCoverage ? cov.windCoverage.windDeductiblePercent : undefined,
      spoilage: cov.spoilageCoverage || undefined,
      ordinanceLawCoverageOptedIn: !!cov.ordinanceAndLawCoverage,
      ordinanceLawCoverageValue: cov.ordinanceAndLawCoverage || undefined,
      windLossMitigationPresent: !!cov.windLossMitigation,
      windLossMitigation: cov.windLossMitigation || undefined,
    },
    lessorsRisk: translateToLessorsRiskForm(building),
  };
};

const translateV3ToFormLocation = (loc: QSLocation): DeepPartial<BopLocation> => {
  return {
    locationDetails: {
      employeeCount: loc.employeeCount.toLocaleString('en-US'),
      partTimeEmployeeCount: loc.partTimeEmployeeCount
        ? loc.partTimeEmployeeCount.toLocaleString('en-US')
        : '0',
      isEmployeeCountVerified: loc.isEmployeeCountVerified,
      propertyDeductible: String(loc.propertyDeductible),
      isWithinCreditableWaterSupplyForPPC: loc.isWithinCreditableWaterSupplyForPPC,
      isWaterSupplyVerified: loc.isWaterSupplyVerified,
      ...loc.address,
    },
    locationPrefill: loc.prefill,
    buildings: loc.buildings.map(translateV3ToFormBuilding),
  };
};

/* For business types w/ enhancements:
 * - payloads have `0`
 * - the controls have `25,000 (included in Enhancement), etc depending on enhancement
 *
 * Note: The first building's businessType is used to attach Enhancements
 **/
const determineLimitForEmployeeDishonestyValue = (
  employeeDishonestyPayload: CoverageValue | undefined | null,
  locations: QSLocation[]
) => {
  const businessType = locations[0].buildings[0].exposure.businessType;
  const lessorsRisk = locations[0].buildings[0].exposure.lessorsRisk;
  const limitForEmployeeDishonesty = employeeDishonestyPayload
    ? (employeeDishonestyPayload.aggregateLimit as number)
    : 0;
  if (
    BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_25000.includes(businessType) &&
    !lessorsRisk &&
    limitForEmployeeDishonesty <= 25000
  ) {
    return 25000;
  }
  if (
    BUSINESS_TYPES_WITH_EMPLOYEE_LIMITS_GREATER_THAN_OR_EQUAL_TO_10000.includes(businessType) &&
    !lessorsRisk &&
    limitForEmployeeDishonesty <= 10000
  ) {
    return 10000;
  }
  return limitForEmployeeDishonesty;
};

const translateToCyberForm = (cyberPayload: {
  [key: string]: string | number | boolean | object;
}) => {
  return {
    optedIn: true,
    // If we're seeing this (e.g., the `CyberLiability` key is on the payload)
    // it means the selectedCoverage must be 'endorsement', as that is the only
    // case when we would enter this routine with `optedIn: true`.
    // Both "coalition-cyber" and "none" result in `optedIn: false`
    selectedCyberCoverage: 'endorsement',
    aggregateLimit: cyberPayload.aggregateLimit as number,
    firstPartyLimit: cyberPayload.firstPartyLimit as number,
    deductible: cyberPayload.deductible as number,
    retroactiveDate: moment(cyberPayload.retroactoveDate as string).format(US_DATE_MASK), // BOPv1 only
  };
};

const translateToEPLIForm = (epliPayload: {
  [key: string]: string | number | boolean | object;
}) => {
  return {
    optedIn: true,
    // V1
    eachEmploymentWrongfulActLimit: epliPayload.perEmploymentWrongfulActLimit as number,
    aggregateLimit: epliPayload.aggregateLimit as number,
    deductible: epliPayload.deductible as number,
    retroactiveDate: moment(epliPayload.retroactiveDate as string).format(US_DATE_MASK),
    // V2
    aggregateLimitV2: epliPayload.aggregateLimit as number,
    defenseLimitV2: epliPayload.aggregateLimitDefense as number,
    deductibleV2: epliPayload.deductible as number,
  };
};

const removeNullAndUndefined = (obj: object): DeepPartial<object> => {
  return JSON.parse(JSON.stringify(obj));
};

// Translates a "V3-style" retrieved quote into a BOP Form that is directly patchable
// This is the inverse of `v3-quote-service`
export const translateFromV3BopForm = (quote: BaseQuote): DeepPartial<BopQuotePayload> => {
  const currentYear = moment().year();
  const year = quote.account ? quote.account.yearBusinessStarted : null;
  const yearsInBusiness = year ? currentYear - year : null;

  const effectiveDate: string = parsedFutureMomentOrNow(quote.policy.effectiveDate);
  const effectiveDateMoment = moment(effectiveDate, 'MM/DD/YYYY');
  const bopVersion = quote.productUwCompany === BOP_V2_UW_COMPANY ? 2 : 1;

  const isNyMeToo =
    bopVersion === 2 && shouldGetMeToo(quote.account.baseState, effectiveDateMoment);

  const coverages = quote.policy.coverages;

  const getCoverage = function (covName: string) {
    const coverageOrNull = coverages.find((cov) => cov.name === covName);
    return coverageOrNull ? coverageOrNull.value : null;
  };

  const cyberPayload = getCoverage('CyberLiability');
  const liquorPayload = getCoverage('LiquorLiability');
  const epliPayload = getCoverage('EmployeeRelatedPracticesLiability');
  const additionalInsuredBusinesses = translateV3ToAdditionalInsureds(quote.attachments);
  const wosPayload = getCoverage('WaiverOfSubrogation');
  const barbersPayload = getCoverage('BarberShopsAndHairSalonsProfessionalLiability');
  const beautyPayload = getCoverage('BeautySalonsProfessionalLiability');
  const damageToPremisesPayload = getCoverage('DamageToPremises');
  const contractorPayload = getCoverage('Contractor');
  const hnoaPayload = getCoverage('HiredNonOwnedAuto');
  const perOccurrencePayload = getCoverage('PerOccurrence');
  const perPersonMedicalPayload = getCoverage('PerPersonMedical');
  const ebPayload = getCoverage('EmployeeBenefits');
  const veterinariansPayload = getCoverage('VeterinariansProfessionalLiability');
  const printersPayload = getCoverage('PrintersErrorsAndOmissionsProfessionalLiability');
  const opticalAndHearingAidPayload = getCoverage('OpticalAndHearingAidProfessionalLiability');
  const terrorismPayload = getCoverage('CertifiedActsOfTerrorism');
  const employeeDishonestyPayload = getCoverage('EmployeeDishonesty');
  const stopGapPayload = getCoverage('StopGapCoverage');

  const partialWithBadKeys = {
    additionalCoverages: {
      acceptCertifiedActsOfTerrorismCoverage: terrorismPayload ? terrorismPayload.optedIn : false,
      additionalInsuredBusinesses,
      cyberLiabilityCoverage: cyberPayload
        ? translateToCyberForm(cyberPayload)
        : { optedIn: false }, // Available everywhere
      employmentRelatedPracticesLiabilityCoverage: epliPayload
        ? translateToEPLIForm(epliPayload)
        : { optedIn: false }, // Available everywhere
      hasAdditionalInsuredBusinesses: additionalInsuredBusinesses.length > 0,
      hasWaiversOfSubrogation: !!wosPayload,
      limitForEmployeeDishonesty: determineLimitForEmployeeDishonestyValue(
        employeeDishonestyPayload,
        quote.locations
      ),
      waiversOfSubrogation: wosPayload ? (wosPayload.waiversOfSubrogation as any) : [],
    },
    excessLiability: {
      excessCommercialAutoCoverageIsScheduled: false,
      excessLiabilityCoverageIsScheduled: false,
      excessLiabilityLimit: null,
    },
    // excessLiabilityOptIn: false
    guidelines: true,
    guidewireId: quote.guidewireId,
    liabilityCoverages: {
      // If we have an hnoaPayload, then originally we must've originally answered "yes" to the following 5 HNOA UW questions.
      hnoaOver18: !!hnoaPayload,
      hnoaCertificateOfInsurance: !!hnoaPayload,
      hnoa3rdPartyDelivery: !!hnoaPayload,
      hnoaMotorVehicles: !!hnoaPayload,
      hnoaLocationRadius: !!hnoaPayload,
      acceptHiredNonOwnedAutoCoverage: !!hnoaPayload,
      acceptSnowPlowCompletedOpsCoverage: !!getCoverage('SnowPlowCompletedOps'),
      barberShopsAndHairSalonsProfessionalLiability: !!barbersPayload,
      barberShopsNumberOfOperators: barbersPayload ? (barbersPayload.numOperators as number) : 1,
      beautySalonsDescriptionOfAdditionalServices: '',
      beautySalonsNumberOfOperators: beautyPayload ? (beautyPayload.numOperators as number) : 1,
      beautySalonsProfessionalLiability: !!beautyPayload,
      damageToPremises: damageToPremisesPayload
        ? (damageToPremisesPayload.occurrenceLimit as number)
        : undefined,
      eachCoveredJobSiteLimit: contractorPayload
        ? (contractorPayload.perCoveredJobSiteLimit as number)
        : undefined,
      propertyInTransitLimit: contractorPayload
        ? (contractorPayload.propertyInTransitLimit as number)
        : undefined,
      installationLimit: contractorPayload
        ? (contractorPayload.coverageLimit as number)
        : undefined,
      toolsBlanketLimit: contractorPayload ? (contractorPayload.blanketLimit as number) : undefined,
      toolsPerItemLimit: contractorPayload
        ? (contractorPayload.blanketPerItemLimit as number)
        : undefined,
      eligibilityUWQuestions: quote.eligibilityUWQuestions,
      employeeHandbook: quote.irpmQuestions ? quote.irpmQuestions.employeeHandbook : null,
      employeeBenefitsLiabilityCoverage: ebPayload
        ? {
            optedIn: true,
            aggregateLimit: ebPayload.aggregateLimit as number,
            deductible: ebPayload.deductible as number,
            eachEmployeeLimit: ebPayload.perEmployeeLimit as number,
            retroactiveDate: moment(ebPayload.retroactiveDate as string).format(US_DATE_MASK),
          }
        : undefined,
      equipmentLeftInVehicle: quote.irpmQuestions
        ? quote.irpmQuestions.equipmentLeftInVehicle
        : null,
      stressTestsWaterLines: quote.irpmQuestions ? quote.irpmQuestions.stressTestsWaterLines : null,
      funeralDirectorsProfessionalLiability: !!getCoverage('FuneralDirectorsProfessionalLiability'),
      hnoaNumberOfDrivers: hnoaPayload ? (hnoaPayload.numberOfDrivers as number) : undefined,
      janitorialServices: quote.irpmQuestions ? quote.irpmQuestions.janitorialServices : null,
      limitPerOccurrenceOfLiabilityAndMedicalExpenses: perOccurrencePayload
        ? (perOccurrencePayload.occurrenceLimit as number)
        : undefined,
      limitPerPersonOfMedicalExpenses: perPersonMedicalPayload
        ? (perPersonMedicalPayload.perPersonLimit as number)
        : undefined,
      liquorLiability: liquorPayload
        ? {
            optedIn: true,
            aggregateLimit: liquorPayload.aggregateLimit as number,
            eachCommonCauseLimit: liquorPayload.perCommonCauseLimit
              ? (liquorPayload.perCommonCauseLimit as number)
              : undefined,
            liquorLicenseNumber: liquorPayload.liquorLicenseNumber,
          }
        : undefined,
      numberOfVeterinarians: veterinariansPayload
        ? (veterinariansPayload.numVeterinarians as number)
        : undefined,
      opticalAndHearingAidEstablishmentsProfessionalLiability: !!opticalAndHearingAidPayload,
      opticalAndHearingAidSales: opticalAndHearingAidPayload
        ? (opticalAndHearingAidPayload.opticalAndHearingAidSales as number)
        : undefined,
      printersErrorsAndOmissionsProfessionalLiability: !!printersPayload,
      printersErrorsAndOmissionsSalesOrPayroll: printersPayload
        ? (printersPayload.exposure as number)
        : undefined,
      stopGap: !!stopGapPayload,
      veterinariansOnlyTreatHousePets: veterinariansPayload
        ? (veterinariansPayload.onlyTreatHousePets as boolean)
        : undefined,
      veterinariansProfessionalLiability: !!veterinariansPayload,
    },
    locations: quote.locations.map(translateV3ToFormLocation),
    policyInfo: {
      agentLicenseNumber: quote.agentLicenseNumber || undefined,
      agentName: quote.agentName || undefined,
      baseState: quote.account.baseState,
      bopVersion: isNyMeToo ? 1 : bopVersion,
      effectiveDate: effectiveDate,
      irpmVersion: quote.irpmVersion || undefined,
      meToo: isNyMeToo,
      numberOfLocations: quote.locations.length,
      organizationType: quote.account.organizationType,
      ratingModelVersion: quote.ratingModelVersion || undefined,
      yearsInBusiness: yearsInBusiness,
    },
  };

  return removeNullAndUndefined(partialWithBadKeys);
};

export const addLROdocumentsToBaseQuote = (
  quote: BaseQuote,
  lroDocumentsByBuilding: QSLessorsRiskBuilding[] | null
): BaseQuote => {
  if (!lroDocumentsByBuilding) {
    return quote;
  }

  lroDocumentsByBuilding.forEach((lroBuilding) => {
    const zeroIdxLocNumber = Number(lroBuilding.locationNumber) - 1;
    const zeroIdxBuildingNumber = Number(lroBuilding.buildingNumber) - 1;
    const documentsToAdd = lroBuilding.documents;

    const lroBuildingToUpdate =
      quote?.locations?.[zeroIdxLocNumber]?.buildings?.[zeroIdxBuildingNumber]?.lessorsRisk;
    if (lroBuildingToUpdate && documentsToAdd.length > 0) {
      lroBuildingToUpdate.rentRollFiles = documentsToAdd;
    }
  });

  return quote;
};
